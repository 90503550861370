export const SET_WATCHLIST_NOTIF_SOURCE = "SET_WATCHLIST_NOTIF_SOURCE";
export const SET_WATCHLIST_NOTIF_SOURCE_LOAD =
  "SET_WATCHLIST_NOTIF_SOURCE_LOAD";
export const SET_WATCHLIST_NOTIF_FOLDER_SELECTED =
  "SET_WATCHLIST_NOTIF_FOLDER_SELECTED";
export const SET_WATCHLIST_NOTIF_FILTER = "SET_WATCHLIST_NOTIF_FILTER";
export const SET_WATCHLIST_NOTIF_PAGE = "SET_WATCHLIST_NOTIF_PAGE";
export const SET_WATCHLIST_NOTIF_LIMIT = "SET_WATCHLIST_NOTIF_LIMIT";
export const SET_WATCHLIST_NOTIF_SHOW_NOTE_TASK =
  "SET_WATCHLIST_NOTIF_SHOW_NOTE_TASK";
export const SET_WATCHLIST_NOTIF_PIPELINE_REQUEST =
  "SET_WATCHLIST_NOTIF_PIPELINE_REQUEST";
export const SET_WATCHLIST_NOTIF_KEY_ACCOUNT_REQUEST =
  "SET_WATCHLIST_NOTIF_KEY_ACCOUNT_REQUEST";
export const SET_WATCHLIST_NOTIF_LOAD = "SET_WATCHLIST_NOTIF_LOAD";
export const SET_WATCHLIST_NOTIF_DATA = "SET_WATCHLIST_NOTIF_DATA";
export const SET_WATCHLIST_NOTIF_ERROR = "SET_WATCHLIST_NOTIF_ERROR";
export const RESET_WATCHLIST_NOTIF = "RESET_WATCHLIST_NOTIF";
