import initialState from "./initialState";
import {
  RESET_FILTER_COMPANY,
  RESET_SEARCH_COMPANY_FILTER,
  RESET_SEARCH_COMPANY_PARAMETERS,
  SET_SEARCH_COMPANY_DATA,
  SET_SEARCH_COMPANY_DATA_ERROR,
  SET_SEARCH_COMPANY_DATA_LOAD,
  SET_SEARCH_COMPANY_DETAIL,
  SET_SEARCH_COMPANY_DETAIL_DATA,
  SET_SEARCH_COMPANY_DETAIL_DATA_LOAD,
  SET_SEARCH_COMPANY_FILTER,
  SET_SEARCH_COMPANY_PARAMETERS,
  SET_SEARCH_COMPANY_SELECTED,
  SET_SEARCH_COMPANY_WATCHLIST_FORM,
  SET_SEARCH_COMPANYID_SELECTED,
  SET_SEARCH_COMPANY_ENQUIRY_FORM,
  SET_SEARCH_COMPANY_SAVE_SEARCH,
  SET_SHOW_ALL_DATA_ON_MAP_COMPANY,
} from "./types";

const filterCompany = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_COMPANY_PARAMETERS: {
      return {
        ...state,
        parameters: { ...state.parameters, ...payload },
        filter: { ...state.filter, offset: 0 },
      };
    }
    case RESET_SEARCH_COMPANY_PARAMETERS: {
      return {
        ...state,
        parameters: { ...initialState.parameters, ...payload },
        filter: { ...state.filter, offset: 0 },
      };
    }
    case SET_SEARCH_COMPANY_FILTER: {
      return {
        ...state,
        filter: { ...state.filter, ...payload, refreshMap: payload.refreshMap !== undefined ? payload.refreshMap : undefined },
      };
    }
    case RESET_SEARCH_COMPANY_FILTER: {
      return {
        ...state,
        filter: initialState.filter,
      };
    }
    case SET_SEARCH_COMPANY_SELECTED: {
      return {
        ...state,
        selected: payload,
        detail: null,
        detailData: null,
      };
    }
    case SET_SEARCH_COMPANYID_SELECTED: {
      return {
        ...state,
        selectedCompanyId: payload,
        detail: null,
        detailData: null,
      };
    }
    case SET_SEARCH_COMPANY_DETAIL: {
      return {
        ...state,
        detail: payload,
        detailData: null,
      };
    }
    case SET_SEARCH_COMPANY_DETAIL_DATA: {
      return {
        ...state,
        detailData: {
          ...initialState.detailData,
          detailData: payload,
        },
      };
    }
    case SET_SEARCH_COMPANY_DETAIL_DATA_LOAD: {
      return {
        ...state,
        detailData: {
          ...initialState.detailData,
          loadData: true,
        },
      };
    }

    // API CALL PROCESS COMPANY DATA
    case SET_SEARCH_COMPANY_DATA_LOAD: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          loadData: true,
        },
        selected: [],
        detail: null,
        detailData: null,
      };
    }
    case SET_SEARCH_COMPANY_DATA_ERROR: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_COMPANY_DATA: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          companies: payload.data,
          totalData: payload.totalData,
        },
      };
    }

    case SET_SEARCH_COMPANY_WATCHLIST_FORM: {
      return {
        ...state,
        companyWatchlistForm: payload,
      };
    }
    case SET_SEARCH_COMPANY_ENQUIRY_FORM: {
      return {
        ...state,
        companyEnquiryForm: !state.companyEnquiryForm,
      };
    }
    case SET_SEARCH_COMPANY_SAVE_SEARCH: {
      return {
        ...state,
        saveSearch: payload,
      };
    }
    case RESET_FILTER_COMPANY: {
      return initialState;
    }
    case SET_SHOW_ALL_DATA_ON_MAP_COMPANY: {
      return {
        ...state,
        showAllDataOnMap: payload ?? true,
      };
    }

    default:
      return state;
  }
};

export default filterCompany;
