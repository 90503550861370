import initialState from './initialState';
import {
    SET_FOLLOWUP_TASK_COUNT,
    SET_FOLLOWUP_TASK_DATA, 
    SET_FOLLOWUP_TASK_ERROR, 
    SET_FOLLOWUP_TASK_LOAD,
    SET_FOLLOWUP_TASK_PAGE,
    SET_FOLLOWUP_TASK_TOTAL
} from "./type";

const saveSearch = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_FOLLOWUP_TASK_DATA : {
            return{
                ...state,
                followupTaskData : payload,
                followupTaskLoad: false,
                followupTaskError : false,
            }
        }
        case SET_FOLLOWUP_TASK_LOAD : {
            return{
                ...state,
                followupTaskLoad: true,
                followupTaskError : false,
            }
        }
        case SET_FOLLOWUP_TASK_ERROR : {
            return{
                ...state,
                followupTaskData : [],
                followupTaskLoad: false,
                followupTaskError : true,
            }
        }
        case SET_FOLLOWUP_TASK_PAGE : {
            return{
                ...state,
                followupTaskPage : payload
            }
        }
        case SET_FOLLOWUP_TASK_TOTAL : {
            return{
                ...state,
                followupTaskTotal : payload
            }
        }
        case SET_FOLLOWUP_TASK_COUNT : {
            return{
                ...state,
                followupTaskCount : payload
            }
        }
    default:
        return state;
    }
};

export default saveSearch;