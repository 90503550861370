const initialState = {
  notifTab: "all", // all | bci_update | my_activity | team_activity
  parameters: {
    keyword: "",
    date: null,
    startDate: "",
    endDate: "",
  },
  filter: {
    offset: 0,
    limit: 10,
  },
  selected: null,
  selectedFromEmail: null,
  selectedData: {
    detail: null,
    load: false,
    error: null,
  },

  resultData: {
    notification: [],
    totalData: 0,
    load: false,
    error: null,
  },

  popupNotFoundState: false,
};

export default initialState;
