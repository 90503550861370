const initialState = {
  watchlistTabActive: "", // 'projectpipeline' || 'keyaccount' || ''

  keyaccountSelectedFolder: null,
  keyaccountShowNoteTask: false,
  keyaccountForm: null,
  keyaccountAlertForm: null,
  keyaccountFolderUserId: localStorage.subscriptionId,
  keyaccountListDefaultFolder: {
    data: [],
    load: false,
  },
  keyaccountListMyFolder: {
    data: [],
    load: false,
  },
  keyaccountRequest: {
    location: null,
    advancedLocation: null,
    roleGroups: [],
    keyword: "",
  },
  keyaccountFilter: {
    page: 1,
    limit: 10,
    sortby: "createdAt",
    order: "desc",
  },
  keyaccountData: {
    data: [],
    error: false,
    load: false,
    total: 0,
  },
  keyaccountArchive: false,

  projectpipelineSelectedFolder: null,
  projectpipelineShowNoteTask: false,
  projectpipelineForm: null,
  projectpipelineAlertForm: null,
  projectpipelineFolderUserId: localStorage.subscriptionId,
  projectpipelineListDefaultFolder: {
    data: [],
    load: false,
  },
  projectpipelineListMyFolder: {
    data: [],
    load: false,
  },
  projectpipelineRequest: {
    categories: [],
    location: null,
    advancedLocation: null,
    stage: [],
    keyword: "",
  },
  projectpipelineFilter: {
    page: 1,
    limit: 10,
    sortby: "createdAt",
    order: "desc",
  },
  projectpipelineData: {
    data: [],
    error: false,
    load: false,
    total: 0,
  },
  projectCompanies: null,
  projectpipelineArchive: false,

  notificationSourceData: null,
};

export default initialState;
