export const SET_COMPANY_LIST_SEARCH = "SET_COMPANY_LIST_SEARCH";

export const SET_SEARCH_COMPANY_LOCATION = "SET_SEARCH_COMPANY_LOCATION";
export const SET_SEARCH_COMPANY_LOCATION_ADD =
  "SET_SEARCH_COMPANY_LOCATION_ADD";
export const SET_SEARCH_COMPANY_LOCATION_REMOVE =
  "SET_SEARCH_COMPANY_LOCATION_REMOVE";
export const SET_SEARCH_COMPANY_LOCATION_LOAD_NEXT_PAGE =
  "SET_SEARCH_COMPANY_LOCATION_LOAD_NEXT_PAGE";
export const SET_SEARCH_COMPANY_LOCATION_TOTAL =
  "SET_SEARCH_COMPANY_LOCATION_TOTAL";
export const SET_SEARCH_COMPANY_LOCATION_CURRENT_PARTITION =
  "SET_SEARCH_COMPANY_LOCATION_CURRENT_PARTITION";
export const SET_SEARCH_COMPANY_LOCATION_LOAD =
  "SET_SEARCH_COMPANY_LOCATION_LOAD";
export const SET_SEARCH_COMPANY_LOCATION_ERROR =
  "SET_SEARCH_COMPANY_LOCATION_ERROR";
export const RESET_SEARCH_COMPANY_LOCATION = "RESET_SEARCH_COMPANY_LOCATION";

export const SET_SEARCH_COMPANY_VALUES_LOAD = "SET_SEARCH_COMPANY_VALUES_LOAD";
export const SET_SEARCH_COMPANY_VALUES = "SET_SEARCH_COMPANY_VALUES";
export const SET_SEARCH_COMPANY_VALUES_ERROR =
  "SET_SEARCH_COMPANY_VALUES_ERROR";
export const RESET_SEARCH_COMPANY_VALUES = "RESET_SEARCH_COMPANY_VALUES";

export const SET_SEARCH_COMPANY_RESULT_LOAD = "SET_SEARCH_COMPANY_RESULT_LOAD";
export const SET_SEARCH_COMPANY_RESULT = "SET_SEARCH_COMPANY_RESULT";
export const SET_SEARCH_COMPANY_RESULT_ERROR =
  "SET_SEARCH_COMPANY_RESULT_ERROR";
export const RESET_SEARCH_COMPANY_RESULT = "RESET_SEARCH_COMPANY_RESULT";

export const RESET_SEARCH_COMPANY_LIST = "RESET_SEARCH_COMPANY_LIST";
