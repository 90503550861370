import React from "react";

const TNLMIcon = ({
  title,
  size = 1,
  color = "#000000",
  style = {},
  className = "",
  icon = "",
  ...other
}) => {
  return (
    <div
      title={title}
      style={{
        width: `${size}em`,
        height: `${size}em`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={className || undefined}
    >
      <i
        title={title}
        className={`tnlm-font ${icon}`}
        style={{ fontSize: `${size}em`, color: color }}
      ></i>
    </div>
  );
};

export default TNLMIcon;
