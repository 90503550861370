import React, { Component } from "react";
import ssoConfig from "../../config/sso.config";

export default class SSORedirectRoute extends Component {
  componentDidMount() {
    window.location.href = `${ssoConfig.tnlmBaseUri}/login?app=${ssoConfig.tnlmAppName}`;
  }
  render() {
    return null;
  }
}
