export const SET_PROJECT_LIST_SEARCH = "SET_PROJECT_LIST_SEARCH";
export const SET_PROJECT_LIST_SEARCH_OUTSIDE_SUBS =
  "SET_PROJECT_LIST_SEARCH_OUTSIDE_SUBS";
export const SET_LOAD_PROJECT_LIST_SEARCH = "SET_LOAD_PROJECT_LIST_SEARCH";
export const SET_PROJECT_LIST_SEARCH_ERROR = "SET_PROJECT_LIST_SEARCH_ERROR";
export const RESET_PROJECT_LIST_SEARCH = "RESET_PROJECT_LIST_SEARCH";

export const SET_SEARCH_PROJECT_LOCATION = "SET_SEARCH_PROJECT_LOCATION";
export const SET_SEARCH_PROJECT_LOCATION_LOAD =
  "SET_SEARCH_PROJECT_LOCATION_LOAD";
export const SET_SEARCH_PROJECT_LOCATION_ADD =
  "SET_SEARCH_PROJECT_LOCATION_ADD";
export const SET_SEARCH_PROJECT_LOCATION_REMOVE =
  "SET_SEARCH_PROJECT_LOCATION_REMOVE";
export const SET_SEARCH_PROJECT_LOCATION_ADD_FORCE_REDRAW = 
  "SET_SEARCH_PROJECT_LOCATION_ADD_FORCE_REDRAW";
export const SET_SEARCH_PROJECT_LOCATION_TOTAL =
  "SET_SEARCH_PROJECT_LOCATION_TOTAL";
export const SET_SEARCH_PROJECT_LOCATION_SCROLL_ID =
  "SET_SEARCH_PROJECT_LOCATION_SCROLL_ID";

export const SET_SEARCH_PROJECT_LOCATION_LOAD_NEXT_PAGE =
  "SET_SEARCH_PROJECT_LOCATION_LOAD_NEXT_PAGE";
export const SET_SEARCH_PROJECT_LOCATION_ERROR =
  "SET_SEARCH_PROJECT_LOCATION_ERROR";
export const RESET_SEARCH_PROJECT_LOCATION = "RESET_SEARCH_PROJECT_LOCATION";
export const SET_SEARCH_PROJECT_LOCATION_FORCE_REDRAW_MAP = "SET_SEARCH_PROJECT_LOCATION_FORCE_REDRAW_MAP";
