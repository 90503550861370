export const SET_USER_ACTIVITY_INSIGHT_FILTER =
  "SET_USER_ACTIVITY_INSIGHT_FILTER";
export const SET_USER_ACTIVITY_INSIGHT_DATA_LOAD =
  "SET_USER_ACTIVITY_INSIGHT_DATA_LOAD";
export const SET_USER_ACTIVITY_INSIGHT_DATA_ERROR =
  "SET_USER_ACTIVITY_INSIGHT_DATA_ERROR";

export const SET_USER_ACTIVITY_INSIGHT_ACTIVE =
  "SET_USER_ACTIVITY_INSIGHT_ACTIVE";
export const SET_USER_ACTIVITY_INSIGHT_DATA = "SET_USER_ACTIVITY_INSIGHT_DATA";

export const SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_LOAD =
  "SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_LOAD";
export const SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_ERROR =
  "SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_ERROR";
export const SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_DATA =
  "SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_DATA";

export const SET_INDIVIDUAL_ACTIVITY_INSIGHT_FILTER =
  "SET_INDIVIDUAL_ACTIVITY_INSIGHT_FILTER";
export const SET_INDIVIDUAL_ACTIVITY_INSIGHT_LOAD =
  "SET_INDIVIDUAL_ACTIVITY_INSIGHT_LOAD";
export const SET_INDIVIDUAL_ACTIVITY_INSIGHT_ERROR =
  "SET_INDIVIDUAL_ACTIVITY_INSIGHT_ERROR";
export const SET_INDIVIDUAL_ACTIVITY_INSIGHT_DATA =
  "SET_INDIVIDUAL_ACTIVITY_INSIGHT_DATA";

export const SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_LOAD =
  "SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_LOAD";
export const SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_ERROR =
  "SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_ERROR";
export const SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA =
  "SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA";

export const RESET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA =
  "RESET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA";
