// import moment from 'moment';

const initialState = {
  globalSearchData: {
    categoryList: [],
    locationList: [],
    stageList: [],
    roleList: [],
    projectSavedQueryList: [],
    companySavedQueryList: [],
  },

  keywordSearch: "",
  locationSearchCheck: "",
  categorySearchCheck: "",
  stageSearchCheck: "",
  roleSearchCheck: "",
  advanceLocation: {
    id: "",
    tabs: "postal_code", //postal_code|city|council
    value: "",
  },
  tabsLocationCheck: "location", //location|advance
  searchFilter: "", //''|watchlist|folder
  searchFilterFolder: "",
  searchFilterDocument: false,

  searchTabAll: {
    keyword: "",
    location: [],
    category: [],
    advanceLocation: {
      id: "",
      tabs: "postal_code", //postal_code|city|council
      value: "",
    },
    locationTabs: "location", //location|advance
    searchFilter: "", //''|watchlist|folder
    searchFilterFolder: [],
    searchFilterDocument: false,
    projectValueMax: "",
    projectValueMin: "",
  },
  searchTabCompany: {
    keyword: "",
    location: [],
    category: [],
    role: [],
    advanceLocation: {
      id: "",
      tabs: "postal_code", //postal_code|city|council
      value: "",
    },
    locationTabs: "location", //location|advance
    searchFilter: "", //''|watchlist|folder
    searchFilterFolder: [],
  },
  searchTabProject: {
    keyword: "",
    location: [],
    category: [],
    stage: [],
    advanceLocation: {
      id: "",
      tabs: "postal_code", //postal_code|city|council
      value: "",
    },
    locationTabs: "location", //location|advance
    searchFilter: "", //''|watchlist|folder
    searchFilterFolder: [],
    searchFilterDocument: false,
  },

  tabsCheck: "all", // all|project|company
  tabsCheckActive: "", // all|project|company

  allActiveTabs: "project", // 'project' || 'company'
  companyAdvanceFilterModal: false,
  projectAdvanceFilterModal: false,
  companySaveSearchModal: false,
  projectSaveSearchModal: false,
  showProjectsOutsideSubscription: false,

  forceDisableRefreshSearchList: false,
  mapMode: false,
  mapPopupRefresh: 0,
  mapPopupOpen: false,
  mapData: [],

  filterProject: {
    keyword: "",
    projectName: "",
    name: "",
    prevName: "",
    shareGroup: false,
    devid: [],
    pOwnerid: [],
    timeStamp: "",
    location: {},
    categories: [],
    stage: [],
    minValue: "",
    maxValue: "",
    countries: "",
    countryProvince: "",
    bciregionid: "",
    town: "",
    address: "",
    postcode: "",
    council: "",
    minFloor: "",
    maxFloor: "",
    minStoreys: "",
    maxStoreys: "",
    minUnits: "",
    maxUnits: "",
    greenRatingIds: "",
    conStartFrom: null,
    conStartTo: null,
    conEndFrom: null,
    conEndTo: null,
    excludeAllWatchlist: false,
    excludeAllFolder: [],
    projectFeaturedIn: [],
    projectDocument: false,
    appointedRole: [],
    foreignParticipantsProject: false,
    includeProjectImage: false,
    floorAreaIncludeBlank: false,
    noOfStoreyIncludeBlank: false,
    noOfUnitIncludeBlank: false,
    tenderClosingStartDate: null,
    tenderClosingEndDate: null,
    advancedLocation: {},
    latestProject: false,
    keyAccountFolder: [],
    greenBuilding: [],
    createdBy: "",

    shareGroupId: [],

    alertValue: null,
    alertType: null,
    alertOption: null,
    turnOnAlert: false,
  },
  filterCompany: {
    keyword: "",
    firmName: "",
    name: "",
    prevName: "",
    shareGroup: false,
    pMinValue: "",
    pMaxValue: "",
    location: {},
    pCategory: [],
    pStage: [],
    timeStamp: "",
    fRoles: "",
    excludeAllWatchlist: false,
    excludeAllFolder: [],
    projectFeaturedIn: [],
    projectDocument: false,
    developmentType: [],
    ownershipType: [],
    tenderStatus: [],
    advancedLocation: {},
    roleGroups: [],
    categories: [],
    otherCountries: [],
    pCountryProvince: [],
    createdBy: "",

    shareGroupId: [],

    alertValue: null,
    alertType: null,
    alertOption: null,
    turnOnAlert: false
  },
  searchOptions: {
    sideMenuExpanded: true,
    cardView: null,
  },
};

export default initialState;
