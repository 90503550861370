export const SET_EXCEL_TEMPLATE_ROLES = "SET_EXCEL_TEMPLATE_ROLES";
export const SET_EXCEL_TEMPLATE_COLUMNS = "SET_EXCEL_TEMPLATE_COLUMNS";
export const SET_EXCEL_TEMPLATE_EXPORT_FORMAT =
  "SET_EXCEL_TEMPLATE_EXPORT_FORMAT";
export const SET_EXCEL_TEMPLATE_EXPORT_COMBINE =
  "SET_EXCEL_TEMPLATE_EXPORT_COMBINE";
export const SET_EXCEL_TEMPLATE_VISIBILITY = "SET_EXCEL_TEMPLATE_VISIBILITY";
export const SET_EXCEL_TEMPLATE_TAB_ACTIVE = "SET_EXCEL_TEMPLATE_TAB_ACTIVE";
export const SET_EXCEL_TEMPLATE_EXPORT_COMPANY_AS_COLUMN =
  "SET_EXCEL_TEMPLATE_EXPORT_COMPANY_AS_COLUMN";
export const SET_EXCEL_TEMPLATE_TAB_ACTIVE_PROJECT =
  "SET_EXCEL_TEMPLATE_TAB_ACTIVE_PROJECT";
export const SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_PROJECT =
  "SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_PROJECT";
export const SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_PROJECT =
  "SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_PROJECT";
export const SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT =
  "SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT";
export const SET_EXCEL_TEMPLATE_DESCRIPTION_PROJECT =
  "SET_EXCEL_TEMPLATE_DESCRIPTION_PROJECT";
export const SET_EXCEL_TEMPLATE_STATIC_COMPANY_ROLE_PROJECT =
  "SET_EXCEL_TEMPLATE_STATIC_COMPANY_ROLE_PROJECT";

export const SET_EXCEL_TEMPLATE_ROLES_COMPANY =
  "SET_EXCEL_TEMPLATE_ROLES_COMPANY";
export const SET_EXCEL_TEMPLATE_COLUMNS_COMPANY =
  "SET_EXCEL_TEMPLATE_COLUMNS_COMPANY";
export const SET_EXCEL_TEMPLATE_EXPORT_FORMAT_COMPANY =
  "SET_EXCEL_TEMPLATE_EXPORT_FORMAT_COMPANY";
export const SET_EXCEL_TEMPLATE_VISIBILITY_COMPANY =
  "SET_EXCEL_TEMPLATE_VISIBILITY_COMPANY";
export const SET_EXCEL_TEMPLATE_EXPORT_COMBINE_COMPANY =
  "SET_EXCEL_TEMPLATE_EXPORT_COMBINE_COMPANY";
export const SET_EXCEL_TEMPLATE_TAB_ACTIVE_COMPANY =
  "SET_EXCEL_TEMPLATE_TAB_ACTIVE_COMPANY";
export const SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_COMPANY =
  "SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_COMPANY";
export const SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_COMPANY =
  "SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_COMPANY";
export const SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT_COMPANY =
  "SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT_COMPANY";
export const SET_EXCEL_TEMPLATE_DESCRIPTION_COMPANY =
  "SET_EXCEL_TEMPLATE_DESCRIPTION_COMPANY";
