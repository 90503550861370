export const SET_PROJECT_DETAIL = "SET_PROJECT_DETAIL";
export const SET_PROJECT_DETAIL_LOAD = "SET_PROJECT_DETAIL_LOAD";
export const SET_PROJECT_DETAIL_ERROR = "SET_PROJECT_DETAIL_ERROR";

export const SET_PROJECT_DETAIL_CONTACT = "SET_PROJECT_DETAIL_CONTACT";
export const SET_PROJECT_DETAIL_CONTACT_LOAD =
  "SET_PROJECT_DETAIL_CONTACT_LOAD";
export const SET_PROJECT_DETAIL_CONTACT_ERROR =
  "SET_PROJECT_DETAIL_CONTACT_ERROR";
export const SET_KEYWORD_CONTACT = "SET_KEYWORD_CONTACT";

export const SET_PROJECT_DETAIL_DOCUMENT = "SET_PROJECT_DETAIL_DOCUMENT";
export const SET_PROJECT_DETAIL_DOCUMENT_LOAD =
  "SET_PROJECT_DETAIL_DOCUMENT_LOAD";
export const SET_PROJECT_DETAIL_DOCUMENT_ERROR =
  "SET_PROJECT_DETAIL_DOCUMENT_ERROR";

export const SET_PROJECT_DETAIL_NOTE = "SET_PROJECT_DETAIL_NOTE";
export const SET_PROJECT_DETAIL_NOTE_LOAD = "SET_PROJECT_DETAIL_NOTE_LOAD";
export const SET_PROJECT_DETAIL_NOTE_ERROR = "SET_PROJECT_DETAIL_NOTE_ERROR";
export const SET_PROJECT_DETAIL_NOTE_PAGE = "SET_PROJECT_DETAIL_NOTE_PAGE";
export const SET_PROJECT_DETAIL_NOTE_DETAIL_OPEN =
  "SET_PROJECT_DETAIL_NOTE_DETAIL_OPEN";
export const SET_PROJECT_DETAIL_NOTE_DETAIL_CLOSE =
  "SET_PROJECT_DETAIL_NOTE_DETAIL_CLOSE";

export const SET_PROJECT_DETAIL_TASK = "SET_PROJECT_DETAIL_TASK";
export const SET_PROJECT_DETAIL_TASK_LOAD = "SET_PROJECT_DETAIL_TASK_LOAD";
export const SET_PROJECT_DETAIL_TASK_ERROR = "SET_PROJECT_DETAIL_TASK_ERROR";
export const SET_PROJECT_DETAIL_TASK_PAGE = "SET_PROJECT_DETAIL_TASK_PAGE";
export const SET_PROJECT_DETAIL_TASK_DETAIL_OPEN =
  "SET_PROJECT_DETAIL_TASK_DETAIL_OPEN";
export const SET_PROJECT_DETAIL_TASK_DETAIL_CLOSE =
  "SET_PROJECT_DETAIL_TASK_DETAIL_CLOSE";
export const SET_PROJECT_DETAIL_TASK_DELETE = "SET_PROJECT_DETAIL_TASK_DELETE";

export const SET_PROJECT_DETAIL_FORM_FOLLOW_UP_TASK =
  "SET_PROJECT_DETAIL_FORM_FOLLOW_UP_TASK";
export const SET_PROJECT_DETAIL_FORM_NOTES = "SET_PROJECT_DETAIL_FORM_NOTES";

export const SET_PROJECT_DETAIL_WATCHLIST_FORM =
  "SET_PROJECT_DETAIL_WATCHLIST_FORM";

export const RESET_PROJECT_DETAIL = "RESET_PROJECT_DETAIL";
export const SET_PROJECT_NAV_ACTIVE = "SET_PROJECT_NAV_ACTIVE";
export const SET_PROJECT_LAST_VERSION = "SET_PROJECT_LAST_VERSION";

export const SET_PROJECT_ACTION_ROLE_DIALOG = "SET_PROJECT_ACTION_ROLE_DIALOG";
export const SET_PROJECT_ACTION_LINKED_COMPANY =
  "SET_PROJECT_ACTION_LINKED_COMPANY";

export const SET_UNLINK_CONTACT_DIALOG = "SET_UNLINK_CONTACT_DIALOG";
