const initialState = {
  tabActive: "forecaster", // "market" || "forecaster"
  forecaster: {
    filter: {
      reportType: "pipeline", // "pipeline" || "forecaster"
      reportYear: "",
    },
    dataYears: [],
    reports: {
      data: [],
      load: false,
      error: null,
    },
  },
};

export default initialState;
