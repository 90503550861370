const initialState = {
  viewMode: null,
  modalAddSaveSearch: null,
  filterExpanded: true,
  topResultExpanded: true,
  searchMode: "", // project | company

  openOutsideSubs: false,

  mapPopUp: {
    open: false,
    mapData: [],
    mapPopupRefresh: 0,
  },

  saveSearchModal: false,
};

export default initialState;
