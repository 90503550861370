import initialState from "./initialState";
import {
  RESET_SALES_INSIGHT,
  RESET_SALES_INSIGHT_DATA,
  SET_SALES_FORECAST_FILTER,
  SET_SALES_FORECAST_DURATION_FILTER,
  SET_SALES_INSIGHT_TAB,
  SET_SALES_PERFORMANCE_INSIGHT_FILTER,
  SET_SALES_FORECAST_DURATION_DATA_LOAD,
  SET_SALES_FORECAST_DURATION_DATA,
  SET_SALES_FORECAST_DURATION_DATA_ERROR,
  SET_SALES_FORECAST_CATEGORY_DATA,
  SET_SALES_FORECAST_CATEGORY_DATA_ERROR,
  SET_SALES_FORECAST_CATEGORY_DATA_LOAD,
  SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_LOAD,
  SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA,
  SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_ERROR,
  SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_LOAD,
  SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA,
  SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_ERROR,
  SET_WON_LOST_INSIGHT_FILTER,
  SET_COMPETITOR_INSIGHT_FILTER,
  SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_FILTER,
  SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_PAGINATION,
  SET_SALES_FORECAST_CATEGORY_FILTER,
} from "./types";

const marketInsight = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SALES_INSIGHT_TAB: {
      return {
        ...state,
        tabActive: payload,
      };
    }

    //Sales Forecast Filter Data
    case SET_SALES_FORECAST_FILTER: {
      return {
        ...state,
        salesForecastFilter: {
          ...state.salesForecastFilter,
          ...payload,
        },
      };
    }

    // Set Data Sales Forecast Vertical Bar chart
    case SET_SALES_FORECAST_DURATION_FILTER: {
      return {
        ...state,
        salesForecastDurationFilter: {
          ...state.salesForecastDurationFilter,
          ...payload,
        },
      };
    }
    case SET_SALES_FORECAST_DURATION_DATA_LOAD: {
      return {
        ...state,
        salesForecastDuration: {
          ...initialState.salesForecastDuration,
          load: true,
        },
      };
    }
    case SET_SALES_FORECAST_DURATION_DATA: {
      return {
        ...state,
        salesForecastDuration: {
          ...initialState.salesForecastDuration,
          ...payload,
        },
      };
    }
    case SET_SALES_FORECAST_DURATION_DATA_ERROR: {
      return {
        ...state,
        salesForecastDuration: {
          ...initialState.salesForecastDuration,
          error: payload,
        },
      };
    }
    // Set Data Sales Forecast Category Bar chart
    case SET_SALES_FORECAST_CATEGORY_FILTER: {
      return {
        ...state,
        salesForecastCategoryFilter: {
          ...state.salesForecastCategoryFilter,
          ...payload,
        },
      };
    }
    case SET_SALES_FORECAST_CATEGORY_DATA_LOAD: {
      return {
        ...state,
        salesForecastCategory: {
          ...initialState?.salesForecastCategory,
          load: true,
        },
      };
    }
    case SET_SALES_FORECAST_CATEGORY_DATA: {
      return {
        ...state,
        salesForecastCategory: {
          ...initialState?.salesForecastCategory,
          data: payload,
        },
      };
    }
    case SET_SALES_FORECAST_CATEGORY_DATA_ERROR: {
      return {
        ...state,
        salesForecastCategory: {
          ...initialState?.salesForecastCategory,
          error: payload,
        },
      };
    }

    // Sales Performance
    case SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_FILTER: {
      return {
        ...state,
        salesPerformanceStatisticPagination: {
          ...initialState?.salesPerformanceStatisticPagination,
        },
        salesPerformanceStatisticFilter: {
          ...state.salesPerformanceStatisticFilter,
          ...payload,
        },
      };
    }
    case SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_PAGINATION: {
      return {
        ...state,
        salesPerformanceStatisticPagination: {
          ...state.salesPerformanceStatisticPagination,
          ...payload,
        },
      };
    }
    case SET_SALES_PERFORMANCE_INSIGHT_FILTER: {
      return {
        ...state,
        salesPerformanceStatisticPagination: {
          ...initialState?.salesPerformanceStatisticPagination,
        },
        salesPerformanceFilter: {
          ...state.salesPerformanceFilter,
          ...payload,
        },
      };
    }

    case SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_LOAD: {
      return {
        ...state,
        salesPerformanceSummaries: {
          ...initialState.salesPerformanceSummaries,
          load: true,
        },
      };
    }
    case SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA: {
      return {
        ...state,
        salesPerformanceSummaries: {
          ...initialState.salesPerformanceSummaries,
          data: payload,
        },
      };
    }
    case SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_ERROR: {
      return {
        ...state,
        salesPerformanceSummaries: {
          ...initialState.salesPerformanceSummaries,
          error: payload,
        },
      };
    }
    case SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_LOAD: {
      return {
        ...state,
        salesPerformanceStatistic: {
          ...initialState.salesPerformanceStatistic,
          load: true,
        },
      };
    }
    case SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA: {
      return {
        ...state,
        salesPerformanceStatistic: {
          ...initialState.salesPerformanceStatistic,
          ...payload,
        },
      };
    }
    case SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_ERROR: {
      return {
        ...state,
        salesPerformanceStatistic: {
          ...initialState.salesPerformanceStatistic,
          error: payload,
        },
      };
    }

    // Won Lost
    case SET_WON_LOST_INSIGHT_FILTER: {
      return {
        ...state,
        wonLost: {
          ...state.wonLost,
          filter: {
            ...state.wonLost.filter,
            ...payload,
          },
        },
      };
    }
    case SET_COMPETITOR_INSIGHT_FILTER: {
      return {
        ...state,
        competitor: {
          ...state.competitor,
          filter: {
            ...state.competitor.filter,
            ...payload,
          },
        },
      };
    }
    // Filter Data

    // Reset Data
    case RESET_SALES_INSIGHT_DATA: {
      return {
        ...state,
        salesForecast: {
          ...initialState.salesForecast,
        },
        salesPerformance: {
          ...initialState.salesPerformance,
        },
        winLost: {
          ...initialState.winLost,
        },
        competitor: {
          ...initialState.competitor,
        },
      };
    }
    case RESET_SALES_INSIGHT: {
      return initialState;
    }
    // Reset Data

    default:
      return state;
  }
};

export default marketInsight;
