const initialState = {
  categoryList: [],
  countriesList: [],
  locationList: [],
  stageList: [],
  roleList: [],
  roleListSetting: [],
  projectSavedQueryList: [],
  companySavedQueryList: [],
  lastUpdateList: [],
  searchSortBy: [
    { value: "relevance", label: "Relevance" },
    { value: "last_updated", label: "Last Updated" },
    { value: "biggest_value", label: "Biggest Value" },
    { value: "lowest_value", label: "Lowest Value" },
    { value: "early_stage", label: "Early Stage" },
    { value: "latest_stage", label: "Latest Stage" },
  ],
  searchSortByCompany: [
    { value: "relevance", label: "Relevance" },
    { value: "a_z", label: "A to Z" },
    { value: "z_a", label: "Z to A" },
    { value: "biggest_project_value", label: "Biggest Matched Project Value" },
    { value: "lowest_project_value", label: "Lowest Matched Project Value" },
    { value: "number_of_total_project", label: "Number of Total Project" },
  ],
  greenBuildingList: [],
  salesPipelineList: [],
  keyAccountList: [],
  developmentTypeList: [],
  ownershipTypeList: [],
  tenderStatusList: [],
  companyProjectSortList: [
    { value: "relevance", label: "Relevance" },
    { value: "a_z", label: "A to Z" },
    { value: "z_a", label: "Z to A" },
    { value: "last_updated", label: "Last Updated" },
    { value: "biggest_project_value", label: "Biggest Value" },
    { value: "lowest_project_value", label: "Lowest Value" },
  ],
  companyContactSortList: [
    { value: "a_z", label: "A to Z" },
    { value: "z_a", label: "Z to A" },
    { value: "biggest_total_project", label: "Biggest Total Project" },
    { value: "lowest_total_project", label: "Lowest Total Project" },
  ],
  contactBy: [],
  assignedTo: [],
  assignedByList: [],
  visibilityTypeCode: [],
  presetPipelineList: [],
  presetAccountList: [],
  inquiriesTypeList: [],
  constructionStartDateList: [],
  tenderClosingDateList: [],
  greenBuildingStatusList: [],
  greenBuildingSchemeList: [],
  appointedMethodList: [],
  quotedList: [],
  productSpecifiedList: [],
  probabilityList: [],
  wonLoseList: [],
  preferenceLanguageList: [],
  companyGroupList: [],
  projectCFList: [],
  companyCFList: [],

  loadState: {
    categoryLoad: false,
    countriesLoad: false,
    locationLoad: false,
    stageLoad: false,
    roleLoad: false,
    projectSavedQueryLoad: false,
    companySavedQueryLoad: false,
    lastUpdateLoad: false,
    constructionStartDateLoad: false,
    tenderClosingDateLoad: false,
    greenBuildingLoad: false,
    salesPipelineLoad: false,
    keyAccountLoad: false,
    developmentTypeLoad: false,
    ownershipTypeLoad: false,
    tenderStatusLoad: false,
    greenBuildingStatusLoad: false,
    greenBuildingSchemeLoad: false,
    appointedMethodLoad: false,
    locationContinentLoad: false,
    preferenceLanguageListLoad: false,
    companyGroupListLoad: false,
    opportunityDetailsLoad: false,
    projectCFLoad: false,
    companyCFLoad: false,
    roleStatusLoad: false,
    buildingMaterialLoad: false,
  },
  buildingMaterialList: [],
  searchLimit: [
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 250, label: "250" },
    { value: 500, label: "500" },
  ],
  forecastDayList: [
    { value: 30, label: "Next 30 Days" },
    { value: 60, label: "Next 60 Days" },
    { value: 90, label: "Next 90 Days" },
    { value: 180, label: "Next 180 Days" },
    { value: 365, label: "Next 365 Days" },
    { value: 730, label: "Next 2 Years" },
  ],
  roleStatusList: [],
};

export default initialState;
