export const SET_SEARCH_PAGE_FILTER_EXPAND = "SET_SEARCH_PAGE_FILTER_EXPAND";
export const SET_SEARCH_PAGE_TOP_RESULT_EXPAND = "SET_SEARCH_PAGE_TOP_RESULT_EXPAND";
export const SET_SEARCH_PAGE_MODE = "SET_SEARCH_PAGE_MODE";
export const SET_SEARCH_VIEW_MODE = "SET_SEARCH_VIEW_MODE";

export const SET_OPEN_OUTSIDE_SUBS = "SET_OPEN_OUTSIDE_SUBS";
export const SET_MODAL_SAVE_SEARCH = "SET_MODAL_SAVE_SEARCH";

export const RESET_SEARCH_RESULT = "RESET_SEARCH_RESULT";

export const SET_MAP_DATA = "SET_MAP_DATA";
export const SET_MAP_POPUP_OPEN = "SET_MAP_POPUP_OPEN";
export const SET_MAP_POPUP_DATA = "SET_MAP_POPUP_DATA";
export const SET_MAP_POPUP_REFRESH = "SET_MAP_POPUP_REFRESH";