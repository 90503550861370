const initialState = {
  keyAccountSelectedFolder: null,
  keyAccountFolderUserId: null,
  keyAccountListDefaultFolder: {
    data: [],
    load: false,
  },
  keyAccountListMyFolder: {
    data: [],
    load: false,
  },
  keyAccountFilter: {
    location: null,
    advancedLocation: null,
    roleGroups: [],
    keyword: "",
  },
  keyAccountUtil: {
    page: 1,
    limit: 25,
    sortby: "createdAt",
    order: "desc",
    isDefault: "1",
  },
  keyAccountData: {
    data: [],
    error: false,
    load: false,
    total: 0,
  },
  keyAccountListView: 1,
  keyAccountFolderType: 1,
  keyAccountSelectedCheck: [],
  keyAccountHistoryPage: [],

  companySuggestionsData: null,
};

export default initialState;
