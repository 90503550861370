const initialState = {
  followupTaskData: [],
  followupTaskCount : {},
  followupTaskLimit: 10,
  followupTaskTotal: 0,
  followupTaskPage: 1,
  followupTaskLoad: false,
  followupTaskError: false,
};

export default initialState;