import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import ssoConfig from "../config/sso.config";

const user = localStorage.getItem("profile");

interface CommonRouteProps {
  children?: React.ReactElement;
}

const CommonRoute = ({ children }: CommonRouteProps) => {
  const location = useLocation();

  /*
   * Handle if login as client when condition is logged in,
   * And then do force login and auto relogin by saving flag forceLogin and callbackRedirect in localStorage
   */
  const authCode = localStorage.getItem("authCode");
  const params = new URLSearchParams(location.search);
  if (
    user &&
    location.pathname === "/callback" &&
    (
      (
        params.get("source") === "crm" &&
        params.get("username") &&
        params.get("access_token") &&
        params.get("username") !== JSON.parse(user).username
      )
      ||
      (
        params.get("source") === "sso" &&
        params.get("auth_code") &&
        params.get("auth_code") !== authCode
      )
    )
  ) {
    const redirect = `${location.pathname}${location.search}`;
    localStorage.setItem("callbackRedirect", redirect);
    localStorage.setItem("nextAction", "forceLogin");
    window.location.replace(
      `/logout?app=${ssoConfig.tnlmAppName}`
    );
    // return (
    //   <Navigate
    //     to={`${ssoConfig.tnlmBaseUri}/logout?app=${ssoConfig.tnlmAppName}`}
    //     replace
    //   />
    // );
  } else if (user) {
    return (
      <Navigate
        to={
          new URLSearchParams(location.search)?.get("redirect")
            ? location.search.replace("?redirect=", "")
            : "/"
        }
        replace
      />
    );
  }
  return children ? children : <Outlet />;
  // End of handle if login as client when condition is logged in
};

export default CommonRoute;
