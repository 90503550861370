import initialState from "./initialState";
import {
  RESET_NOTIFICATION,
  RESET_NOTIFICATIONS_FILTER,
  RESET_NOTIFICATIONS_LIST,
  RESET_NOTIFICATIONS_PARAMETERS,
  SET_NOTIFICATIONS_DETAIL,
  SET_NOTIFICATIONS_DETAIL_ERROR,
  SET_NOTIFICATIONS_DETAIL_LOAD,
  SET_NOTIFICATIONS_FILTER,
  SET_NOTIFICATIONS_LIST,
  SET_NOTIFICATIONS_LIST_ERROR,
  SET_NOTIFICATIONS_LIST_LOAD,
  SET_NOTIFICATIONS_PARAMETERS,
  SET_NOTIFICATIONS_POPUP_NOT_FOUND_STATE,
  SET_NOTIFICATIONS_SELECTED,
  SET_NOTIFICATIONS_TAB,
  SET_NOTIFICATIONS_SELECTED_FROM_EMAIL,
} from "./types";

const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATIONS_TAB: {
      return {
        ...state,
        notifTab: payload,
      };
    }
    case SET_NOTIFICATIONS_PARAMETERS: {
      return {
        ...state,
        parameters: {
          ...state.parameters,
          ...payload,
        },
        filter: initialState.filter,
      };
    }
    case RESET_NOTIFICATIONS_PARAMETERS: {
      return {
        ...state,
        parameters: initialState.parameters,
      };
    }
    case SET_NOTIFICATIONS_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      };
    }
    case RESET_NOTIFICATIONS_FILTER: {
      return {
        ...state,
        filter: initialState.filter,
      };
    }
    case SET_NOTIFICATIONS_SELECTED: {
      return {
        ...state,
        selected: payload,
        selectedData: initialState.selectedData,
      };
    }
    case SET_NOTIFICATIONS_POPUP_NOT_FOUND_STATE: {
      return {
        ...state,
        popupNotFoundState: payload,
      };
    }
    case SET_NOTIFICATIONS_LIST: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          notification: payload.data,
          totalData: payload.total,
        },
      };
    }
    case SET_NOTIFICATIONS_LIST_LOAD: {
      return {
        ...state,
        selected: null,
        selectedData: initialState.selectedData,
        popupNotFoundState: false,
        resultData: {
          ...initialState.resultData,
          load: true,
        },
      };
    }
    case SET_NOTIFICATIONS_LIST_ERROR: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          error: payload || "something went wrong",
        },
      };
    }
    case RESET_NOTIFICATIONS_LIST: {
      return {
        ...state,
        resultData: initialState.resultData,
      };
    }

    case SET_NOTIFICATIONS_DETAIL: {
      return {
        ...state,
        selectedData: {
          ...initialState.selectedData,
          detail: payload,
        },
      };
    }
    case SET_NOTIFICATIONS_DETAIL_LOAD: {
      return {
        ...state,
        selectedData: {
          ...initialState.selectedData,
          load: true,
        },
      };
    }
    case SET_NOTIFICATIONS_DETAIL_ERROR: {
      return {
        ...state,
        selectedData: {
          ...initialState.selectedData,
          error: payload || "something went wrong",
        },
      };
    }

    case SET_NOTIFICATIONS_SELECTED_FROM_EMAIL: {
      return {
        ...state,
        selectedFromEmail: payload,
      };
    }

    case RESET_NOTIFICATION: {
      return initialState;
    }

    default:
      return state;
  }
};

export default notifications;
