export const SET_NOTIFICATIONS_TAB = "SET_NOTIFICATIONS_TAB";
export const SET_NOTIFICATIONS_SELECTED = "SET_NOTIFICATIONS_SELECTED";
export const SET_NOTIFICATIONS_SELECTED_FROM_EMAIL =
  "SET_NOTIFICATIONS_SELECTED_FROM_EMAIL";
export const SET_NOTIFICATIONS_PARAMETERS = "SET_NOTIFICATIONS_PARAMETERS";
export const RESET_NOTIFICATIONS_PARAMETERS = "RESET_NOTIFICATIONS_PARAMETERS";
export const SET_NOTIFICATIONS_FILTER = "SET_NOTIFICATIONS_FILTER";
export const RESET_NOTIFICATIONS_FILTER = "RESET_NOTIFICATIONS_FILTER";

export const SET_NOTIFICATIONS_LIST = "SET_NOTIFICATIONS_LIST";
export const SET_NOTIFICATIONS_LIST_LOAD = "SET_NOTIFICATIONS_LIST_LOAD";
export const SET_NOTIFICATIONS_LIST_ERROR = "SET_NOTIFICATIONS_LIST_ERROR";
export const RESET_NOTIFICATIONS_LIST = "RESET_NOTIFICATIONS_LIST";

export const SET_NOTIFICATIONS_DETAIL = "SET_NOTIFICATIONS_DETAIL";
export const SET_NOTIFICATIONS_DETAIL_LOAD = "SET_NOTIFICATIONS_DETAIL_LOAD";
export const SET_NOTIFICATIONS_DETAIL_ERROR = "SET_NOTIFICATIONS_DETAIL_ERROR";

export const SET_NOTIFICATIONS_POPUP_NOT_FOUND_STATE =
  "SET_NOTIFICATIONS_POPUP_NOT_FOUND_STATE";

export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
