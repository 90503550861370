const initialState = {
  tab: "", // 'project' || 'company' || ''
  cfProjectList: {
    data: [],
    total: 0,
    load: false,
    error: "",
  },
  cfCompanyList: {
    data: [],
    total: 0,
    load: false,
    error: "",
  },
  cfTypeList: [],
  cfSelectedList: [],
};

export default initialState;
