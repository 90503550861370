// import moment from 'moment';

const initialState = {
  firstLoad: true,

  companyInformation: {},
  companyInformationLoad: true,
  companyInformationError: false,

  companyOtherInformation: [],
  companyOtherInformationLoad: true,
  companyOtherInformationError: false,

  companyTeamWatchlist: [],
  companyTeamWatchlistLoad: true,
  companyTeamWatchlistError: false,

  companyProjectList: [],
  companyProjectTotal: 0,
  companyProjectTotalOther: 0,
  companyProjectTotalOtherUec: 0,
  companyProjectLastUpdate: {
    startDate: "",
    endDate: "",
  },
  companyProjectPage: 1,
  companyProjectSort: "last_updated",
  companyProjectLoad: true,
  companyProjectError: false,
  companyProjectWatchlistForm: null,

  companyContactList: [],
  companyContactTotal: 0,
  companyContactPage: 1,
  companyContactSort: "biggest_total_project",
  companyContactLoad: true,
  companyContactError: false,
  companyAllContactList: [],

  companyProjectContactExact: true,
  companyProjectContactKeyword: "",
  companyProjectContactLimit: 10,
  companyProjectContactFilter: {
    categories: null,
    roleGroups: null,
    stage: null,
    tenderStatus: null,
    activeProject: null,
    contact: null,
  },

  companyNoteKeyword: "",
  companyNoteList: [],
  companyNoteTotal: 0,
  companyNotePage: 1,
  companyNoteLimit: 5,
  companyNoteLoad: true,
  companyNoteError: false,
  companyNoteDialog: {
    action: "",
    data: {},
  },

  companyFollowupKeyword: "",
  companyFollowupList: [],
  companyFollowupTotal: 0,
  companyFollowupPage: 1,
  companyFollowupLimit: 5,
  companyFollowupLoad: true,
  companyFollowupError: false,
  companyFollowupContactBy: 0,
  companyFollowupAssignedBy: 0,
  companyFollowupAssignedTo: 0,
  companyFollowupStartDate: null,
  companyFollowupEndDate: null,
  companyFollowupForm: null,
  companyFollowupRemove: 0,

  companyWorkingWithList: [],
  companyWorkingWithLoad: true,
  companyWorkingWithError: false,
  companyWorkingWithKeyword: "",
  companyWatchlistForm: null,

  companyOverviewCollapse: false,

  partnerNetworkCollapse: false,

  relationshipMappingCollapse: false,
  partnerNetwork: {
    data: [],
    load: false,
    error: false,
  },

  actionContactDialog: {
    open: false,
    data: {},
  },
  actionDeleteContactDialog: {
    open: false,
    contactId: "",
  },
};

export default initialState;
