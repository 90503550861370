const initialState = {
    projectData: [],
    projectLoad: true,
    projectLocationData: [],
    projectLocationLoad: true,
    projectDetailLoad: true,

    projectOtherData: [],
    projectOtherLoad: true,

    projectLocationOtherData: [],
    projectLocationOtherLoad: true,

    projectSortBy: 'relevance',
    projectLimit: 25,
    projectPage: 1,

    projectDetail: null,
    projectDetailData: null,
    projectSelected: [],

    projectTotal: 0,
    projectOutsideSubscription: 0,

    projectDataError: false,
    projectDetailError: false,

    projectWatchlistForm: null,
};

export default initialState