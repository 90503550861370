const initialState = {
  filter: {
    lastUpdate: "7",
    startDate: null,
    endDate: null,
  },
  subscriptionInsightProject: {
    data: null,
    load: false,
    error: false,
  },
  subscriptionInsightCategory: {
    data: null,
    load: false,
    error: false,
  },
  subscriptionInsightLocationFilter: {
    country_id: 0,
  },
  subscriptionInsightLocation: {
    data: null,
    load: false,
    error: false,
  },
  subscriptionInsightOpportunitiesFilterOpts: 1,
  subscriptionInsightOpportunitiesFilter: {
    country_id: "",
    categories: [],
  },
  subscriptionInsightOpportunities: {
    data: null,
    load: false,
    error: false,
  },
  subscriptionInsightTotalCompanies: {
    data: null,
    load: false,
    error: false,
  },
  subscriptionInsightTotalContact: {
    data: null,
    load: false,
    error: false,
  },
};

export default initialState;
