const initialState = {
  filter: {
    subscriberId: 0,
    lastDay: "7",
    startDate: "",
    endDate: "",
  },
  userInsight: {
    data: null,
    load: false,
    error: false,
  },
  userInsightActive: null,

  individualActivityFilter: {
    skip: 0,
    limit: 25,

    filteredRelationSubscriberId: [],
    keywords: "",
  },
  individualActivityUserList: {
    data: [],
    total: 0,
    load: false,
    error: true,
  },
  individualActivity: {
    data: [],
    total: 0,
    load: false,
    error: true,
  },
  individualActivityDetail: {
    data: null,
    total: 0,
    load: false,
    error: false,
  },
};

export default initialState;
