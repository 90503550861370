import { combineReducers } from "redux";
import Layout from "./reducers/layout";
import User from "./reducers/user";
import SearchResult from "./reducers/search-result";
import GlobalSearch from "./reducers/global-search";
import ListCombo from "./reducers/list-combo";
import SearchProject from "./reducers/search-project";
import SearchCompany from "./reducers/search-company";
import DetailCompany from "./reducers/detail-company";
import DetailProject from "./reducers/detail-project";
import SaveSearch from "./reducers/save-search";
import FollowupTask from "./reducers/followup-task";
import Watchlist from "./reducers/watchlist";
import Notifications from "./reducers/notifications";
import PresetAlert from "./reducers/setting-preset-alert";
import LanguageLabel from "./reducers/language-label";
import ExcelTemplate from "./reducers/setting-excel-template";
import FilterProject from "./reducers/filter-project";
import FilterCompany from "./reducers/filter-company";
import Dashboard from "./reducers/dashboard";

import ProjectList from "./reducers/project-list";
import CompanyList from "./reducers/company-list";
import WatchlistAlertNotification from "./reducers/watchlist-alert-notification";
import WatchlistNotif from "./reducers/watchlist-notification";
import MarketInsight from "./reducers/market-insight";
import SalesInsight from "./reducers/sales-insight";
import UserActivityInsight from "./reducers/user-activity-insight";
import SubscriptionInsight from "./reducers/subscription-insight";
import ProjectPipeline from "./reducers/project-pipeline";
import ProjectPipelineLocation from "./reducers/project-pipeline-location";
import KeyAccount from "./reducers/key-account";
import KeyAccountLocation from "./reducers/key-account-location";
import archive from "./reducers/archive";
import cfSetting from "./reducers/custom-field-settings";

export default combineReducers({
  layout: Layout,
  user: User,
  searchResult: SearchResult,
  globalSearch: GlobalSearch,
  listCombo: ListCombo,
  searchProject: SearchProject,
  searchCompany: SearchCompany,
  detailCompany: DetailCompany,
  detailProject: DetailProject,
  saveSearch: SaveSearch,
  followupTask: FollowupTask,
  watchlist: Watchlist,
  notifications: Notifications,
  presetAlert: PresetAlert,
  languageLabel: LanguageLabel,
  excelTemplate: ExcelTemplate,
  filterProject: FilterProject,
  filterCompany: FilterCompany,
  dashboard: Dashboard,
  projectList: ProjectList,
  companyList: CompanyList,
  watchlistAlertNotification: WatchlistAlertNotification,
  watchlistNotif: WatchlistNotif,
  marketInsight: MarketInsight,
  salesInsight: SalesInsight,
  userActivityInsight: UserActivityInsight,
  subscriptionInsight: SubscriptionInsight,
  projectPipeline: ProjectPipeline,
  projectPipelineLocation: ProjectPipelineLocation,
  keyAccount: KeyAccount,
  keyAccountLocation: KeyAccountLocation,
  archive: archive,
  cfSetting: cfSetting,
});
