import initialState from "./initialState";
import {
  SET_WATCHLIST_NOTIF_DATA,
  SET_WATCHLIST_NOTIF_ERROR,
  SET_WATCHLIST_NOTIF_FILTER,
  SET_WATCHLIST_NOTIF_FOLDER_SELECTED,
  SET_WATCHLIST_NOTIF_KEY_ACCOUNT_REQUEST,
  SET_WATCHLIST_NOTIF_LIMIT,
  SET_WATCHLIST_NOTIF_LOAD,
  SET_WATCHLIST_NOTIF_PAGE,
  SET_WATCHLIST_NOTIF_PIPELINE_REQUEST,
  SET_WATCHLIST_NOTIF_SHOW_NOTE_TASK,
  SET_WATCHLIST_NOTIF_SOURCE,
  SET_WATCHLIST_NOTIF_SOURCE_LOAD,
} from "./type";

const watchlistNotif = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_WATCHLIST_NOTIF_SOURCE: {
      return {
        ...initialState,
        watchlistNotifSource: payload,
      };
    }
    case SET_WATCHLIST_NOTIF_SOURCE_LOAD: {
      return {
        ...initialState,
        watchlistNotifSourceLoad: true,
      };
    }
    case SET_WATCHLIST_NOTIF_FOLDER_SELECTED: {
      return {
        ...state,
        watchlistNotifFolderSelected: payload,
        watchlistPipelineNotifRequest:
          initialState.watchlistPipelineNotifRequest,
        watchlistKeyAccountNotifRequest:
          initialState.watchlistKeyAccountNotifRequest,
        watchlistNotifFilter: initialState.watchlistNotifFilter,
        watchlistNotifList: initialState.watchlistNotifList,
      };
    }
    case SET_WATCHLIST_NOTIF_SHOW_NOTE_TASK: {
      return {
        ...state,
        watchlistNotifShowNoteTask: payload,
      };
    }
    case SET_WATCHLIST_NOTIF_PIPELINE_REQUEST: {
      return {
        ...state,
        watchlistPipelineNotifRequest: {
          ...state.watchlistPipelineNotifRequest,
          ...payload,
        },
        watchlistNotifFilter: {
          ...state.watchlistNotifFilter,
          page: 1,
        },
      };
    }
    case SET_WATCHLIST_NOTIF_KEY_ACCOUNT_REQUEST: {
      return {
        ...state,
        watchlistKeyAccountNotifRequest: {
          ...state.watchlistKeyAccountNotifRequest,
          ...payload,
        },
        watchlistNotifFilter: {
          ...state.watchlistNotifFilter,
          page: 1,
        },
      };
    }
    case SET_WATCHLIST_NOTIF_FILTER: {
      return {
        ...state,
        watchlistNotifFilter: {
          ...state.watchlistNotifFilter,
          sortby: payload.sortby,
          order: payload.order,
          page: 1,
        },
      };
    }
    case SET_WATCHLIST_NOTIF_PAGE: {
      return {
        ...state,
        watchlistNotifFilter: {
          ...state.watchlistNotifFilter,
          page: payload,
        },
      };
    }
    case SET_WATCHLIST_NOTIF_LIMIT: {
      return {
        ...state,
        watchlistNotifFilter: {
          ...state.watchlistNotifFilter,
          limit: payload,
          page: 1,
        },
      };
    }
    case SET_WATCHLIST_NOTIF_DATA: {
      return {
        ...state,
        watchlistNotifList: {
          ...initialState.watchlistNotifList,
          data: payload.data,
          total: payload.total,
          included: payload.included,
          load: false,
        },
      };
    }
    case SET_WATCHLIST_NOTIF_LOAD: {
      return {
        ...state,
        watchlistNotifList: {
          ...initialState.watchlistNotifList,
          load: true,
        },
      };
    }
    case SET_WATCHLIST_NOTIF_ERROR: {
      return {
        ...state,
        watchlistNotifList: {
          ...initialState.watchlistNotifList,
          error: true,
          load: false,
        },
      };
    }

    default:
      return state;
  }
};

export default watchlistNotif;
