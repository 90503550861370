import initialState from "./initialState";
import {
  SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_COMPANY,
  SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_PROJECT,
  SET_EXCEL_TEMPLATE_COLUMNS,
  SET_EXCEL_TEMPLATE_COLUMNS_COMPANY,
  SET_EXCEL_TEMPLATE_DESCRIPTION_COMPANY,
  SET_EXCEL_TEMPLATE_DESCRIPTION_PROJECT,
  SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT,
  SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT_COMPANY,
  SET_EXCEL_TEMPLATE_EXPORT_COMBINE,
  SET_EXCEL_TEMPLATE_EXPORT_COMBINE_COMPANY,
  SET_EXCEL_TEMPLATE_EXPORT_COMPANY_AS_COLUMN,
  SET_EXCEL_TEMPLATE_EXPORT_FORMAT,
  SET_EXCEL_TEMPLATE_EXPORT_FORMAT_COMPANY,
  SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_COMPANY,
  SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_PROJECT,
  SET_EXCEL_TEMPLATE_ROLES,
  SET_EXCEL_TEMPLATE_ROLES_COMPANY,
  SET_EXCEL_TEMPLATE_STATIC_COMPANY_ROLE_PROJECT,
  SET_EXCEL_TEMPLATE_TAB_ACTIVE,
  SET_EXCEL_TEMPLATE_TAB_ACTIVE_COMPANY,
  SET_EXCEL_TEMPLATE_TAB_ACTIVE_PROJECT,
  SET_EXCEL_TEMPLATE_VISIBILITY,
  SET_EXCEL_TEMPLATE_VISIBILITY_COMPANY,
} from "./types";

const excelTemplate = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EXCEL_TEMPLATE_TAB_ACTIVE: {
      return {
        ...state,
        tabActive: payload,
      };
    }
    case SET_EXCEL_TEMPLATE_EXPORT_FORMAT: {
      return {
        ...state,
        project: {
          ...state.project,
          exportFormat: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_EXPORT_COMBINE: {
      return {
        ...state,
        project: {
          ...state.project,
          exportCombine: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT: {
      return {
        ...state,
        project: {
          ...state.project,
          excludeResignedContact: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_EXPORT_COMPANY_AS_COLUMN: {
      return {
        ...state,
        project: {
          ...state.project,
          exportCompanyDataAsColumn: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_COLUMNS: {
      return {
        ...state,
        project: {
          ...state.project,
          columns: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_ROLES: {
      return {
        ...state,
        project: {
          ...state.project,
          roles: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_TAB_ACTIVE_PROJECT: {
      return {
        ...state,
        project: {
          ...state.project,
          tabActive: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_DESCRIPTION_PROJECT: {
      return {
        ...state,
        project: {
          ...state.project,
          description: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_STATIC_COMPANY_ROLE_PROJECT: {
      return {
        ...state,
        project: {
          ...state.project,
          staticCompanyRole: payload,
        },
      };
    }

    case SET_EXCEL_TEMPLATE_EXPORT_FORMAT_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          exportFormat: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_EXPORT_COMBINE_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          exportCombine: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_COLUMNS_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          columns: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_ROLES_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          roles: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_TAB_ACTIVE_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          tabActive: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_PROJECT: {
      return {
        ...state,
        project: {
          ...state.project,
          refresh: new Date(),
        },
      };
    }
    case SET_EXCEL_TEMPLATE_REFRESH_LIST_TEMPLATE_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          refresh: new Date(),
        },
      };
    }

    case SET_EXCEL_TEMPLATE_VISIBILITY: {
      return {
        ...state,
        project: {
          ...state.project,
          visibility: payload,
        },
      };
    }

    case SET_EXCEL_TEMPLATE_VISIBILITY_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          visibility: payload,
        },
      };
    }

    case SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_PROJECT: {
      return {
        ...state,
        project: {
          ...state.project,
          ...payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_ADDITIONAL_OPTIONAL_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          ...payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_EXCLUDE_RESIGNED_CONTACT_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          excludeResignedContact: payload,
        },
      };
    }
    case SET_EXCEL_TEMPLATE_DESCRIPTION_COMPANY: {
      return {
        ...state,
        company: {
          ...state.company,
          description: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default excelTemplate;
