import initialState from "./initialState";
import {
  SET_WATCHLIST_TAB,
  SET_PIPELINE_SHOW_NOTE_TASK,
  SET_PIPELINE_FORM,
  SET_PIPELINE_ALERT_FORM,
  SET_PIPELINE_FOLDER_USER_ID,
  SET_PIPELINE_FOLDER_LOAD,
  SET_PIPELINE_FOLDER_DATA,
  SET_PIPELINE_SELECTED_FOLDER,
  SET_PIPELINE_PROJECT_FILTER,
  SET_PIPELINE_PROJECT_REQUEST,
  SET_PIPELINE_DATA,
  SET_PIPELINE_DATA_LOAD,
  SET_PIPELINE_DATA_ERROR,
  SET_PIPELINE_ARCHIVE,
  RESET_PIPELINE,
  SET_KEYACCOUNT_SHOW_NOTE_TASK,
  SET_KEYACCOUNT_FORM,
  SET_KEYACCOUNT_ALERT_FORM,
  SET_KEYACCOUNT_FOLDER_USER_ID,
  SET_KEYACCOUNT_FOLDER_LOAD,
  SET_KEYACCOUNT_FOLDER_DATA,
  SET_KEYACCOUNT_SELECTED_FOLDER,
  SET_KEYACCOUNT_PROJECT_FILTER,
  SET_KEYACCOUNT_PROJECT_REQUEST,
  SET_KEYACCOUNT_DATA,
  SET_KEYACCOUNT_DATA_LOAD,
  SET_KEYACCOUNT_DATA_ERROR,
  SET_KEYACCOUNT_ARCHIVE,
  RESET_KEYACCOUNT,
  RESET_WATCHLIST,
  SET_PIPELINE_PROJECT_PAGE,
  SET_KEYACCOUNT_PROJECT_PAGE,
  SET_NOTIFICATION_SOURCE_DATA,
  SET_PROJECT_PIPELINE_LIMIT_DATA_PER_PAGE,
  SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE,
} from "./type";

const watchlist = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_WATCHLIST_TAB: {
      return {
        ...state,
        watchlistTabActive: payload,
        projectpipelineArchive: false,
        keyaccountArchive: false,
      };
    }

    case SET_PIPELINE_SHOW_NOTE_TASK: {
      return {
        ...state,
        projectpipelineShowNoteTask: payload,
      };
    }

    case SET_PIPELINE_PROJECT_REQUEST: {
      return {
        ...state,
        projectpipelineRequest: {
          ...state.projectpipelineRequest,
          ...payload,
        },
        projectpipelineFilter: {
          ...state.projectpipelineFilter,
          page: 1,
        },
      };
    }
    case SET_PIPELINE_FORM: {
      return {
        ...state,
        projectpipelineForm: payload,
      };
    }
    case SET_PIPELINE_ALERT_FORM: {
      return {
        ...state,
        projectpipelineAlertForm: payload,
      };
    }
    case SET_PIPELINE_FOLDER_USER_ID: {
      return {
        ...state,
        projectpipelineFolderUserId: payload,
      };
    }
    case SET_PIPELINE_FOLDER_LOAD: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            projectpipelineListDefaultFolder: {
              ...state.projectpipelineListDefaultFolder,
              load: true,
              data: [],
            },
            projectpipelineSelectedFolder: null,
          }
        : {
            ...state,
            projectpipelineListMyFolder: {
              ...state.projectpipelineListMyFolder,
              load: true,
              data: [],
            },
            projectpipelineSelectedFolder: null,
          };
    }
    case SET_PIPELINE_FOLDER_DATA: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            projectpipelineListDefaultFolder: {
              ...state.projectpipelineListDefaultFolder,
              load: false,
              data: payload.data,
            },
          }
        : {
            ...state,
            projectpipelineListMyFolder: {
              ...state.projectpipelineListMyFolder,
              load: false,
              data: payload.data,
            },
          };
    }
    case SET_PIPELINE_SELECTED_FOLDER: {
      return {
        ...state,
        projectpipelineData: initialState.projectpipelineData,
        projectpipelineFilter: initialState.projectpipelineFilter,
        projectpipelineRequest: initialState.projectpipelineRequest,
        projectpipelineSelectedFolder: payload,
        projectpipelineArchive: false,
      };
    }
    case RESET_PIPELINE: {
      return {
        ...state,
        projectpipelineSelectedFolder:
          initialState.projectpipelineSelectedFolder,
        projectpipelineShowNoteTask: initialState.projectpipelineShowNoteTask,
        projectpipelineForm: initialState.projectpipelineForm,
        projectpipelineAlertForm: initialState.projectpipelineAlertForm,
        projectpipelineRequest: initialState.projectpipelineRequest,
        projectpipelineFolderUserId: initialState.projectpipelineFolderUserId,
        projectpipelineListDefaultFolder:
          initialState.projectpipelineListDefaultFolder,
        projectpipelineListMyFolder: initialState.projectpipelineListMyFolder,
        projectpipelineData: initialState.projectpipelineData,
      };
    }
    case SET_PIPELINE_PROJECT_FILTER: {
      return {
        ...state,
        projectpipelineFilter: {
          ...state.projectpipelineFilter,
          sortby: payload.sortby,
          order: payload.order,
          page: 1,
        },
      };
    }
    case SET_PIPELINE_PROJECT_PAGE: {
      return {
        ...state,
        projectpipelineFilter: {
          ...state.projectpipelineFilter,
          page: payload,
        },
      };
    }
    case SET_PIPELINE_DATA: {
      return {
        ...state,
        projectpipelineData: {
          ...initialState.projectpipelineData,
          data: payload.data,
          total: payload.total,
          load: false,
          error: false,
        },
      };
    }
    case SET_PIPELINE_DATA_LOAD: {
      return {
        ...state,
        projectpipelineData: {
          ...initialState.projectpipelineData,
          load: true,
        },
      };
    }
    case SET_PIPELINE_ARCHIVE: {
      return {
        ...state,
        projectpipelineArchive: true,
        projectpipelineSelectedFolder: null,
      };
    }
    case SET_PIPELINE_DATA_ERROR: {
      return {
        ...state,
        projectpipelineData: {
          ...initialState.projectpipelineData,
          error: true,
        },
      };
    }

    case SET_KEYACCOUNT_SHOW_NOTE_TASK: {
      return {
        ...state,
        keyaccountShowNoteTask: payload,
      };
    }

    case SET_KEYACCOUNT_PROJECT_REQUEST: {
      return {
        ...state,
        keyaccountRequest: {
          ...state.keyaccountRequest,
          ...payload,
        },
        keyaccountFilter: {
          ...state.keyaccountFilter,
          page: 1,
        },
      };
    }
    case SET_KEYACCOUNT_FORM: {
      return {
        ...state,
        keyaccountForm: payload,
      };
    }
    case SET_KEYACCOUNT_ALERT_FORM: {
      return {
        ...state,
        keyaccountAlertForm: payload,
      };
    }
    case SET_KEYACCOUNT_FOLDER_USER_ID: {
      return {
        ...state,
        keyaccountFolderUserId: payload,
      };
    }
    case SET_KEYACCOUNT_FOLDER_LOAD: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            keyaccountListDefaultFolder: {
              ...state.keyaccountListDefaultFolder,
              load: true,
              data: [],
            },
            keyaccountSelectedFolder: null,
          }
        : {
            ...state,
            keyaccountListMyFolder: {
              ...state.keyaccountListMyFolder,
              load: true,
              data: [],
            },
            keyaccountSelectedFolder: null,
          };
    }
    case SET_KEYACCOUNT_FOLDER_DATA: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            keyaccountListDefaultFolder: {
              ...state.keyaccountListDefaultFolder,
              load: false,
              data: payload.data,
            },
          }
        : {
            ...state,
            keyaccountListMyFolder: {
              ...state.keyaccountListMyFolder,
              load: false,
              data: payload.data,
            },
          };
    }
    case SET_KEYACCOUNT_SELECTED_FOLDER: {
      return {
        ...state,
        keyaccountData: initialState.keyaccountData,
        keyaccountFilter: initialState.keyaccountFilter,
        keyaccountRequest: initialState.keyaccountRequest,
        keyaccountSelectedFolder: payload,
        keyaccountArchive: false,
      };
    }
    case RESET_KEYACCOUNT: {
      return {
        ...state,
        keyaccountSelectedFolder: initialState.keyaccountSelectedFolder,
        keyaccountShowNoteTask: initialState.keyaccountShowNoteTask,
        keyaccountForm: initialState.keyaccountForm,
        keyaccountAlertForm: initialState.keyaccountAlertForm,
        keyaccountRequest: initialState.keyaccountRequest,
        keyaccountFolderUserId: initialState.keyaccountFolderUserId,
        keyaccountListDefaultFolder: initialState.keyaccountListDefaultFolder,
        keyaccountListMyFolder: initialState.keyaccountListMyFolder,
        keyaccountData: initialState.keyaccountData,
      };
    }
    case SET_KEYACCOUNT_PROJECT_FILTER: {
      return {
        ...state,
        keyaccountFilter: {
          ...state.keyaccountFilter,
          sortby: payload.sortby,
          order: payload.order,
          page: 1,
        },
      };
    }
    case SET_KEYACCOUNT_PROJECT_PAGE: {
      return {
        ...state,
        keyaccountFilter: {
          ...state.keyaccountFilter,
          page: payload,
        },
      };
    }
    case SET_KEYACCOUNT_DATA: {
      return {
        ...state,
        keyaccountData: {
          ...initialState.keyaccountData,
          data: payload.data,
          total: payload.total,
        },
      };
    }
    case SET_KEYACCOUNT_DATA_LOAD: {
      return {
        ...state,
        keyaccountData: {
          ...initialState.keyaccountData,
          load: true,
        },
      };
    }
    case SET_KEYACCOUNT_ARCHIVE: {
      return {
        ...state,
        keyaccountArchive: true,
        keyaccountSelectedFolder: null,
      };
    }
    case SET_KEYACCOUNT_DATA_ERROR: {
      return {
        ...state,
        keyaccountData: {
          ...initialState.keyaccountData,
          error: true,
        },
      };
    }

    case SET_NOTIFICATION_SOURCE_DATA: {
      return {
        ...state,
        notificationSourceData: payload,
      };
    }

    case RESET_WATCHLIST: {
      return {
        ...initialState,
      };
    }
    case SET_PROJECT_PIPELINE_LIMIT_DATA_PER_PAGE: {
      return {
        ...state,
        projectpipelineFilter: {
          ...initialState.projectpipelineFilter,
          limit: payload,
        },
      };
    }
    case SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE: {
      return {
        ...state,
        keyaccountFilter: {
          ...initialState.keyaccountFilter,
          limit: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default watchlist;
