export const SET_COMPANY_NAVIGATION = "SET_COMPANY_NAVIGATION";

export const SET_COMPANY_INFORMATION = "SET_COMPANY_INFORMATION";
export const SET_COMPANY_INFORMATION_LOAD = "SET_COMPANY_INFORMATION_LOAD";
export const SET_COMPANY_INFORMATION_ERROR = "SET_COMPANY_INFORMATION_ERROR";

export const SET_COMPANY_OTHER_INFORMATION = "SET_COMPANY_OTHER_INFORMATION";
export const SET_COMPANY_OTHER_INFORMATION_LOAD =
  "SET_COMPANY_OTHER_INFORMATION_LOAD";
export const SET_COMPANY_OTHER_INFORMATION_ERROR =
  "SET_COMPANY_OTHER_INFORMATION_ERROR";

export const SET_COMPANY_TEAM_WATCHLIST = "SET_COMPANY_TEAM_WATCHLIST";
export const SET_COMPANY_TEAM_WATCHLIST_LOAD =
  "SET_COMPANY_TEAM_WATCHLIST_LOAD";
export const SET_COMPANY_TEAM_WATCHLIST_ERROR =
  "SET_COMPANY_TEAM_WATCHLIST_ERROR";

export const SET_COMPANY_PROJECT_LIST = "SET_COMPANY_PROJECT_LIST";
export const SET_COMPANY_PROJECT_LOAD = "SET_COMPANY_PROJECT_LOAD";
export const SET_COMPANY_PROJECT_ERROR = "SET_COMPANY_PROJECT_ERROR";
export const SET_COMPANY_PROJECT_PAGE = "SET_COMPANY_PROJECT_PAGE";
export const SET_COMPANY_PROJECT_TOTAL = "SET_COMPANY_PROJECT_TOTAL";
export const SET_COMPANY_PROJECT_TOTAL_OTHER =
  "SET_COMPANY_PROJECT_TOTAL_OTHER";
export const SET_COMPANY_PROJECT_TOTAL_OTHER_UEC =
  "SET_COMPANY_PROJECT_TOTAL_OTHER_UEC";
export const SET_COMPANY_PROJECT_LAST_UPDATE =
  "SET_COMPANY_PROJECT_LAST_UPDATE";
export const SET_COMPANY_PROJECT_SORT = "SET_COMPANY_PROJECT_SORT";

export const SET_COMPANY_CONTACT_LIST = "SET_COMPANY_CONTACT_LIST";
export const SET_COMPANY_CONTACT_LOAD = "SET_COMPANY_CONTACT_LOAD";
export const SET_COMPANY_CONTACT_ERROR = "SET_COMPANY_CONTACT_ERROR";
export const SET_COMPANY_CONTACT_PAGE = "SET_COMPANY_CONTACT_PAGE";
export const SET_COMPANY_CONTACT_TOTAL = "SET_COMPANY_CONTACT_TOTAL";
export const SET_COMPANY_CONTACT_SORT = "SET_COMPANY_CONTACT_SORT";

export const SET_COMPANY_PROJECT_CONTACT_EXACT =
  "SET_COMPANY_PROJECT_CONTACT_EXACT";
export const SET_COMPANY_PROJECT_CONTACT_KEYWORD =
  "SET_COMPANY_PROJECT_CONTACT_KEYWORD";
export const SET_COMPANY_PROJECT_CONTACT_LIMIT =
  "SET_COMPANY_PROJECT_CONTACT_LIMIT";
export const SET_COMPANY_PROJECT_CONTACT_FILTER =
  "SET_COMPANY_PROJECT_CONTACT_FILTER";
export const RESET_COMPANY_PROJECT_CONTACT_FILTER =
  "RESET_COMPANY_PROJECT_CONTACT_FILTER";

export const RESET_COMPANY_DETAIL = "RESET_COMPANY_DETAIL";

export const SET_COMPANY_NOTE_LIST = "SET_COMPANY_NOTE_LIST";
export const SET_COMPANY_NOTE_LOAD = "SET_COMPANY_NOTE_LOAD";
export const SET_COMPANY_NOTE_ERROR = "SET_COMPANY_NOTE_ERROR";
export const SET_COMPANY_NOTE_PAGE = "SET_COMPANY_NOTE_PAGE";
export const SET_COMPANY_NOTE_TOTAL = "SET_COMPANY_NOTE_TOTAL";
export const SET_COMPANY_NOTE_KEYWORD = "SET_COMPANY_NOTE_KEYWORD";

export const SET_COMPANY_FOLLOW_UP_LIST = "SET_COMPANY_FOLLOW_UP_LIST";
export const SET_COMPANY_FOLLOW_UP_LOAD = "SET_COMPANY_FOLLOW_UP_LOAD";
export const SET_COMPANY_FOLLOW_UP_ERROR = "SET_COMPANY_FOLLOW_UP_ERROR";
export const SET_COMPANY_FOLLOW_UP_PAGE = "SET_COMPANY_FOLLOW_UP_PAGE";
export const SET_COMPANY_FOLLOW_UP_TOTAL = "SET_COMPANY_FOLLOW_UP_TOTAL";
export const SET_COMPANY_FOLLOW_UP_KEYWORD = "SET_COMPANY_FOLLOW_UP_KEYWORD";
export const SET_COMPANY_FOLLOW_UP_CONTACT_BY =
  "SET_COMPANY_FOLLOW_UP_CONTACT_BY";
export const SET_COMPANY_FOLLOW_UP_ASSIGNED_BY =
  "SET_COMPANY_FOLLOW_UP_ASSIGNED_BY";
export const SET_COMPANY_FOLLOW_UP_ASSIGNED_TO =
  "SET_COMPANY_FOLLOW_UP_ASSIGNED_TO";
export const SET_COMPANY_FOLLOW_UP_START_DATE =
  "SET_COMPANY_FOLLOW_UP_START_DATE";
export const SET_COMPANY_FOLLOW_UP_END_DATE = "SET_COMPANY_FOLLOW_UP_END_DATE";
export const SET_COMPANY_FOLLOW_UP_FORM = "SET_COMPANY_FOLLOW_UP_FORM";
export const SET_COMPANY_FOLLOW_UP_REMOVE = "SET_COMPANY_FOLLOW_UP_REMOVE";

export const SET_COMPANY_NOTE_DIALOG = "SET_COMPANY_NOTE_DIALOG";
export const SET_COMPANY_ALL_CONTACT_LIST = "SET_COMPANY_ALL_CONTACT_LIST";

export const SET_COMPANY_WORKING_WITH_LIST = "SET_COMPANY_WORKING_WITH_LIST";
export const SET_COMPANY_WORKING_WITH_LOAD = "SET_COMPANY_WORKING_WITH_LOAD";
export const SET_COMPANY_WORKING_WITH_ERROR = "SET_COMPANY_WORKING_WITH_ERROR";
export const SET_COMPANY_WORKING_WITH_KEYWORD =
  "SET_COMPANY_WORKING_WITH_KEYWORD";

export const SET_COMPANY_DETAIL_WATCHLIST_FORM =
  "SET_COMPANY_DETAIL_WATCHLIST_FORM";
export const SET_COMPANY_PROJECT_DETAIL_WATCHLIST_FORM =
  "SET_COMPANY_PROJECT_DETAIL_WATCHLIST_FORM";

export const SET_COMPANY_OVERVIEW_COLLAPSE = "SET_COMPANY_OVERVIEW_COLLAPSE";
export const SET_PARTNER_NETWORK_COLLAPSE = "SET_PARTNER_NETWORK_COLLAPSE";
export const SET_RELATIONSHIP_MAPPING_COLLAPSE =
  "SET_RELATIONSHIP_MAPPING_COLLAPSE";

export const SET_PARTNER_NETWORK_LOAD = "SET_PARTNER_NETWORK_LOAD";
export const SET_PARTNER_NETWORK_DATA = "SET_PARTNER_NETWORK_DATA";
export const SET_PARTNER_NETWORK_ERROR = "SET_PARTNER_NETWORK_ERROR";
export const SET_ACTION_CONTACT_DIALOG = "SET_ACTION_CONTACT_DIALOG";
export const SET_ACTION_DELETE_CONTACT_DIALOG =
  "SET_ACTION_DELETE_CONTACT_DIALOG";
export const SET_FIRST_LOAD = "SET_FIRST_LOAD";