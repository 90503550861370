import initialState from "./initialState";
import {
  RESET_SEARCH_RESULT,
  SET_MAP_DATA,
  SET_MAP_POPUP_DATA,
  SET_MAP_POPUP_OPEN,
  SET_MAP_POPUP_REFRESH,
  SET_MODAL_SAVE_SEARCH,
  SET_OPEN_OUTSIDE_SUBS,
  SET_SEARCH_PAGE_FILTER_EXPAND,
  SET_SEARCH_PAGE_MODE,
  SET_SEARCH_PAGE_TOP_RESULT_EXPAND,
  SET_SEARCH_VIEW_MODE,
} from "./types";

const searchResult = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_PAGE_FILTER_EXPAND: {
      return {
        ...state,
        filterExpanded: !state.filterExpanded,
      };
    }
    case SET_SEARCH_PAGE_TOP_RESULT_EXPAND: {
      return {
        ...state,
        topResultExpanded: !state.topResultExpanded,
      };
    }
    case SET_SEARCH_PAGE_MODE: {
      return {
        ...state,
        searchMode: payload,
        openOutsideSubs: false,
      };
    }
    case SET_SEARCH_VIEW_MODE: {
      return {
        ...state,
        viewMode: payload,
      };
    }

    case SET_OPEN_OUTSIDE_SUBS: {
      return {
        ...state,
        openOutsideSubs: !state.openOutsideSubs,
      };
    }
    case SET_MAP_DATA: {
      return {
        ...state,
      };
    }
    case SET_MODAL_SAVE_SEARCH: {
      return {
        ...state,
        saveSearchModal: payload,
      };
    }
    case SET_MAP_POPUP_OPEN: {
      return {
        ...state,
        mapPopUp: {
          ...state.mapPopUp,
          open: payload,
        },
      };
    }
    case SET_MAP_POPUP_DATA: {
      return {
        ...state,
        mapPopUp: {
          ...state.mapPopUp,
          mapData: payload,
        },
      };
    }
    case SET_MAP_POPUP_REFRESH: {
      return {
        ...state,
        mapPopUp: {
          ...state.mapPopUp,
          mapPopupRefresh: payload,
        },
      };
    }

    case RESET_SEARCH_RESULT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default searchResult;
