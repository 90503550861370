import initialState from "./initialState";
import {
  RESET_KEY_ACCOUNT_LOCATION,
  SET_KEY_ACCOUNT_LOCATION_DATA,
  SET_KEY_ACCOUNT_LOCATION_LOAD,
  SET_KEY_ACCOUNT_LOCATION_LOAD_NEXT,
  SET_KEY_ACCOUNT_LOCATION_PAGE,
  SET_KEY_ACCOUNT_LOCATION_TOTAL,
  SET_AFTER_PAGE_KEY_ACCOUNT
} from "./type";

const KeyAccountLocation = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_KEY_ACCOUNT_LOCATION_DATA: {
      return {
        ...state,
        loadLocation: false,
        locations: payload,
      };
    }
    case SET_KEY_ACCOUNT_LOCATION_LOAD: {
      return {
        ...state,
        loadLocation: payload,
      };
    }
    case SET_KEY_ACCOUNT_LOCATION_LOAD_NEXT: {
      return {
        ...state,
        loadLocationNextPage: payload,
      };
    }
    case SET_KEY_ACCOUNT_LOCATION_PAGE: {
      return {
        ...state,
        locationPage: payload,
      };
    }
    case SET_KEY_ACCOUNT_LOCATION_TOTAL: {
      return {
        ...state,
        totalLocations: payload,
      };
    }
    case RESET_KEY_ACCOUNT_LOCATION: {
      return {
        ...initialState,
      };
    }
    case SET_AFTER_PAGE_KEY_ACCOUNT:{
      return {
        ...state,
        afterPage: payload,
      }
    }
    default:
      return state;
  }
};

export default KeyAccountLocation;
