import initialState from "./initialState";
import { SET_WATCHLIST_ALERT_TABS } from "./types";

const presetAlert = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_WATCHLIST_ALERT_TABS: {
      return {
        ...state,
        tabActive: payload,
      };
    }

    default:
      return state;
  }
};

export default presetAlert;
