import initialState from "./initialState";
import {
  SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA,
  SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_ERROR,
  SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_LOAD,
  SET_SUBSCRIPTION_INSIGHT_DATA,
  SET_SUBSCRIPTION_INSIGHT_DATA_ERROR,
  SET_SUBSCRIPTION_INSIGHT_DATA_LOAD,
  SET_SUBSCRIPTION_INSIGHT_FILTER,
  SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA,
  SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_ERROR,
  SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_LOAD,
  SET_SUBSCRIPTION_INSIGHT_LOCATION_FILTER,
  SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA,
  SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_ERROR,
  SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_LOAD,
  SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_FILTER,
  SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_OPTION,
  SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA,
  SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_ERROR,
  SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_LOAD,
  SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA,
  SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_ERROR,
  SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_LOAD,
} from "./types";

const subscriptionInsight = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SUBSCRIPTION_INSIGHT_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_DATA_LOAD: {
      return {
        ...state,
        subscriptionInsightProject: {
          ...initialState.subscriptionInsightProject,
          load: true,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_DATA: {
      return {
        ...state,
        subscriptionInsightProject: {
          ...initialState.subscriptionInsightProject,
          data: payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_DATA_ERROR: {
      return {
        ...state,
        subscriptionInsightProject: {
          ...initialState.subscriptionInsightProject,
          error: payload,
        },
      };
    }

    case SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_LOAD: {
      return {
        ...state,
        subscriptionInsightCategory: {
          ...initialState.subscriptionInsightCategory,
          load: true,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA: {
      return {
        ...state,
        subscriptionInsightCategory: {
          ...initialState.subscriptionInsightCategory,
          data: payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_ERROR: {
      return {
        ...state,
        subscriptionInsightCategory: {
          ...initialState.subscriptionInsightCategory,
          error: payload,
        },
      };
    }

    case SET_SUBSCRIPTION_INSIGHT_LOCATION_FILTER: {
      return {
        ...state,
        subscriptionInsightLocationFilter: {
          ...state.subscriptionInsightLocationFilter,
          ...payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_LOAD: {
      return {
        ...state,
        subscriptionInsightLocation: {
          ...initialState.subscriptionInsightLocation,
          load: true,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA: {
      return {
        ...state,
        subscriptionInsightLocation: {
          ...initialState.subscriptionInsightLocation,
          data: payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_ERROR: {
      return {
        ...state,
        subscriptionInsightLocation: {
          ...initialState.subscriptionInsightLocation,
          error: payload,
        },
      };
    }

    case SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_OPTION: {
      return {
        ...state,
        subscriptionInsightOpportunitiesFilterOpts: payload,
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_FILTER: {
      return {
        ...state,
        subscriptionInsightOpportunitiesFilter: {
          ...state.subscriptionInsightOpportunitiesFilter,
          ...payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_LOAD: {
      return {
        ...state,
        subscriptionInsightOpportunities: {
          ...initialState.subscriptionInsightOpportunities,
          load: true,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA: {
      return {
        ...state,
        subscriptionInsightOpportunities: {
          ...initialState.subscriptionInsightOpportunities,
          data: payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_ERROR: {
      return {
        ...state,
        subscriptionInsightOpportunities: {
          ...initialState.subscriptionInsightOpportunities,
          error: payload,
        },
      };
    }

    case SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_LOAD: {
      return {
        ...state,
        subscriptionInsightTotalCompanies: {
          ...initialState.subscriptionInsightTotalCompanies,
          load: true,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA: {
      return {
        ...state,
        subscriptionInsightTotalCompanies: {
          ...initialState.subscriptionInsightTotalCompanies,
          data: payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_ERROR: {
      return {
        ...state,
        subscriptionInsightTotalCompanies: {
          ...initialState.subscriptionInsightTotalCompanies,
          error: payload,
        },
      };
    }

    case SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_LOAD: {
      return {
        ...state,
        subscriptionInsightTotalContact: {
          ...initialState.subscriptionInsightTotalContact,
          load: true,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA: {
      return {
        ...state,
        subscriptionInsightTotalContact: {
          ...initialState.subscriptionInsightTotalContact,
          data: payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_ERROR: {
      return {
        ...state,
        subscriptionInsightTotalContact: {
          ...initialState.subscriptionInsightTotalContact,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default subscriptionInsight;
