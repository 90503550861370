import { applyMiddleware, createStore, compose } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./rootReducer";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.error(e, "serializedState failed save to local storage");
  }
}
function loadFromLocalStorage() {
  const serializedState = localStorage.getItem("state");
  if (serializedState === null) return undefined;
  const parsedSerializedState = JSON.parse(serializedState);
  return parsedSerializedState;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const presistedState = loadFromLocalStorage();
const store = createStore(
  rootReducer,
  presistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  // uodate location to not persist at local storage
  const projectList = JSON.parse(JSON.stringify(store.getState().projectList));
  const layout = JSON.parse(JSON.stringify(store.getState().layout));

  if (projectList.searchProjectLocation) {
    projectList.searchProjectLocation.locations = [];
    projectList.searchProjectLocation.totalLocations = 0;
  }
  if (layout.userFraudIndicationStatus) {
    layout.userFraudIndicationStatus = false;
  }

  saveToLocalStorage({
    layout: layout,
    user: store.getState().user,
    globalSearch: store.getState().globalSearch,
    listCombo: store.getState().listCombo,
    // searchProject: store.getState().searchProject,
    // searchCompany: store.getState().searchCompany,
    watchlist: store.getState().watchlist,
    searchResult: store.getState().searchResult,
    filterProject: store.getState().filterProject,
    filterCompany: store.getState().filterCompany,
    projectList: projectList,
    keyAccount: store.getState().keyAccount,
    projectPipeline: store.getState().projectPipeline,
  });
});
export default store;
