const initialState = {
  locations: [],
  additionalLocations: [],
  loadLocation: false,
  error: null,
  loadLocationNextPage: false,
  locationPage: 0,
  totalLocations: 0,
  showDataPerCall: 1000,
  afterPage:[]
};

export default initialState;
