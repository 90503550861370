export const SET_KEY_ACCOUNT_FOLDER_LOAD = "SET_KEY_ACCOUNT_FOLDER_LOAD";
export const SET_KEY_ACCOUNT_FOLDER_DATA = "SET_KEY_ACCOUNT_FOLDER_DATA";
export const SET_KEY_ACCOUNT_FOLDER_USER_ID = "SET_KEY_ACCOUNT_FOLDER_USER_ID";
export const SET_KEY_ACCOUNT_SELECTED_FOLDER =
  "SET_KEY_ACCOUNT_SELECTED_FOLDER";
export const SET_KEY_ACCOUNT_PROJECT_FILTER = "SET_KEY_ACCOUNT_PROJECT_FILTER";
export const SET_KEY_ACCOUNT_PROJECT_UTIL = "SET_KEY_ACCOUNT_PROJECT_UTIL";
export const SET_KEY_ACCOUNT_PROJECT_PAGE = "SET_KEY_ACCOUNT_PROJECT_PAGE";
export const SET_KEY_ACCOUNT_DATA = "SET_KEY_ACCOUNT_DATA";
export const SET_KEY_ACCOUNT_DATA_LOAD = "SET_KEY_ACCOUNT_DATA_LOAD";
export const SET_KEY_ACCOUNT_DATA_ERROR = "SET_KEY_ACCOUNT_DATA_ERROR";
export const SET_KEY_ACCOUNT_PROJECT_COMPANIES =
  "SET_KEY_ACCOUNT_PROJECT_COMPANIES";
export const SET_KEY_ACCOUNT_ARCHIVE = "SET_KEY_ACCOUNT_ARCHIVE";
export const SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE =
  "SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE";
export const SET_KEY_ACCOUNT_PROJECT_LIST_VIEW =
  "SET_KEY_ACCOUNT_PROJECT_LIST_VIEW";
export const SET_KEY_ACCOUNT_PROJECT_FOLDER_TYPE =
  "SET_KEY_ACCOUNT_PROJECT_FOLDER_TYPE";

export const SET_KEY_ACCOUNT_PROJECT_MAP_DATA =
  "SET_KEY_ACCOUNT_PROJECT_MAP_DATA";
export const SET_KEY_ACCOUNT_PROJECT_MAP_LOAD =
  "SET_KEY_ACCOUNT_PROJECT_MAP_LOAD";
export const SET_KEY_ACCOUNT_PROJECT_MAP_PAGE =
  "SET_KEY_ACCOUNT_PROJECT_MAP_PAGE";
export const SET_KEY_ACCOUNT_PROJECT_MAP_TOTAL =
  "SET_KEY_ACCOUNT_PROJECT_MAP_TOTAL";

export const SET_COMPANY_SUGGESTIONS_DATA = "SET_COMPANY_SUGGESTIONS_DATA";

export const RESET_KEY_ACCOUNT = "RESET_KEY_ACCOUNT";
export const RESET_HISTORY_KEY_ACCOUNT = "RESET_HISTORY_KEY_ACCOUNT";
export const RESET_KEY_ACCOUNT_PROJECT_FILTER =
  "RESET_KEY_ACCOUNT_PROJECT_FILTER";
