const style = {
  // Colors
  "red-bci": "#FF0000",
  "red-light": "#FFE8E8",
  "red-light-soft": "#FFF0F1",

  "grey-lighter": "#F9FAFC",
  "grey-light": "#F7F7F7",
  "grey-regular": "#E0E0E0",
  "grey-cool-bci": "#808080",
  "grey-text": "#6A6A73",
  "grey-dark": "#6a6a73",
  "grey-table-stripped": "#F7F8FA",
  "grey-table-border": "#CED3D8",
  green: "#0DA738 ",

  black: "#454550",

  blue: "#454550",
  "blue-light": "#ECF7FF",

  tortoise: "#03989E",
  "tortoise-light": "#D8F4F5",

  white: "#FFF",

  // New Colors
  "trolley-gray": "#808080",
  "gray-94": "#F0F0F0",
  "new-gray": "#CAC8C8",
  magenta: "#FF7676",
  "bci-blue": "#0039FF",

  // Fonts
  "font-regular": "'Roboto', sans-serif",
  "font-prox-semibold": "'Proxima Nova Semibold', sans-serif",
  "font-prox-bold": "'Proxima Nova Bold', sans-serif",
  "font-prox-light": "'Proxima Nova Light', sans-serif",
  "font-prox-reg": "'Proxima Nova Regular', sans-serif",

  // width
  "width-sidebar": "60px",

  // box-shadow-regular
  "box-shadow-regular": "2px 2px 10px rgba(0,0,0,0.05) !important",

  // border-radius-regular
  "border-radius-regular": "8px",
  // padding-card-regular
  "padding-card-regular": "16px",

  // Map pin point balloon color for project stage
  "map-point-stage-2": "#EC2934", // Concept
  "map-point-stage-4": "#FD7F2C", // Design & Implementation
  "map-point-stage-6": "#FFC42C", // Pre-construction
  "map-point-stage-8": "#0DA738", // Construction
  "map-point-stage-11": "#3D3DB4", // Operational
  "map-point-stage-99": "#6A6A73", // Completed, Deferred, Abandoned
};

export default style;
