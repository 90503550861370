import initialState from "./initialState";
import {
  RESET_MARKET_INSIGHT,
  SET_MARKET_INSIGHT_FORECASTER_DATA,
  SET_MARKET_INSIGHT_FORECASTER_DATA_ERROR,
  SET_MARKET_INSIGHT_FORECASTER_DATA_LOAD,
  SET_MARKET_INSIGHT_FORECASTER_FILTER,
  SET_MARKET_INSIGHT_FORECASTER_YEARS,
  SET_MARKET_INSIGHT_TAB,
} from "./types";

const marketInsight = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MARKET_INSIGHT_TAB: {
      return {
        ...state,
        tabActive: payload,
      };
    }

    case SET_MARKET_INSIGHT_FORECASTER_FILTER: {
      return {
        ...state,
        forecaster: {
          ...state.forecaster,
          filter: {
            ...state.forecaster.filter,
            ...payload,
          },
          // reports: initialState.forecaster.reports,
        },
      };
    }
    case SET_MARKET_INSIGHT_FORECASTER_YEARS: {
      return {
        ...state,
        forecaster: {
          ...state.forecaster,
          dataYears: payload,
        },
      };
    }
    case SET_MARKET_INSIGHT_FORECASTER_DATA_LOAD: {
      return {
        ...state,
        forecaster: {
          ...state.forecaster,
          reports: {
            ...initialState.forecaster.reports,
            load: true,
          },
        },
      };
    }
    case SET_MARKET_INSIGHT_FORECASTER_DATA: {
      return {
        ...state,
        forecaster: {
          ...state.forecaster,
          reports: {
            ...initialState.forecaster.reports,
            data: payload,
          },
        },
      };
    }
    case SET_MARKET_INSIGHT_FORECASTER_DATA_ERROR: {
      return {
        ...state,
        forecaster: {
          ...state.forecaster,
          reports: {
            ...initialState.forecaster.reports,
            error: payload,
          },
        },
      };
    }

    case RESET_MARKET_INSIGHT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default marketInsight;
