const initialState = {
  PREV_VIEW: null,
  CURRENT_VIEW: null,
  globalLoaderState: false,
  language: 1,
  moreApp: false,
  printPreview: "",
  forceLoggedOutInactive: false,
  forceLoggedOutTokenExpire: false,

  globalApiError: {
    errorStatus: null,
    errorMessage: null,
  },
  globalApiErrorTimeout: {
    error: false,
    apiReloadList: [],
    retryCount: 0,
  },
  subscriptionModal: false,
  // show captcha or now
  userFraudIndicationStatus: false,
  // show dialog prepare excel
  prepareExcelDownloadModal: { open: false, type: "" },
};

export default initialState;
