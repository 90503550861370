import initialState from "./initialState";
import {
  SET_PROJECT_DETAIL,
  SET_PROJECT_DETAIL_LOAD,
  SET_PROJECT_DETAIL_ERROR,
  SET_PROJECT_DETAIL_CONTACT,
  SET_PROJECT_DETAIL_CONTACT_LOAD,
  SET_PROJECT_DETAIL_CONTACT_ERROR,
  SET_PROJECT_DETAIL_DOCUMENT,
  SET_PROJECT_DETAIL_DOCUMENT_LOAD,
  SET_PROJECT_DETAIL_DOCUMENT_ERROR,
  SET_PROJECT_DETAIL_NOTE,
  SET_PROJECT_DETAIL_NOTE_LOAD,
  SET_PROJECT_DETAIL_NOTE_ERROR,
  SET_PROJECT_DETAIL_NOTE_PAGE,
  SET_PROJECT_DETAIL_NOTE_DETAIL_OPEN,
  SET_PROJECT_DETAIL_NOTE_DETAIL_CLOSE,
  SET_PROJECT_DETAIL_TASK,
  SET_PROJECT_DETAIL_TASK_LOAD,
  SET_PROJECT_DETAIL_TASK_ERROR,
  SET_PROJECT_DETAIL_TASK_PAGE,
  SET_PROJECT_DETAIL_TASK_DETAIL_OPEN,
  SET_PROJECT_DETAIL_TASK_DETAIL_CLOSE,
  SET_PROJECT_DETAIL_TASK_DELETE,
  SET_PROJECT_DETAIL_FORM_FOLLOW_UP_TASK,
  SET_PROJECT_DETAIL_FORM_NOTES,
  SET_PROJECT_DETAIL_WATCHLIST_FORM,
  RESET_PROJECT_DETAIL,
  SET_PROJECT_NAV_ACTIVE,
  SET_PROJECT_LAST_VERSION,
  SET_KEYWORD_CONTACT,
  SET_PROJECT_ACTION_ROLE_DIALOG,
  SET_PROJECT_ACTION_LINKED_COMPANY,
  SET_UNLINK_CONTACT_DIALOG,
} from "./type";

const detailProject = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROJECT_DETAIL: {
      return {
        ...state,
        projectDetail: payload,
        projectDetailLoad: false,
        projectDetailError: false,
      };
    }
    case SET_PROJECT_DETAIL_LOAD: {
      return {
        ...initialState,
        projectDetailLoad: true,
        projectLastVersion: state.projectLastVersion,
      };
    }
    case SET_PROJECT_DETAIL_ERROR: {
      return {
        ...state,
        projectDetail: null,
        projectDetailLoad: false,
        projectDetailError: true,
      };
    }
    case SET_PROJECT_DETAIL_CONTACT: {
      return {
        ...state,
        projectDetailContact: payload,
        projectDetailContactLoad: false,
        projectDetailContactError: false,
      };
    }

    case SET_KEYWORD_CONTACT: {
      return {
        ...state,
        keyword: payload,
      };
    }
    case SET_PROJECT_DETAIL_CONTACT_LOAD: {
      return {
        ...state,
        projectDetailContact: [],
        projectDetailContactLoad: true,
        projectDetailContactError: false,
      };
    }
    case SET_PROJECT_DETAIL_CONTACT_ERROR: {
      return {
        ...state,
        projectDetailContact: [],
        projectDetailContactLoad: false,
        projectDetailContactError: true,
      };
    }
    case SET_PROJECT_DETAIL_DOCUMENT: {
      return {
        ...state,
        projectDetailDocument: payload,
        projectDetailDocumentLoad: false,
        projectDetailDocumentError: false,
      };
    }
    case SET_PROJECT_DETAIL_DOCUMENT_LOAD: {
      return {
        ...state,
        projectDetailDocument: [],
        projectDetailDocumentLoad: true,
        projectDetailDocumentError: false,
      };
    }
    case SET_PROJECT_DETAIL_DOCUMENT_ERROR: {
      return {
        ...state,
        projectDetailDocument: [],
        projectDetailDocumentLoad: false,
        projectDetailDocumentError: true,
      };
    }
    case SET_PROJECT_DETAIL_TASK: {
      return {
        ...state,
        projectFollowUpTask: {
          ...state.projectFollowUpTask,
          load: false,
          error: false,
          data: payload.data,
          total: payload.total,
        },
      };
    }
    case SET_PROJECT_DETAIL_TASK_LOAD: {
      return {
        ...state,
        projectFollowUpTask: {
          ...state.projectFollowUpTask,
          load: true,
        },
      };
    }
    case SET_PROJECT_DETAIL_TASK_ERROR: {
      return {
        ...state,
        projectFollowUpTask: {
          ...initialState.projectFollowUpTask,
          error: true,
        },
      };
    }
    case SET_PROJECT_DETAIL_TASK_PAGE: {
      return {
        ...state,
        projectFollowUpTask: {
          ...state.projectFollowUpTask,
          page: payload,
        },
      };
    }
    case SET_PROJECT_DETAIL_TASK_DETAIL_OPEN: {
      return {
        ...state,
        projectFollowUpTask: {
          ...state.projectFollowUpTask,
          detail: {
            data: payload.data,
            source: payload.source || "",
          },
        },
      };
    }
    case SET_PROJECT_DETAIL_TASK_DETAIL_CLOSE: {
      return {
        ...state,
        projectFollowUpTask: {
          ...state.projectFollowUpTask,
          detail: initialState.projectFollowUpTask.detail,
        },
      };
    }
    case SET_PROJECT_DETAIL_FORM_FOLLOW_UP_TASK: {
      return {
        ...state,
        projectFollowUpTask: {
          ...state.projectFollowUpTask,
          formFollowUpTaskData: payload,
        },
      };
    }
    case SET_PROJECT_DETAIL_TASK_DELETE: {
      return {
        ...state,
        projectFollowUpTask: {
          ...state.projectFollowUpTask,
          deleteFollowUpTaskId: payload || 0,
        },
      };
    }
    case SET_PROJECT_DETAIL_NOTE: {
      return {
        ...state,
        projectNotes: {
          ...state.projectNotes,
          load: false,
          error: false,
          data: payload.data,
          total: payload.total,
        },
      };
    }
    case SET_PROJECT_DETAIL_NOTE_LOAD: {
      return {
        ...state,
        projectNotes: {
          ...state.projectNotes,
          load: true,
        },
      };
    }
    case SET_PROJECT_DETAIL_NOTE_ERROR: {
      return {
        ...state,
        projectNotes: {
          ...initialState.projectNotes,
          error: true,
        },
      };
    }
    case SET_PROJECT_DETAIL_NOTE_PAGE: {
      return {
        ...state,
        projectNotes: {
          ...state.projectNotes,
          page: payload,
        },
      };
    }
    case SET_PROJECT_DETAIL_NOTE_DETAIL_OPEN: {
      return {
        ...state,
        projectNotes: {
          ...state.projectNotes,
          detail: payload,
        },
      };
    }
    case SET_PROJECT_DETAIL_NOTE_DETAIL_CLOSE: {
      return {
        ...state,
        projectNotes: {
          ...state.projectNotes,
          detail: null,
        },
      };
    }
    case SET_PROJECT_DETAIL_FORM_NOTES: {
      return {
        ...state,
        projectNotes: {
          ...state.projectNotes,
          formNotesData: payload,
        },
      };
    }
    case SET_PROJECT_DETAIL_WATCHLIST_FORM: {
      return {
        ...state,
        projectWatchlistForm: payload,
      };
    }
    case SET_PROJECT_NAV_ACTIVE: {
      return {
        ...state,
        projectNavActive: payload,
      };
    }
    case RESET_PROJECT_DETAIL: {
      return initialState;
    }
    case SET_PROJECT_LAST_VERSION: {
      return {
        ...state,
        projectLastVersion: payload,
      };
    }
    case SET_PROJECT_ACTION_ROLE_DIALOG: {
      return {
        ...state,
        projectActionRoleDialog: {
          ...initialState.projectActionRoleDialog,
          ...payload,
        },
      };
    }
    case SET_PROJECT_ACTION_LINKED_COMPANY: {
      return {
        ...state,
        projectActionLinkedCompany: {
          ...initialState.projectActionLinkedCompany,
          ...payload,
        },
      };
    }
    case SET_UNLINK_CONTACT_DIALOG: {
      return {
        ...state,
        unlinkContactDialog: {
          ...initialState.unlinkContactDialog,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default detailProject;
