import initialState from "./initialState";
import {
  SET_SEARCH_COMPANY_PAGE,
  SET_SEARCH_COMPANY_LIMIT,
  SET_SEARCH_COMPANY_SORT,
  SET_SEARCH_COMPANY_DATA,
  SET_SEARCH_COMPANY_DATA_LOAD,
  RESET_SEARCH_COMPANY,
  SET_SEARCH_COMPANY_DETAIL,
  SET_SEARCH_COMPANY_SELECTED,
  SET_SEARCH_COMPANY_DETAIL_LOAD,
  SET_SEARCH_COMPANY_DETAIL_DATA,
  SET_SEARCH_COMPANY_ERROR,
  SET_SEARCH_COMPANY_DETAIL_ERROR,
  SET_SEARCH_COMPANY_TOTAL,
  SET_SEARCH_COMPANY_WATCHLIST_FORM,
  SET_SEARCH_COMPANY_LOCATION_DATA,
  SET_SEARCH_COMPANY_DATA_MAP_MODE,
  SET_SEARCH_COMPANY_DATA_LOAD_MAP_MODE,
  SET_SEARCH_COMPANY_LOCATION_DATA_LOAD,
  SET_SEARCH_COMPANY_PROJECT_VALUE_DATA,
  SET_SEARCH_COMPANY_PROJECT_VALUE_LOAD,
  SET_SEARCH_COMPANY_PROJECT_VALUE_ERROR,
  RESET_SEARCH_COMPANY_PROJECT_VALUE,
} from "./type";

const searchCompany = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_COMPANY_PAGE: {
      return {
        ...state,
        companyPage: payload,
        // companyData: [],
      };
    }
    case SET_SEARCH_COMPANY_LIMIT: {
      return {
        ...state,
        companyLimit: payload,
        companyPage: 1,
        companyData: [],
      };
    }
    case SET_SEARCH_COMPANY_SORT: {
      return {
        ...state,
        companySortBy: payload,
        companyPage: 1,
        companyData: [],
      };
    }
    case SET_SEARCH_COMPANY_DATA: {
      return {
        ...state,
        companyDataError: false,
        companyData: payload,
        companyLoad: false,
        companyDetail: null,
        companyDetailData: null,
        companySelected: [],
      };
    }
    case SET_SEARCH_COMPANY_DATA_LOAD: {
      return {
        ...state,
        companyDataError: false,
        companyLoad: true,
        companyData: [],
        companyDetail: null,
        companyDetailData: null,
        companySelected: [],
        companyWatchlistForm: null,
      };
    }
    case SET_SEARCH_COMPANY_DATA_MAP_MODE: {
      return {
        ...state,
        companyDataError: false,
        companyData: payload,
        companyLoad: false,
      };
    }
    case SET_SEARCH_COMPANY_DATA_LOAD_MAP_MODE: {
      return {
        ...state,
        companyDataError: false,
        companyLoad: true,
        companyData: [],
        companyWatchlistForm: null,
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_DATA: {
      return {
        ...state,
        companyLocationData: payload,
        companyLocationLoad: false,
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_DATA_LOAD: {
      return {
        ...state,
        companyLocationLoad: true,
        companyLocationData: [],
      };
    }
    case SET_SEARCH_COMPANY_DETAIL_LOAD: {
      return {
        ...state,
        companyDetailError: false,
        companyDetailLoad: true,
      };
    }
    case SET_SEARCH_COMPANY_DETAIL: {
      return {
        ...state,
        companyDetail: payload,
        companyDetailData: null,
        projectDetail: null,
      };
    }
    case SET_SEARCH_COMPANY_DETAIL_DATA: {
      return {
        ...state,
        companyDetailData: payload,
        companyDetailLoad: false,
      };
    }
    case SET_SEARCH_COMPANY_SELECTED: {
      return {
        ...state,
        companySelected: payload,
        companyDetail: null,
      };
    }
    case RESET_SEARCH_COMPANY: {
      return {
        ...initialState,
      };
    }
    case SET_SEARCH_COMPANY_ERROR: {
      return {
        ...state,
        companyDataError: true,
        companyLoad: false,
        companyData: [],
        companyDetail: null,
        companySelected: [],
        companyTotal: 0,
      };
    }
    case SET_SEARCH_COMPANY_DETAIL_ERROR: {
      return {
        ...state,
        companyDetailError: true,
        companyDetailLoad: false,
        companyDetailData: null,
      };
    }
    case SET_SEARCH_COMPANY_TOTAL: {
      return {
        ...state,
        companyTotal: payload,
      };
    }
    case SET_SEARCH_COMPANY_WATCHLIST_FORM: {
      return {
        ...state,
        companyWatchlistForm: payload,
      };
    }

    case SET_SEARCH_COMPANY_PROJECT_VALUE_DATA: {
      return {
        ...state,
        companiesProjectValue: {
          ...initialState.companiesProjectValue,
          data: [...state.companiesProjectValue.data, ...payload],
        },
      };
    }
    case SET_SEARCH_COMPANY_PROJECT_VALUE_LOAD: {
      return {
        ...state,
        companiesProjectValue: {
          ...initialState.companiesProjectValue,
          data: state.companiesProjectValue.data,
          load: true,
        },
      };
    }
    case SET_SEARCH_COMPANY_PROJECT_VALUE_ERROR: {
      return {
        ...state,
        companiesProjectValue: {
          ...initialState.companiesProjectValue,
          data: state.companiesProjectValue.data,
          error: true,
        },
      };
    }
    case RESET_SEARCH_COMPANY_PROJECT_VALUE: {
      return {
        ...state,
        companiesProjectValue: {
          ...initialState.companiesProjectValue,
        },
      };
    }
    default:
      return state;
  }
};

export default searchCompany;
