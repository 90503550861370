import initialState from "./initialState";
import {
  SET_COMPANY_INFORMATION,
  SET_COMPANY_INFORMATION_LOAD,
  SET_COMPANY_INFORMATION_ERROR,
  SET_COMPANY_PROJECT_LIST,
  SET_COMPANY_PROJECT_LOAD,
  SET_COMPANY_PROJECT_ERROR,
  SET_COMPANY_PROJECT_CONTACT_EXACT,
  SET_COMPANY_PROJECT_CONTACT_KEYWORD,
  SET_COMPANY_PROJECT_CONTACT_LIMIT,
  SET_COMPANY_NAVIGATION,
  SET_COMPANY_PROJECT_PAGE,
  SET_COMPANY_PROJECT_TOTAL,
  SET_COMPANY_PROJECT_TOTAL_OTHER,
  SET_COMPANY_CONTACT_LIST,
  SET_COMPANY_CONTACT_LOAD,
  SET_COMPANY_CONTACT_ERROR,
  SET_COMPANY_CONTACT_TOTAL,
  SET_COMPANY_CONTACT_PAGE,
  SET_COMPANY_OTHER_INFORMATION,
  SET_COMPANY_OTHER_INFORMATION_LOAD,
  SET_COMPANY_OTHER_INFORMATION_ERROR,
  RESET_COMPANY_DETAIL,
  SET_COMPANY_PROJECT_CONTACT_FILTER,
  RESET_COMPANY_PROJECT_CONTACT_FILTER,
  SET_COMPANY_CONTACT_SORT,
  SET_COMPANY_PROJECT_SORT,
  SET_COMPANY_NOTE_LIST,
  SET_COMPANY_NOTE_LOAD,
  SET_COMPANY_NOTE_ERROR,
  SET_COMPANY_NOTE_PAGE,
  SET_COMPANY_NOTE_TOTAL,
  SET_COMPANY_NOTE_KEYWORD,
  SET_COMPANY_FOLLOW_UP_LOAD,
  SET_COMPANY_FOLLOW_UP_ERROR,
  SET_COMPANY_FOLLOW_UP_PAGE,
  SET_COMPANY_FOLLOW_UP_TOTAL,
  SET_COMPANY_FOLLOW_UP_KEYWORD,
  SET_COMPANY_FOLLOW_UP_LIST,
  SET_COMPANY_NOTE_DIALOG,
  SET_COMPANY_ALL_CONTACT_LIST,
  SET_COMPANY_FOLLOW_UP_FORM,
  SET_COMPANY_FOLLOW_UP_CONTACT_BY,
  SET_COMPANY_FOLLOW_UP_ASSIGNED_BY,
  SET_COMPANY_FOLLOW_UP_ASSIGNED_TO,
  SET_COMPANY_FOLLOW_UP_START_DATE,
  SET_COMPANY_FOLLOW_UP_END_DATE,
  SET_COMPANY_FOLLOW_UP_REMOVE,
  SET_COMPANY_TEAM_WATCHLIST_LOAD,
  SET_COMPANY_TEAM_WATCHLIST_ERROR,
  SET_COMPANY_TEAM_WATCHLIST,
  SET_COMPANY_WORKING_WITH_LIST,
  SET_COMPANY_WORKING_WITH_LOAD,
  SET_COMPANY_WORKING_WITH_ERROR,
  SET_COMPANY_WORKING_WITH_KEYWORD,
  SET_COMPANY_PROJECT_LAST_UPDATE,
  SET_COMPANY_DETAIL_WATCHLIST_FORM,
  SET_COMPANY_OVERVIEW_COLLAPSE,
  SET_RELATIONSHIP_MAPPING_COLLAPSE,
  SET_PARTNER_NETWORK_COLLAPSE,
  SET_PARTNER_NETWORK_LOAD,
  SET_PARTNER_NETWORK_DATA,
  SET_PARTNER_NETWORK_ERROR,
  SET_COMPANY_PROJECT_DETAIL_WATCHLIST_FORM,
  SET_COMPANY_PROJECT_TOTAL_OTHER_UEC,
  SET_ACTION_DELETE_CONTACT_DIALOG,
  SET_ACTION_CONTACT_DIALOG,
  SET_FIRST_LOAD,
} from "./type";

const detailCompany = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_INFORMATION: {
      return {
        ...state,
        companyInformation: payload,
        companyInformationLoad: false,
        companyInformationError: false,
      };
    }
    case SET_COMPANY_INFORMATION_LOAD: {
      return {
        ...state,
        companyInformationLoad: true,
        companyInformationError: false,
      };
    }
    case SET_COMPANY_INFORMATION_ERROR: {
      return {
        ...state,
        companyInformation: {},
        companyInformationLoad: false,
        companyInformationError: true,
      };
    }
    case SET_COMPANY_OTHER_INFORMATION: {
      return {
        ...state,
        companyOtherInformation: payload,
        companyOtherInformationLoad: false,
        companyOtherInformationError: false,
      };
    }
    case SET_COMPANY_OTHER_INFORMATION_LOAD: {
      return {
        ...state,
        companyOtherInformationLoad: true,
        companyOtherInformationError: false,
      };
    }
    case SET_COMPANY_OTHER_INFORMATION_ERROR: {
      return {
        ...state,
        companyOtherInformation: {},
        companyOtherInformationLoad: false,
        companyOtherInformationError: true,
      };
    }
    case SET_COMPANY_PROJECT_CONTACT_EXACT: {
      return {
        ...state,
        companyProjectContactExact: payload,
      };
    }
    case SET_COMPANY_PROJECT_CONTACT_KEYWORD: {
      return {
        ...state,
        companyProjectContactKeyword: payload,
      };
    }
    case SET_COMPANY_PROJECT_CONTACT_LIMIT: {
      return {
        ...state,
        companyProjectContactLimit: payload,
      };
    }
    case SET_COMPANY_PROJECT_CONTACT_FILTER: {
      return {
        ...state,
        companyProjectContactFilter: {
          ...initialState.companyProjectContactFilter,
          ...payload,
        },
      };
    }
    case SET_COMPANY_NAVIGATION: {
      return {
        ...state,
        companyNavigation: payload,
      };
    }
    case SET_COMPANY_PROJECT_LIST: {
      return {
        ...state,
        firstLoad: false,
        companyProjectList: payload,
        companyProjectLoad: false,
        companyProjectError: false,
      };
    }
    case SET_FIRST_LOAD:{
      return {
        ...state,
        firstLoad: payload,
        companyInformation: {},
      };
    }
    case SET_COMPANY_PROJECT_LOAD: {
      return {
        ...state,
        companyProjectLoad: true,
        companyProjectError: false,
        companyProjectTotalOther: 0,
      };
    }
    case SET_COMPANY_PROJECT_ERROR: {
      return {
        ...state,
        companyProjectList: [],
        companyProjectLoad: false,
        companyProjectError: true,
      };
    }
    case SET_COMPANY_PROJECT_PAGE: {
      return {
        ...state,
        companyProjectPage: payload,
      };
    }
    case SET_COMPANY_PROJECT_TOTAL: {
      return {
        ...state,
        companyProjectTotal: payload,
      };
    }
    case SET_COMPANY_PROJECT_TOTAL_OTHER: {
      return {
        ...state,
        companyProjectTotalOther: payload,
      };
    }
    case SET_COMPANY_PROJECT_TOTAL_OTHER_UEC: {
      return {
        ...state,
        companyProjectTotalOtherUec: payload,
      };
    }
    case SET_COMPANY_PROJECT_LAST_UPDATE: {
      return {
        ...state,
        companyProjectLastUpdate: payload,
      };
    }
    case SET_COMPANY_CONTACT_LIST: {
      return {
        ...state,
        companyContactList: payload,
        companyContactLoad: false,
        companyContactError: false,
      };
    }
    case SET_COMPANY_CONTACT_LOAD: {
      return {
        ...state,
        companyContactLoad: true,
        companyContactError: false,
      };
    }
    case SET_COMPANY_CONTACT_ERROR: {
      return {
        ...state,
        companyContactList: [],
        companyContactLoad: false,
        companyContactError: true,
      };
    }
    case SET_COMPANY_CONTACT_PAGE: {
      return {
        ...state,
        companyContactPage: payload,
      };
    }
    case SET_COMPANY_CONTACT_TOTAL: {
      return {
        ...state,
        companyContactTotal: payload,
      };
    }
    case RESET_COMPANY_DETAIL: {
      return initialState;
    }
    case RESET_COMPANY_PROJECT_CONTACT_FILTER: {
      return {
        ...state,
        companyProjectContactFilter: initialState.companyProjectContactFilter,
      };
    }
    case SET_COMPANY_PROJECT_SORT: {
      return {
        ...state,
        companyProjectSort: payload,
      };
    }
    case SET_COMPANY_CONTACT_SORT: {
      return {
        ...state,
        companyContactSort: payload,
      };
    }
    case SET_COMPANY_NOTE_LIST: {
      return {
        ...state,
        companyNoteList: payload,
        companyNoteLoad: false,
        companyNoteError: false,
      };
    }
    case SET_COMPANY_NOTE_LOAD: {
      return {
        ...state,
        companyNoteLoad: true,
        companyNoteError: false,
      };
    }
    case SET_COMPANY_NOTE_ERROR: {
      return {
        ...state,
        companyNoteList: [],
        companyNoteLoad: false,
        companyNoteError: true,
      };
    }
    case SET_COMPANY_NOTE_PAGE: {
      return {
        ...state,
        companyNotePage: payload,
      };
    }
    case SET_COMPANY_NOTE_TOTAL: {
      return {
        ...state,
        companyNoteTotal: payload,
      };
    }
    case SET_COMPANY_NOTE_KEYWORD: {
      return {
        ...state,
        companyNoteKeyword: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_LIST: {
      return {
        ...state,
        companyFollowupList: payload,
        companyFollowupLoad: false,
        companyFollowupError: false,
      };
    }
    case SET_COMPANY_FOLLOW_UP_LOAD: {
      return {
        ...state,
        companyFollowupLoad: true,
        companyFollowupError: false,
      };
    }
    case SET_COMPANY_FOLLOW_UP_ERROR: {
      return {
        ...state,
        companyFollowupList: [],
        companyFollowupLoad: false,
        companyFollowupError: true,
      };
    }
    case SET_COMPANY_FOLLOW_UP_PAGE: {
      return {
        ...state,
        companyFollowupPage: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_TOTAL: {
      return {
        ...state,
        companyFollowupTotal: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_KEYWORD: {
      return {
        ...state,
        companyFollowupKeyword: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_FORM: {
      return {
        ...state,
        companyFollowupForm: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_CONTACT_BY: {
      return {
        ...state,
        companyFollowupContactBy: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_ASSIGNED_BY: {
      return {
        ...state,
        companyFollowupAssignedBy: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_ASSIGNED_TO: {
      return {
        ...state,
        companyFollowupAssignedTo: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_START_DATE: {
      return {
        ...state,
        companyFollowupStartDate: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_END_DATE: {
      return {
        ...state,
        companyFollowupEndDate: payload,
      };
    }
    case SET_COMPANY_FOLLOW_UP_REMOVE: {
      return {
        ...state,
        companyFollowupRemove: payload,
      };
    }
    case SET_COMPANY_NOTE_DIALOG: {
      return {
        ...state,
        companyNoteDialog: payload,
      };
    }
    case SET_COMPANY_ALL_CONTACT_LIST: {
      return {
        ...state,
        companyAllContactList: payload,
      };
    }
    case SET_COMPANY_TEAM_WATCHLIST: {
      return {
        ...state,
        companyTeamWatchlist: payload,
        companyTeamWatchlistLoad: false,
        companyTeamWatchlistError: false,
      };
    }
    case SET_COMPANY_TEAM_WATCHLIST_LOAD: {
      return {
        ...state,
        companyTeamWatchlistLoad: true,
        companyTeamWatchlistError: false,
      };
    }
    case SET_COMPANY_TEAM_WATCHLIST_ERROR: {
      return {
        ...state,
        companyTeamWatchlist: [],
        companyTeamWatchlistLoad: false,
        companyTeamWatchlistError: true,
      };
    }
    case SET_COMPANY_WORKING_WITH_LIST: {
      return {
        ...state,
        companyWorkingWithList: payload,
        companyWorkingWithLoad: false,
        companyWorkingWithError: false,
      };
    }
    case SET_COMPANY_WORKING_WITH_LOAD: {
      return {
        ...state,
        companyWorkingWithLoad: true,
      };
    }
    case SET_COMPANY_WORKING_WITH_ERROR: {
      return {
        ...state,
        companyWorkingWithList: [],
        companyWorkingWithLoad: false,
        companyWorkingWithError: true,
      };
    }
    case SET_COMPANY_WORKING_WITH_KEYWORD: {
      return {
        ...state,
        companyWorkingWithKeyword: payload,
      };
    }
    case SET_COMPANY_DETAIL_WATCHLIST_FORM: {
      return {
        ...state,
        companyWatchlistForm: payload,
      };
    }
    case SET_COMPANY_PROJECT_DETAIL_WATCHLIST_FORM: {
      return {
        ...state,
        companyProjectWatchlistForm: payload,
      };
    }
    case SET_COMPANY_OVERVIEW_COLLAPSE: {
      return {
        ...state,
        companyOverviewCollapse: payload,
      };
    }
    case SET_PARTNER_NETWORK_COLLAPSE: {
      return {
        ...state,
        partnerNetworkCollapse: payload,
      };
    }
    case SET_RELATIONSHIP_MAPPING_COLLAPSE: {
      return {
        ...state,
        relationshipMappingCollapse: payload,
      };
    }
    case SET_PARTNER_NETWORK_LOAD: {
      return {
        ...state,
        partnerNetwork: {
          ...initialState.partnerNetwork,
          load: true,
        },
      };
    }
    case SET_PARTNER_NETWORK_DATA: {
      return {
        ...state,
        partnerNetwork: {
          ...initialState.partnerNetwork,
          data: payload,
        },
      };
    }
    case SET_PARTNER_NETWORK_ERROR: {
      return {
        ...state,
        partnerNetwork: {
          ...initialState.partnerNetwork,
          error: true,
        },
      };
    }
    case SET_ACTION_CONTACT_DIALOG: {
      return {
        ...state,
        actionContactDialog: {
          ...initialState.partnerNetwork,
          ...payload,
        },
      };
    }
    case SET_ACTION_DELETE_CONTACT_DIALOG: {
      return {
        ...state,
        actionDeleteContactDialog: {
          ...initialState.actionDeleteContactDialog,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default detailCompany;
