const ssoConfig = {
  region: process.env.REACT_APP_SSO_REGION,
  userPool: process.env.REACT_APP_SSO_USER_POOL,
  userPoolBaseUri: process.env.REACT_APP_SSO_USER_POOL_BASE_URI,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  clientSecret: "",
  callbackUri: process.env.REACT_APP_SSO_CALLBACK_URI,
  signoutUri: process.env.REACT_APP_SSO_SIGN_OUT_URI,
  tnlmAppName: process.env.REACT_APP_SSO_TNLM_APP_NAME,
  tnlmBaseUri: process.env.REACT_APP_SSO_TNLM_BASE_URI,

  tokenScopes: ["openid", "email", "profile"],
};

export default ssoConfig;
