const initialState = {
  tabActive: "", // "forecaster" || "performance" || "won-lost" || "competitor"

  // Sales Forecast
  salesForecastFilter: {
    lastUpdate: "30",
    startDate: null,
    endDate: null,
    dateType: 1, // 1: Expected Order Date; 2: Construction Start Date

    folderIds: [],
    groupId: 0,
    teamMember: [],
    opportunityValueMax: 0,
    opportunityValueMin: 0,
    probability: [],
    productSpecified: [],
    quoted: [],
  },
  salesForecastDurationFilter: {
    forecastType: 1,
    durationType: 1,
  },
  salesForecastDuration: {
    keys: [],
    data: [],
    load: false,
    error: null,
  },
  salesForecastCategory: {
    data: [],
    load: false,
    error: null,
  },
  salesForecastCategoryFilter: {
    categoryType: 1,
    filter: [],
    locationCategory: null,
  },

  // salesPerformance
  salesPerformanceFilter: {
    lastUpdate: "7",
    startDate: null,
    endDate: null,
    groupId: "",
  },
  salesPerformanceSummaries: {
    data: null,
    load: false,
    error: null,
  },
  salesPerformanceStatisticFilter: {
    keywords: "",
  },
  salesPerformanceStatisticPagination: {
    limit: 25,
    sortBy: "member_name",
    order: "asc",
    offset: 1,
  },
  salesPerformanceStatistic: {
    data: [],
    total: 0,
    load: false,
    error: null,
  },

  // Won/Lost
  wonLost: {
    filter: {
      lastUpdate: "7",
      displayType: 1,
    },
    wonLostBreakdown: [],
    productSpecifiedList: [],
    associatedCompanies: [],
  },
  competitor: {
    filter: {
      lastUpdate: "7",
      productSpecified: 0,
    },
  },
};

export default initialState;
