const initialState = {
  userData: {
    accountManager: "",
    accountManagerEmail: "",
    email: "",
    firstName: "",
    groupId: "",
    id: "",
    lastLogin: "",
    lastName: "",
    origin: "",
    title: "",
    username: "",
    state: "",
    archiveDate: "",
  },
  subscriptionInfo: null,

  userNotification: null,
  lastGetNotification: null,
};

export default initialState;
