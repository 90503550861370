export const SET_PIPELINE_FOLDER_LOAD = "SET_PIPELINE_FOLDER_LOAD";
export const SET_PIPELINE_FOLDER_DATA = "SET_PIPELINE_FOLDER_DATA";
export const SET_PIPELINE_FOLDER_USER_ID = "SET_PIPELINE_FOLDER_USER_ID";
export const SET_PIPELINE_SELECTED_FOLDER = "SET_PIPELINE_SELECTED_FOLDER";
export const SET_PIPELINE_PROJECT_FILTER = "SET_PIPELINE_PROJECT_FILTER";
export const SET_PIPELINE_PROJECT_UTIL = "SET_PIPELINE_PROJECT_UTIL";
export const SET_PIPELINE_PROJECT_PAGE = "SET_PIPELINE_PROJECT_PAGE";
export const SET_PIPELINE_DATA = "SET_PIPELINE_DATA";
export const SET_PIPELINE_DATA_LOAD = "SET_PIPELINE_DATA_LOAD";
export const SET_PIPELINE_DATA_ERROR = "SET_PIPELINE_DATA_ERROR";
export const SET_PIPELINE_PROJECT_COMPANIES = "SET_PIPELINE_PROJECT_COMPANIES";
export const SET_PIPELINE_ARCHIVE = "SET_PIPELINE_ARCHIVE";
export const RESET_PIPELINE = "RESET_PIPELINE";
export const SET_PROJECT_PIPELINE_LIMIT_DATA_PER_PAGE =
  "SET_PROJECT_PIPELINE_LIMIT_DATA_PER_PAGE";
export const SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE =
  "SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE";
export const SET_PIPELINE_PROJECT_LIST_VIEW = "SET_PIPELINE_PROJECT_LIST_VIEW";
export const SET_PIPELINE_PROJECT_FOLDER_TYPE =
  "SET_PIPELINE_PROJECT_FOLDER_TYPE";

export const SET_PIPELINE_PROJECT_MAP_DATA = "SET_PIPELINE_PROJECT_MAP_DATA";
export const SET_PIPELINE_PROJECT_MAP_LOAD = "SET_PIPELINE_PROJECT_MAP_LOAD";
export const SET_PIPELINE_PROJECT_MAP_PAGE = "SET_PIPELINE_PROJECT_MAP_PAGE";
export const SET_PIPELINE_PROJECT_MAP_TOTAL = "SET_PIPELINE_PROJECT_MAP_TOTAL";
export const SET_PIPELINE_HISTORY_PAGE = "SET_PIPELINE_HISTORY_PAGE";
export const SET_PROJECT_SUGGESTIONS_DATA = "SET_PROJECT_SUGGESTIONS_DATA";
export const RESET_HISTORY_PROJECT_PIPELINE = "RESET_HISTORY_PROJECT_PIPELINE";

export const RESET_PIPELINE_PROJECT_FILTER = "RESET_PIPELINE_PROJECT_FILTER";
