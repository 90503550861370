const initialState = {
  saveSearchTabActive: "project",
  saveSearchData: [],
  saveSearchLimit: 25,
  saveSearchTotal: 0,
  saveSearchPage: 1,
  saveSearchLoad: false,
  saveSearchKeyword: "",
  saveSearchAlertType: "",
  saveSearchSubscriberId: null,
  saveSearchSelected: [],
  saveSearchSelectedObject: null,
  saveSearchModalDetail: false,
  saveSearchModalDelete: false,
  saveSearchModalChangeName: false,
  saveSearchModalMovePriority: false,
  saveSearchModalShare: false,
  saveSearchModalCopy: false,
  saveSearchModalAlert: false,
  saveSearchModalAlertNotFound: false,
  saveSearchCountTotal: 0,
};

export default initialState;
