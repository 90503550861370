import initialState from "./initialState";
import {
  SET_KEY_ACCOUNT_FOLDER_USER_ID,
  SET_KEY_ACCOUNT_FOLDER_LOAD,
  SET_KEY_ACCOUNT_FOLDER_DATA,
  SET_KEY_ACCOUNT_SELECTED_FOLDER,
  SET_KEY_ACCOUNT_PROJECT_UTIL,
  SET_KEY_ACCOUNT_PROJECT_FILTER,
  SET_KEY_ACCOUNT_DATA,
  SET_KEY_ACCOUNT_DATA_LOAD,
  SET_KEY_ACCOUNT_DATA_ERROR,
  RESET_KEY_ACCOUNT,
  SET_KEY_ACCOUNT_PROJECT_LIST_VIEW,
  SET_KEY_ACCOUNT_PROJECT_FOLDER_TYPE,
  RESET_KEY_ACCOUNT_PROJECT_FILTER,
  SET_COMPANY_SUGGESTIONS_DATA,
} from "./type";

const ProjectPipeline = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_KEY_ACCOUNT_FOLDER_LOAD: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            keyAccountListDefaultFolder: {
              ...state.keyAccountListDefaultFolder,
              load: true,
              data: [],
            },
          }
        : {
            ...state,
            keyAccountListMyFolder: {
              ...state.keyAccountListMyFolder,
              load: true,
              data: [],
            },
          };
    }
    case SET_KEY_ACCOUNT_FOLDER_DATA: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            keyAccountListDefaultFolder: {
              ...state.keyAccountListDefaultFolder,
              load: false,
              data: payload.data,
            },
          }
        : {
            ...state,
            keyAccountListMyFolder: {
              ...state.keyAccountListMyFolder,
              load: false,
              data: payload.data,
            },
          };
    }
    case SET_KEY_ACCOUNT_SELECTED_FOLDER: {
      return payload?.selectedPage
        ? {
            ...state,
            keyAccountData: initialState.keyAccountData,
            keyAccountUtil: {
              ...state.keyAccountUtil,
              page: payload?.selectedPage,
            },
            keyAccountSelectedFolder: payload?.data,
          }
        : {
            ...state,
            keyAccountData: initialState.keyAccountData,
            keyAccountUtil: {
              ...initialState.keyAccountUtil,
              limit: state?.keyAccountUtil?.limit || 25,
            },
            keyAccountSelectedFolder: payload?.data,
          };
    }
    case RESET_KEY_ACCOUNT: {
      return {
        ...initialState,
        companySuggestionsData: state.companySuggestionsData,
      };
    }
    case SET_KEY_ACCOUNT_DATA: {
      return {
        ...state,
        keyAccountData: {
          ...initialState.keyAccountData,
          data: payload.data,
          total: payload.total,
          load: false,
          error: false,
        },
        keyAccountHistoryPage: payload.history,
      };
    }
    case SET_KEY_ACCOUNT_DATA_LOAD: {
      return {
        ...state,
        keyAccountData: {
          ...initialState.keyAccountData,
          load: true,
        },
      };
    }
    case SET_KEY_ACCOUNT_DATA_ERROR: {
      return {
        ...state,
        keyAccountData: {
          ...initialState.keyAccountData,
          error: true,
        },
      };
    }
    case SET_KEY_ACCOUNT_PROJECT_FILTER: {
      return {
        ...state,
        keyAccountFilter: {
          ...state.keyAccountFilter,
          ...payload,
        },
        keyAccountUtil: { ...state.keyAccountUtil, page: 1 },
      };
    }
    case RESET_KEY_ACCOUNT_PROJECT_FILTER: {
      return {
        ...state,
        keyAccountFilter: initialState.keyAccountFilter,
      };
    }
    case SET_KEY_ACCOUNT_PROJECT_UTIL: {
      return {
        ...state,
        keyAccountUtil: {
          ...state.keyAccountUtil,
          ...payload,
        },
      };
    }

    case SET_KEY_ACCOUNT_PROJECT_LIST_VIEW: {
      return {
        ...state,
        keyAccountListView: payload,
      };
    }

    case SET_KEY_ACCOUNT_FOLDER_USER_ID: {
      return {
        ...state,
        keyAccountFolderUserId: payload,
        keyAccountSelectedFolder: null,
        keyAccountFolderType: initialState.keyAccountFolderType,
        keyAccountListDefaultFolder: initialState.keyAccountListDefaultFolder,
        keyAccountListMyFolder: initialState.keyAccountListMyFolder,
      };
    }

    case SET_KEY_ACCOUNT_PROJECT_FOLDER_TYPE: {
      return {
        ...state,
        keyAccountFolderType: payload,
        keyAccountData: initialState?.keyAccountData,
        keyAccountSelectedFolder: null,
      };
    }

    case SET_COMPANY_SUGGESTIONS_DATA: {
      return {
        ...state,
        companySuggestionsData: payload || null,
      };
    }

    default:
      return state;
  }
};

export default ProjectPipeline;
