import initialState from './initialState';
import {
    SET_CATEGORY_SEARCH_CHECK, 
    SET_STAGE_SEARCH_CHECK, 
    SET_LOCATION_SEARCH_CHECK, 
    SET_ROLE_SEARCH_CHECK, 
    SET_TABS_CHECK ,
    SET_TABS_LOCATION,
    SET_ADVANCE_LOCATION,
    SET_ALL_TABS, 
    SET_GLOBAL_SEARCH_FILTER_COMPANY, 
    SET_MODAL_FILTER_ADVANCE_PROJECT, 
    SET_MODAL_FILTER_ADVANCE_COMPANY,
    SET_SEARCH_FILTER,
    SET_SEARCH_FILTER_DOCUMENT,
    SET_SEARCH_FILTER_FOLDER,
    SET_SEARCH_TABS_ALL,
    SET_SEARCH_TABS_COMPANY,
    SET_SEARCH_TABS_PROJECT,
    SET_GLOBAL_SEARCH_FILTER_PROJECT,
    SET_TABS_CHECK_ACTIVE,
    SET_OTHER_SUBSCRIPTION_PROJECTS,
    RESET_GLOBAL_SEARCH,
    SET_MODAL_SAVE_SEARCH_PROJECT,
    SET_MODAL_SAVE_SEARCH_COMPANY,
    SET_FORCE_DISABLE_REFRESH_SEARCH_LIST,
    SET_MAP_MODE_ACTIVE,
    SET_MAP_POPUP_REFRESH,
    SET_MAP_POPUP_OPEN,
    SET_MAP_DATA,
    SET_SEARCH_OPTION,
} from "./type";

const globalSearchReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CATEGORY_SEARCH_CHECK : {
            return{
                ...state,
                categorySearchCheck : payload
                
            }
        }
        case SET_LOCATION_SEARCH_CHECK : {
            return{
                ...state,
                locationSearchCheck : payload
                
                
            }
        }
        case SET_STAGE_SEARCH_CHECK : {
            return{
                ...state,
                stageSearchCheck : payload
                
            }
        }
        case SET_ROLE_SEARCH_CHECK : {
            return{
                ...state,
                roleSearchCheck : payload
                
            }
        }
        case SET_TABS_CHECK : {
            return{
                ...state,
                tabsCheck : payload
            }
        }
        case SET_TABS_LOCATION : {
            return{
                ...state,
                tabsLocationCheck : payload
            }
        }
        case SET_ADVANCE_LOCATION : {
            return{
                ...state,
                advanceLocation : {
                    ...state.advanceLocation,
                    ...payload
                }
            }
        }
        case SET_ALL_TABS : {
            return{
                ...state,
                allActiveTabs : payload
            }
        }
        case SET_GLOBAL_SEARCH_FILTER_COMPANY : {
            return{
                ...state,
                filterCompany: {
                    ...state.filterCompany,
                    ...payload
                }
            }
        }
        case SET_GLOBAL_SEARCH_FILTER_PROJECT : {
            return{
                ...state,
                filterProject: {
                    ...state.filterProject,
                    ...payload
                }
            }
        }
        case SET_MODAL_FILTER_ADVANCE_PROJECT : {
            return{
                ...state,
                projectAdvanceFilterModal: payload
            }
        }
        case SET_MODAL_FILTER_ADVANCE_COMPANY : {
            return{
                ...state,
                companyAdvanceFilterModal: payload
            }
        }
        case SET_MODAL_SAVE_SEARCH_PROJECT : {
            return{
                ...state,
                projectSaveSearchModal: payload
            }
        }
        case SET_MODAL_SAVE_SEARCH_COMPANY : {
            return{
                ...state,
                companySaveSearchModal: payload
            }
        }
        case SET_OTHER_SUBSCRIPTION_PROJECTS : {
            return{
                ...state,
                showProjectsOutsideSubscription: payload
            }
        }
        case SET_FORCE_DISABLE_REFRESH_SEARCH_LIST : {
            return{
                ...state,
                forceDisableRefreshSearchList: payload
            }
        }
        case SET_SEARCH_FILTER : {
            return{
                ...state,
                searchFilter: payload
            }
        }
        case SET_SEARCH_FILTER_FOLDER : {
            return{
                ...state,
                searchFilterFolder: payload
            }
        }
        case SET_SEARCH_FILTER_DOCUMENT : {
            return{
                ...state,
                searchFilterDocument: payload
            }
        }
        case SET_SEARCH_TABS_ALL : {
            return{
                ...state,
                searchTabAll: payload
            }
        }
        case SET_SEARCH_TABS_COMPANY : {
            return{
                ...state,
                searchTabCompany: payload
            }
        }
        case SET_SEARCH_TABS_PROJECT : {
            return{
                ...state,
                searchTabProject: payload
            }
        }
        case SET_TABS_CHECK_ACTIVE : {
            return{
                ...state,
                tabsCheckActive: state.tabsCheck
            }
        }
        case RESET_GLOBAL_SEARCH : {
            return initialState
        }
        case SET_MAP_MODE_ACTIVE : {
            return{
                ...state,
                mapMode: payload
            }
        }
        case SET_MAP_POPUP_REFRESH : {
            return{
                ...state,
                mapPopupRefresh: payload
            }
        }
        case SET_MAP_POPUP_OPEN : {
            return{
                ...state,
                mapPopupOpen: payload
            }
        }
        case SET_MAP_DATA : {
            return{
                ...state,
                mapData: payload
            }
        }
        case SET_SEARCH_OPTION : {
            return{
                ...state,
                searchOptions: {
                    ...state.searchOptions,
                    ...payload
                }
            }
        }
    default:
        return state;
    }
};

export default globalSearchReducer;