import initialState from "./initialState";
import { SET_LANGUAGE_LABEL } from "./types";

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LANGUAGE_LABEL: {
      return {
        ...state,
        languageLabelList: payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
