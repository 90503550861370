const initialState = {
  projectDetail: null,
  projectDetailLoad: false,
  projectDetailError: false,

  projectDetailContact: [],
  projectDetailContactLoad: false,
  projectDetailContactError: false,
  keyword: "",

  projectDetailDocument: [],
  projectDetailDocumentLoad: false,
  projectDetailDocumentError: false,

  projectNotes: {
    load: true,
    error: false,
    data: [],
    total: 0,
    page: 1,
    limit: 5,
    detail: null,
    formNotesData: null,
  },

  projectFollowUpTask: {
    load: true,
    error: false,
    data: [],
    total: 0,
    page: 1,
    limit: 5,
    detail: {
      data: null,
      source: "", // contact || followup
    },
    formFollowUpTaskData: null,
    deleteFollowUpTaskId: 0,
  },

  projectNavActive: null,
  projectWatchlistForm: null,
  projectLastVersion: null,
  projectActionRoleDialog: { open: false, data: null },
  projectActionLinkedCompany: { open: false, data: null },
  unlinkContactDialog: { open: false, data: null },
};

export default initialState;
