import initialState from "./initialState";
import {
  SET_PIPELINE_FOLDER_USER_ID,
  SET_PIPELINE_FOLDER_LOAD,
  SET_PIPELINE_FOLDER_DATA,
  SET_PIPELINE_SELECTED_FOLDER,
  SET_PIPELINE_PROJECT_UTIL,
  SET_PIPELINE_PROJECT_FILTER,
  SET_PIPELINE_DATA,
  SET_PIPELINE_DATA_LOAD,
  SET_PIPELINE_DATA_ERROR,
  RESET_PIPELINE,
  SET_PIPELINE_PROJECT_LIST_VIEW,
  SET_PIPELINE_PROJECT_FOLDER_TYPE,
  RESET_HISTORY_PROJECT_PIPELINE,
  RESET_PIPELINE_PROJECT_FILTER,
  SET_PROJECT_SUGGESTIONS_DATA,
} from "./type";

const ProjectPipeline = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PIPELINE_FOLDER_LOAD: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            projectpipelineListDefaultFolder: {
              ...state.projectpipelineListDefaultFolder,
              load: true,
              data: [],
            },
          }
        : {
            ...state,
            projectpipelineListMyFolder: {
              ...state.projectpipelineListMyFolder,
              load: true,
              data: [],
            },
          };
    }
    case SET_PIPELINE_FOLDER_DATA: {
      return payload.folderType === "default_folder"
        ? {
            ...state,
            projectpipelineListDefaultFolder: {
              ...state.projectpipelineListDefaultFolder,
              load: false,
              data: payload.data,
            },
          }
        : {
            ...state,
            projectpipelineListMyFolder: {
              ...state.projectpipelineListMyFolder,
              load: false,
              data: payload.data,
            },
          };
    }
    case SET_PIPELINE_SELECTED_FOLDER: {
      return payload?.selectedPage
        ? {
            ...state,
            projectpipelineData: initialState.projectpipelineData,
            projectpipelineUtil: {
              ...state?.projectpipelineUtil,
              page: payload?.selectedPage,
            },
            projectpipelineSelectedFolder: payload?.data,
          }
        : {
            ...state,
            projectpipelineData: initialState.projectpipelineData,
            projectpipelineUtil: {
              ...initialState.projectpipelineUtil,
              limit: state?.projectpipelineUtil?.limit || 25,
            },
            projectpipelineSelectedFolder: payload?.data,
          };
    }
    case RESET_PIPELINE: {
      return {
        ...initialState,
        projectSuggestionsData: state.projectSuggestionsData,
      };
    }
    case SET_PIPELINE_DATA: {
      return {
        ...state,
        projectpipelineData: {
          ...initialState.projectpipelineData,
          data: payload.data,
          total: payload.total,
          load: false,
          error: false,
        },
        projectPipelineHistoryPage: payload.history,
      };
    }
    case SET_PIPELINE_DATA_LOAD: {
      return {
        ...state,
        projectpipelineData: {
          ...initialState.projectpipelineData,
          load: true,
        },
      };
    }
    case SET_PIPELINE_DATA_ERROR: {
      return {
        ...state,
        projectpipelineData: {
          ...initialState.projectpipelineData,
          error: true,
        },
      };
    }
    case SET_PIPELINE_PROJECT_FILTER: {
      return {
        ...state,
        projectpipelineFilter: {
          ...state.projectpipelineFilter,
          ...payload,
        },
        projectpipelineUtil: { ...state.projectpipelineUtil, page: 1 },
      };
    }
    case SET_PIPELINE_PROJECT_UTIL: {
      return {
        ...state,
        projectpipelineUtil: {
          ...state.projectpipelineUtil,
          ...payload,
        },
      };
    }

    case SET_PIPELINE_PROJECT_LIST_VIEW: {
      return {
        ...state,
        projectPipelineListView: payload,
      };
    }

    case SET_PIPELINE_FOLDER_USER_ID: {
      return {
        ...state,
        projectpipelineFolderUserId: payload,
        projectpipelineSelectedFolder: null,
        projectPipelineFolderType: initialState.projectPipelineFolderType,
        projectpipelineListDefaultFolder:
          initialState.projectpipelineListDefaultFolder,
        projectpipelineListMyFolder: initialState.projectpipelineListMyFolder,
      };
    }

    case SET_PIPELINE_PROJECT_FOLDER_TYPE: {
      return {
        ...state,
        projectPipelineFolderType: payload,
        projectpipelineData: initialState?.projectpipelineData,
        projectpipelineSelectedFolder: null,
      };
    }
    case SET_PROJECT_SUGGESTIONS_DATA: {
      return {
        ...state,
        projectSuggestionsData: payload || null,
      };
    }
    case RESET_HISTORY_PROJECT_PIPELINE: {
      return {
        ...state,
        projectPipelineHistoryPage: [],
      };
    }
    case RESET_PIPELINE_PROJECT_FILTER: {
      return {
        ...state,
        projectpipelineFilter: initialState.projectpipelineFilter,
      };
    }

    default:
      return state;
  }
};

export default ProjectPipeline;
