const initialState = {
  watchlistNotifSource: null,
  watchlistNotifSourceLoad: false,
  watchlistNotifFolderSelected: null,
  watchlistPipelineNotifRequest: {
    categories: [],
    location: null,
    advancedLocation: null,
    stage: [],
    keyword: "",
  },
  watchlistNotifShowNoteTask: false,
  watchlistKeyAccountNotifRequest: {
    location: null,
    advancedLocation: null,
    roleGroups: [],
    keyword: "",
  },
  watchlistNotifFilter: {
    page: 1,
    limit: 25,
    sortby: "",
    order: "",
  },
  watchlistNotifList: {
    data: [],
    included: null,
    total: 0,
    error: null,
    load: true,
  },
};

export default initialState;
