import initialState from "./initialState";
import {
  RESET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA,
  SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA,
  SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_ERROR,
  SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_LOAD,
  SET_INDIVIDUAL_ACTIVITY_INSIGHT_DATA,
  SET_INDIVIDUAL_ACTIVITY_INSIGHT_ERROR,
  SET_INDIVIDUAL_ACTIVITY_INSIGHT_FILTER,
  SET_INDIVIDUAL_ACTIVITY_INSIGHT_LOAD,
  SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_DATA,
  SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_ERROR,
  SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_LOAD,
  SET_USER_ACTIVITY_INSIGHT_ACTIVE,
  SET_USER_ACTIVITY_INSIGHT_DATA,
  SET_USER_ACTIVITY_INSIGHT_DATA_ERROR,
  SET_USER_ACTIVITY_INSIGHT_DATA_LOAD,
  SET_USER_ACTIVITY_INSIGHT_FILTER,
} from "./types";

const userActivityInsight = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_ACTIVITY_INSIGHT_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      };
    }
    case SET_USER_ACTIVITY_INSIGHT_DATA_LOAD: {
      return {
        ...state,
        userInsightActive: null,
        userInsight: {
          ...initialState.userInsight,
          load: true,
        },
      };
    }
    case SET_USER_ACTIVITY_INSIGHT_DATA: {
      return {
        ...state,
        userInsight: {
          ...initialState.userInsight,
          data: payload,
        },
      };
    }
    case SET_USER_ACTIVITY_INSIGHT_DATA_ERROR: {
      return {
        ...state,
        userInsight: {
          ...initialState.userInsight,
          error: payload,
        },
      };
    }
    case SET_USER_ACTIVITY_INSIGHT_ACTIVE: {
      return {
        ...state,
        userInsightActive: payload,
      };
    }

    case SET_INDIVIDUAL_ACTIVITY_INSIGHT_FILTER: {
      return {
        ...state,
        individualActivityFilter: {
          ...state.individualActivityFilter,
          ...payload,
        },
      };
    }

    case SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_LOAD: {
      return {
        ...state,
        individualActivityUserList: {
          ...initialState.individualActivityUserList,
          load: true,
        },
      };
    }
    case SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_DATA: {
      return {
        ...state,
        individualActivityUserList: {
          ...initialState.individualActivityUserList,
          data: payload?.data,
          total: payload?.total,
        },
      };
    }
    case SET_INDIVIDUAL_ACTIVITY_INSIGHT_USER_LIST_ERROR: {
      return {
        ...state,
        individualActivityUserList: {
          ...initialState.individualActivityUserList,
          error: payload,
        },
      };
    }

    case SET_INDIVIDUAL_ACTIVITY_INSIGHT_LOAD: {
      return {
        ...state,
        individualActivity: {
          ...initialState.individualActivity,
          load: true,
        },
      };
    }
    case SET_INDIVIDUAL_ACTIVITY_INSIGHT_DATA: {
      return {
        ...state,
        individualActivity: {
          ...initialState.individualActivity,
          data: payload?.data,
          total: payload?.total,
        },
      };
    }
    case SET_INDIVIDUAL_ACTIVITY_INSIGHT_ERROR: {
      return {
        ...state,
        individualActivity: {
          ...initialState.individualActivity,
          error: payload,
        },
      };
    }

    case SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_LOAD: {
      return {
        ...state,
        individualActivityDetail: {
          ...initialState.individualActivityDetail,
          load: true,
        },
      };
    }
    case SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA: {
      return {
        ...state,
        individualActivityDetail: {
          ...initialState.individualActivityDetail,
          data: payload?.data,
          total: payload?.total,
        },
      };
    }
    case SET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_ERROR: {
      return {
        ...state,
        individualActivityDetail: {
          ...initialState.individualActivityDetail,
          error: payload,
        },
      };
    }
    case RESET_INDIVIDUAL_ACTIVITY_DETAIL_INSIGHT_DATA: {
      return {
        ...state,
        individualActivityDetail: initialState.individualActivity,
      };
    }

    default:
      return state;
  }
};

export default userActivityInsight;
