const initialState = {
  searchCompany: {
    data: [],
  },
  resultDataValues: {
    values: [],
    loadValues: false,
    error: false,
  },
  resultDataTopResult: {
    loadDataTopResult: false,
    error: null,
    totalData: 0,
    totalLinked: 0,
  },
  searchProjectLocation: {
    locations: [],
    loadLocation: false,
    error: null,
    loadLocationNextPage: false,
    totalLocations: 0,
    currentPartition: 1,
  },
};

export default initialState;
