export const SET_SAVE_SEARCH_TAB = "SET_SAVE_SEARCH_TAB";
export const SET_SAVE_SEARCH_DATA = "SET_SAVE_SEARCH_DATA";
export const SET_SAVE_SEARCH_LOAD = "SET_SAVE_SEARCH_LOAD";
export const SET_SAVE_SEARCH_PAGE = "SET_SAVE_SEARCH_PAGE";
export const SET_SAVE_SEARCH_TOTAL = "SET_SAVE_SEARCH_TOTAL";
export const SET_COUNT_SAVE_SEARCH_ALL = "SET_COUNT_SAVE_SEARCH_ALL";
export const SET_SAVE_SEARCH_KEYWORD = "SET_SAVE_SEARCH_KEYWORD";
export const SET_SAVE_SEARCH_ALERT_TYPE = "SET_SAVE_SEARCH_ALERT_TYPE";
export const SET_SAVE_SEARCH_SUBSCRIBER_ID = "SET_SAVE_SEARCH_SUBSCRIBER_ID";
export const SET_SAVE_SEARCH_SELECTED = "SET_SAVE_SEARCH_SELECTED";
export const SET_SAVE_SEARCH_MODAL_DETAIL = "SET_SAVE_SEARCH_MODAL_DETAIL";
export const SET_SAVE_SEARCH_MODAL_DELETE = "SET_SAVE_SEARCH_MODAL_DELETE";
export const SET_SAVE_SEARCH_MODAL_CHANGE_NAME =
  "SET_SAVE_SEARCH_MODAL_CHANGE_NAME";
export const SET_SAVE_SEARCH_MODAL_MOVE_PRIORITY =
  "SET_SAVE_SEARCH_MODAL_MOVE_PRIORITY";
export const SET_SAVE_SEARCH_MODAL_SHARE = "SET_SAVE_SEARCH_MODAL_SHARE";
export const SET_SAVE_SEARCH_MODAL_COPY = "SET_SAVE_SEARCH_MODAL_COPY";
export const SET_SAVE_SEARCH_MODAL_ALERT = "SET_SAVE_SEARCH_MODAL_ALERT";
export const SET_SAVE_SEARCH_SELECTED_OBJECT =
  "SET_SAVE_SEARCH_SELECTED_OBJECT";
export const SET_SAVE_SEARCH_MODAL_ALERT_NOT_FOUND =
  "SET_SAVE_SEARCH_MODAL_ALERT_NOT_FOUND";
export const SET_SAVE_SEARCH_LIMIT = "SET_SAVE_SEARCH_LIMIT";

export const RESET_SAVE_SEARCH = "RESET_SAVE_SEARCH";
