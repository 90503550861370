import initialState from "./initialState";
import {
  SET_SAVE_SEARCH_ALERT_TYPE,
  SET_SAVE_SEARCH_DATA,
  SET_SAVE_SEARCH_KEYWORD,
  SET_SAVE_SEARCH_LOAD,
  SET_SAVE_SEARCH_MODAL_ALERT,
  SET_SAVE_SEARCH_MODAL_CHANGE_NAME,
  SET_SAVE_SEARCH_MODAL_DELETE,
  SET_SAVE_SEARCH_MODAL_DETAIL,
  SET_SAVE_SEARCH_MODAL_MOVE_PRIORITY,
  SET_SAVE_SEARCH_MODAL_SHARE,
  SET_SAVE_SEARCH_PAGE,
  SET_SAVE_SEARCH_SELECTED,
  SET_SAVE_SEARCH_SELECTED_OBJECT,
  SET_SAVE_SEARCH_TAB,
  SET_SAVE_SEARCH_LIMIT,
  SET_SAVE_SEARCH_TOTAL,
  SET_SAVE_SEARCH_MODAL_ALERT_NOT_FOUND,
  SET_COUNT_SAVE_SEARCH_ALL,
  SET_SAVE_SEARCH_SUBSCRIBER_ID,
  SET_SAVE_SEARCH_MODAL_COPY,
  RESET_SAVE_SEARCH,
} from "./type";

const saveSearch = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SAVE_SEARCH_TAB: {
      return {
        ...state,
        saveSearchTabActive: payload,
        saveSearchPage: 1,
      };
    }
    case SET_SAVE_SEARCH_DATA: {
      return {
        ...state,
        saveSearchData: payload,
        saveSearchLoad: false,
      };
    }
    case SET_SAVE_SEARCH_LOAD: {
      return {
        ...state,
        saveSearchLoad: true,
        saveSearchData: [],
      };
    }
    case SET_SAVE_SEARCH_TOTAL: {
      return {
        ...state,
        saveSearchTotal: payload,
      };
    }
    case SET_SAVE_SEARCH_PAGE: {
      return {
        ...state,
        saveSearchPage: payload,
      };
    }
    case SET_SAVE_SEARCH_KEYWORD: {
      return {
        ...state,
        saveSearchKeyword: payload,
      };
    }
    case SET_SAVE_SEARCH_LIMIT: {
      return {
        ...state,
        saveSearchLimit: payload,
      };
    }
    case SET_SAVE_SEARCH_ALERT_TYPE: {
      return {
        ...state,
        saveSearchAlertType: payload,
      };
    }
    case SET_SAVE_SEARCH_SUBSCRIBER_ID: {
      return {
        ...state,
        saveSearchSubscriberId: payload,
      };
    }
    case SET_SAVE_SEARCH_SELECTED: {
      return {
        ...state,
        saveSearchSelected: payload,
      };
    }
    case SET_SAVE_SEARCH_SELECTED_OBJECT: {
      return {
        ...state,
        saveSearchSelectedObject: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_DETAIL: {
      return {
        ...state,
        saveSearchModalDetail: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_DELETE: {
      return {
        ...state,
        saveSearchModalDelete: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_CHANGE_NAME: {
      return {
        ...state,
        saveSearchModalChangeName: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_MOVE_PRIORITY: {
      return {
        ...state,
        saveSearchModalMovePriority: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_SHARE: {
      return {
        ...state,
        saveSearchModalShare: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_COPY: {
      return {
        ...state,
        saveSearchModalCopy: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_ALERT: {
      return {
        ...state,
        saveSearchModalAlert: payload,
      };
    }
    case SET_SAVE_SEARCH_MODAL_ALERT_NOT_FOUND: {
      return { ...state, saveSearchModalAlertNotFound: payload };
    }
    case SET_COUNT_SAVE_SEARCH_ALL: {
      return { ...state, saveSearchCountTotal: payload };
    }

    case RESET_SAVE_SEARCH: {
      return initialState;
    }
    default:
      return state;
  }
};

export default saveSearch;
