import initialState from "./initialState";
import {
  SET_SEARCH_PROJECT_PAGE,
  SET_SEARCH_PROJECT_LIMIT,
  SET_SEARCH_PROJECT_SORT,
  SET_SEARCH_PROJECT_DATA,
  SET_SEARCH_PROJECT_DATA_LOAD,
  SET_SEARCH_PROJECT_OTHER_DATA,
  SET_SEARCH_PROJECT_OTHER_DATA_LOAD,
  SET_SEARCH_PROJECT_DETAIL,
  SET_SEARCH_PROJECT_DETAIL_DATA,
  SET_SEARCH_PROJECT_SELECTED,
  SET_SEARCH_PROJECT_TOTAL,
  RESET_SEARCH_PROJECT,
  SET_SEARCH_PROJECT_DETAIL_LOAD,
  SET_SEARCH_PROJECT_ERROR,
  SET_SEARCH_PROJECT_DETAIL_ERROR,
  SET_SEARCH_PROJECT_TOTAL_OUTSIDE_SUBSCRIPTION,
  SET_SEARCH_PROJECT_WATCHLIST_FORM,
  SET_SEARCH_PROJECT_LOCATION_DATA_LOAD,
  SET_SEARCH_PROJECT_LOCATION_DATA,
  SET_SEARCH_PROJECT_LOCATION_OTHER_DATA_LOAD,
  SET_SEARCH_PROJECT_LOCATION_OTHER_DATA,
  SET_SEARCH_PROJECT_DATA_MAP_MODE,
  SET_SEARCH_PROJECT_DATA_LOAD_MAP_MODE,
} from "./type";

const searchProject = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_PROJECT_PAGE: {
      return {
        ...state,
        projectPage: payload,
        // projectData: []
        // projectOtherData: []
      };
    }
    case SET_SEARCH_PROJECT_LIMIT: {
      return {
        ...state,
        projectLimit: payload,
        projectPage: 1,
        projectData: [],
      };
    }
    case SET_SEARCH_PROJECT_SORT: {
      return {
        ...state,
        projectSortBy: payload,
        projectPage: 1,
        projectData: [],
      };
    }
    case SET_SEARCH_PROJECT_DATA: {
      return {
        ...state,
        projectDataError: false,
        projectData: payload,
        projectLoad: false,
        projectDetail: null,
        projectDetailData: null,
        projectSelected: [],
      };
    }
    case SET_SEARCH_PROJECT_DATA_MAP_MODE: {
      return {
        ...state,
        projectDataError: false,
        projectData: payload,
        projectLoad: false,
      };
    }
    case SET_SEARCH_PROJECT_DATA_LOAD: {
      return {
        ...state,
        projectDataError: false,
        projectLoad: true,
        projectData: [],
        projectOtherData: [],
        projectDetail: null,
        projectDetailData: null,
        projectSelected: [],
        projectWatchlistForm: null,
      };
    }
    case SET_SEARCH_PROJECT_DATA_LOAD_MAP_MODE: {
      return {
        ...state,
        projectDataError: false,
        projectLoad: true,
        projectData: [],
        projectOtherData: [],
        projectWatchlistForm: null,
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_DATA: {
      return {
        ...state,
        projectLocationData: payload,
        projectLocationLoad: false,
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_DATA_LOAD: {
      return {
        ...state,
        projectLocationLoad: true,
        projectLocationData: [],
      };
    }
    case SET_SEARCH_PROJECT_OTHER_DATA: {
      return {
        ...state,
        projectOtherData: payload,
        projectOtherLoad: false,
      };
    }
    case SET_SEARCH_PROJECT_OTHER_DATA_LOAD: {
      return {
        ...state,
        projectOtherData: [],
        projectOtherLoad: true,
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_OTHER_DATA: {
      return {
        ...state,
        projectLocationOtherData: payload,
        projectLocationOtherLoad: false,
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_OTHER_DATA_LOAD: {
      return {
        ...state,
        projectLocationOtherData: [],
        projectLocationOtherLoad: true,
      };
    }
    case SET_SEARCH_PROJECT_DETAIL_LOAD: {
      return {
        ...state,
        projectDetailError: false,
        projectDetailLoad: true,
      };
    }
    case SET_SEARCH_PROJECT_DETAIL: {
      return {
        ...state,
        projectDetail: payload,
        projectDetailData: null,
        companyDetail: null,
      };
    }
    case SET_SEARCH_PROJECT_DETAIL_DATA: {
      return {
        ...state,
        projectDetailData: payload,
        projectDetailLoad: false,
      };
    }
    case SET_SEARCH_PROJECT_SELECTED: {
      return {
        ...state,
        projectSelected: payload,
        projectDetail: null,
      };
    }
    case SET_SEARCH_PROJECT_TOTAL: {
      return {
        ...state,
        projectTotal: payload,
      };
    }
    case SET_SEARCH_PROJECT_TOTAL_OUTSIDE_SUBSCRIPTION: {
      return {
        ...state,
        projectOutsideSubscription: payload,
      };
    }
    case RESET_SEARCH_PROJECT: {
      return {
        ...initialState,
      };
    }
    case SET_SEARCH_PROJECT_ERROR: {
      return {
        ...state,
        projectDataError: true,
        projectLoad: false,
        projectData: [],
        projectOtherData: [],
        projectDetail: null,
        projectSelected: [],
        projectTotal: 0,
        projectOutsideSubscription: 0,
      };
    }
    case SET_SEARCH_PROJECT_DETAIL_ERROR: {
      return {
        ...state,
        projectDetailError: true,
        projectDetailLoad: false,
        projectDetailData: null,
      };
    }
    case SET_SEARCH_PROJECT_WATCHLIST_FORM: {
      return {
        ...state,
        projectWatchlistForm: payload,
      };
    }
    default:
      return state;
  }
};

export default searchProject;
