import initialState from "./initialState";
import {
  SET_COMPANY_LIST_SEARCH,
  SET_SEARCH_COMPANY_LOCATION,
  SET_SEARCH_COMPANY_LOCATION_ERROR,
  SET_SEARCH_COMPANY_LOCATION_LOAD,
  SET_SEARCH_COMPANY_LOCATION_TOTAL,
  SET_SEARCH_COMPANY_LOCATION_LOAD_NEXT_PAGE,
  SET_SEARCH_COMPANY_LOCATION_ADD,
  SET_SEARCH_COMPANY_LOCATION_CURRENT_PARTITION,
  SET_SEARCH_COMPANY_VALUES_LOAD,
  SET_SEARCH_COMPANY_VALUES_ERROR,
  SET_SEARCH_COMPANY_VALUES,
  RESET_SEARCH_COMPANY_VALUES,
  SET_SEARCH_COMPANY_RESULT_LOAD,
  SET_SEARCH_COMPANY_RESULT_ERROR,
  SET_SEARCH_COMPANY_RESULT,
  RESET_SEARCH_COMPANY_RESULT,
  RESET_SEARCH_COMPANY_LIST,
  SET_SEARCH_COMPANY_LOCATION_REMOVE,
} from "./types";

const companyList = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_LIST_SEARCH: {
      return {
        ...state,
        searchProject: {
          ...state.searchCompany,
          data: payload,
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_LOAD: {
      return {
        ...state,
        searchProjectLocation: {
          ...initialState.searchProjectLocation,
          loadLocation: payload ? payload: true,
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_ERROR: {
      return {
        ...state,
        searchProjectLocation: {
          ...initialState.searchProjectLocation,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION: {
      return {
        ...state,
        searchProjectLocation: {
          ...initialState.searchProjectLocation,
          locations: payload?.data,
          totalLocations: state.searchProjectLocation.totalLocations,
          scrollId: payload?.scrollId
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_ADD: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          locations: state.searchProjectLocation.locations.concat(
            payload?.data
          ),
          scrollId: payload?.scrollId,
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_REMOVE: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          locations: state.searchProjectLocation.locations?.filter((x) => !payload.includes(+x.companyId)),
          totalLocations: state.searchProjectLocation.totalLocations ? state.searchProjectLocation.totalLocations-payload.length : 0,
          redrawMap: new Date()
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_LOAD_NEXT_PAGE: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          loadLocationNextPage: payload,
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_TOTAL: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          totalLocations: payload,
        },
      };
    }
    case SET_SEARCH_COMPANY_LOCATION_CURRENT_PARTITION: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          currentPartition: payload,
        },
      };
    }

    case SET_SEARCH_COMPANY_VALUES_LOAD: {
      return {
        ...state,
        resultDataValues: {
          ...initialState.resultDataValues,
          values: state.resultDataValues.values,
          loadValues: true,
        },
      };
    }
    case SET_SEARCH_COMPANY_VALUES_ERROR: {
      return {
        ...state,
        resultDataValues: {
          ...initialState.resultDataValues,
          values: state.resultDataValues.values,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_COMPANY_VALUES: {
      return {
        ...state,
        resultDataValues: {
          ...initialState.resultDataValues,
          values: [...state.resultDataValues.values, ...payload],
        },
      };
    }
    case RESET_SEARCH_COMPANY_VALUES: {
      return {
        ...state,
        resultDataValues: initialState.resultDataValues,
      };
    }

    case SET_SEARCH_COMPANY_RESULT_LOAD: {
      return {
        ...state,
        resultDataTopResult: {
          ...initialState.resultDataTopResult,
          loadDataTopResult: true,
        },
      };
    }
    case SET_SEARCH_COMPANY_RESULT_ERROR: {
      return {
        ...state,
        resultDataTopResult: {
          ...initialState.resultDataTopResult,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_COMPANY_RESULT: {
      return {
        ...state,
        resultDataTopResult: {
          ...initialState.resultDataTopResult,
          totalData: payload.totalData,
          totalLinked: payload.totalLinked,
        },
      };
    }
    case RESET_SEARCH_COMPANY_RESULT: {
      return {
        ...state,
        resultDataTopResult: {
          ...initialState.resultDataTopResult,
          totalData: payload.totalData,
          totalLinked: payload.totalLinked,
        },
      };
    }

    case RESET_SEARCH_COMPANY_LIST: {
      return initialState;
    }

    default:
      return state;
  }
};

export default companyList;
