const initialState = {
  companyData: [],
  companyLoad: true,
  companyLocationData: [],
  companyLocationLoad: true,
  companyDetailLoad: true,

  companySortBy: "relevance",
  companyLimit: 25,
  companyPage: 1,

  companyDetail: null,
  companyDetailData: null,
  companySelected: [],

  companyTotal: 0,

  companyDataError: false,
  companyDetailError: false,

  companyWatchlistForm: null,

  companiesProjectValue: {
    load: false,
    data: [],
    error: false,
  },
};

export default initialState;
