export const SET_GLOBAL_LOADER_STATE = "SET_GLOBAL_LOADER_STATE";
export const SET_CURRENT_VIEW = "SET_CURRENT_VIEW";
export const SET_MORE_APP_MODAL = "SET_MORE_APP_MODAL";
export const SET_PRINT_PREVIEW = "SET_PRINT_PREVIEW";
export const SET_FORCE_LOGGED_OUT_INACTIVE = "SET_FORCE_LOGGED_OUT_INACTIVE";
export const SET_FORCE_LOGGED_OUT_TOKEN_EXPIRE =
  "SET_FORCE_LOGGED_OUT_TOKEN_EXPIRE";

export const SET_GLOBAL_API_ERROR_TIMEOUT_LIST =
  "SET_GLOBAL_API_ERROR_TIMEOUT_LIST";
export const SET_GLOBAL_API_ERROR_TIMEOUT_RETRY_COUNT =
  "SET_GLOBAL_API_ERROR_TIMEOUT_RETRY_COUNT";
export const RESET_GLOBAL_API_ERROR_TIMEOUT = "RESET_GLOBAL_API_ERROR_TIMEOUT";

export const SET_GLOBAL_API_ERROR = "SET_GLOBAL_API_ERROR";
export const RESET_GLOBAL_API_ERROR = "RESET_GLOBAL_API_ERROR";

//type for modal subscription on setting
export const SET_SUBSCRIPTION_MODAL = "SET_SUBSCRIPTION_MODAL";

// Action User Capcha
export const SET_USER_FRAUD = "SET_USER_FRAUD";

// Action User Capcha
export const SET_PREPARE_EXCEL_DOWNLOAD_MODAL =
  "SET_PREPARE_EXCEL_DOWNLOAD_MODAL";
