const initialState = {
  dashboardUpdates: {
    loadDashboardUpdate: false,
    data: {
      all: [],
      bciUpdate: [],
      myActivity: [],
      teamActivity: [],
    },
    errorDashboardUpdates: null,
  },
};

export default initialState;
