import initialState from "./initialState";
import {
  RESET_PRESET_KEY_ACCOUNT,
  RESET_PRESET_PIPELINE,
  SET_PRESET_ALERT_TAB,
  SET_PRESET_KEY_ACCOUNT_DATA,
  SET_PRESET_KEY_ACCOUNT_DATA_LOAD,
  SET_PRESET_KEY_ACCOUNT_DETAIL,
  SET_PRESET_KEY_ACCOUNT_FILTER,
  SET_PRESET_PIPELINE_DATA,
  SET_PRESET_PIPELINE_DATA_LOAD,
  SET_PRESET_PIPELINE_DETAIL,
  SET_PRESET_PIPELINE_FILTER,
} from "./types";

const presetAlert = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PRESET_PIPELINE_DATA: {
      return {
        ...state,
        projectPipeline: {
          ...state.projectPipeline,
          list: {
            data: payload.data,
            total: payload.total,
            load: false,
          },
        },
      };
    }
    case SET_PRESET_PIPELINE_DATA_LOAD: {
      return {
        ...state,
        projectPipeline: {
          ...state.projectPipeline,
          list: {
            ...initialState.projectPipeline.list,
            load: true,
          },
          detail: null,
        },
      };
    }
    case SET_PRESET_PIPELINE_FILTER: {
      return {
        ...state,
        projectPipeline: {
          ...state.projectPipeline,
          filter: {
            ...state.projectPipeline.filter,
            ...payload,
          },
        },
      };
    }
    case SET_PRESET_PIPELINE_DETAIL: {
      return {
        ...state,
        projectPipeline: {
          ...state.projectPipeline,
          detail: payload,
        },
      };
    }
    case RESET_PRESET_PIPELINE: {
      return {
        ...state,
        projectPipeline: initialState.projectPipeline,
      };
    }

    case SET_PRESET_KEY_ACCOUNT_DATA: {
      return {
        ...state,
        keyAccount: {
          ...state.keyAccount,
          list: {
            data: payload.data,
            total: payload.total,
            load: false,
          },
        },
      };
    }
    case SET_PRESET_KEY_ACCOUNT_DATA_LOAD: {
      return {
        ...state,
        keyAccount: {
          ...state.keyAccount,
          list: {
            ...initialState.keyAccount.list,
            load: true,
          },
          detail: null,
        },
      };
    }
    case SET_PRESET_KEY_ACCOUNT_FILTER: {
      return {
        ...state,
        keyAccount: {
          ...state.keyAccount,
          filter: {
            ...state.keyAccount.filter,
            ...payload,
          },
        },
      };
    }
    case SET_PRESET_KEY_ACCOUNT_DETAIL: {
      return {
        ...state,
        keyAccount: {
          ...state.keyAccount,
          detail: payload,
        },
      };
    }
    case RESET_PRESET_KEY_ACCOUNT: {
      return {
        ...state,
        keyAccount: initialState.keyAccount,
      };
    }

    case SET_PRESET_ALERT_TAB: {
      return {
        ...state,
        tabActive: payload,
      };
    }

    default:
      return state;
  }
};

export default presetAlert;
