import initialState from "./initialState";
import { SET_ARCHIVE_TAB } from "./types";

const archiveReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ARCHIVE_TAB: {
      return {
        ...state,
        tab: payload,
      };
    }

    default:
      return state;
  }
};

export default archiveReducer;
