export const SET_WATCHLIST_TAB = "SET_WATCHLIST_TAB";

export const SET_PIPELINE_SHOW_NOTE_TASK = "SET_PIPELINE_SHOW_NOTE_TASK";
export const SET_PIPELINE_FORM = "SET_PIPELINE_FORM";
export const SET_PIPELINE_ALERT_FORM = "SET_PIPELINE_ALERT_FORM";
export const SET_PIPELINE_FOLDER_LOAD = "SET_PIPELINE_FOLDER_LOAD";
export const SET_PIPELINE_FOLDER_DATA = "SET_PIPELINE_FOLDER_DATA";
export const SET_PIPELINE_FOLDER_USER_ID = "SET_PIPELINE_FOLDER_USER_ID";
export const SET_PIPELINE_SELECTED_FOLDER = "SET_PIPELINE_SELECTED_FOLDER";
export const SET_PIPELINE_PROJECT_FILTER = "SET_PIPELINE_PROJECT_FILTER";
export const SET_PIPELINE_PROJECT_REQUEST = "SET_PIPELINE_PROJECT_REQUEST";
export const SET_PIPELINE_PROJECT_PAGE = "SET_PIPELINE_PROJECT_PAGE";
export const SET_PIPELINE_DATA = "SET_PIPELINE_DATA";
export const SET_PIPELINE_DATA_LOAD = "SET_PIPELINE_DATA_LOAD";
export const SET_PIPELINE_DATA_ERROR = "SET_PIPELINE_DATA_ERROR";
export const SET_PIPELINE_PROJECT_COMPANIES = "SET_PIPELINE_PROJECT_COMPANIES";
export const SET_PIPELINE_ARCHIVE = "SET_PIPELINE_ARCHIVE";

export const SET_KEYACCOUNT_SHOW_NOTE_TASK = "SET_KEYACCOUNT_SHOW_NOTE_TASK";
export const SET_KEYACCOUNT_FORM = "SET_KEYACCOUNT_FORM";
export const SET_KEYACCOUNT_ALERT_FORM = "SET_KEYACCOUNT_ALERT_FORM";
export const SET_KEYACCOUNT_FOLDER_LOAD = "SET_KEYACCOUNT_FOLDER_LOAD";
export const SET_KEYACCOUNT_FOLDER_DATA = "SET_KEYACCOUNT_FOLDER_DATA";
export const SET_KEYACCOUNT_FOLDER_USER_ID = "SET_KEYACCOUNT_FOLDER_USER_ID";
export const SET_KEYACCOUNT_SELECTED_FOLDER = "SET_KEYACCOUNT_SELECTED_FOLDER";
export const SET_KEYACCOUNT_PROJECT_FILTER = "SET_KEYACCOUNT_PROJECT_FILTER";
export const SET_KEYACCOUNT_PROJECT_REQUEST = "SET_KEYACCOUNT_PROJECT_REQUEST";
export const SET_KEYACCOUNT_PROJECT_PAGE = "SET_KEYACCOUNT_PROJECT_PAGE";
export const SET_KEYACCOUNT_DATA = "SET_KEYACCOUNT_DATA";
export const SET_KEYACCOUNT_DATA_LOAD = "SET_KEYACCOUNT_DATA_LOAD";
export const SET_KEYACCOUNT_DATA_ERROR = "SET_KEYACCOUNT_DATA_ERROR";
export const SET_KEYACCOUNT_ARCHIVE = "SET_KEYACCOUNT_ARCHIVE";

export const SET_NOTIFICATION_SOURCE_DATA = "SET_NOTIFICATION_SOURCE_DATA";

export const RESET_PIPELINE = "RESET_PIPELINE";
export const RESET_KEYACCOUNT = "RESET_KEYACCOUNT";
export const RESET_WATCHLIST = "RESET_WATCHLIST";

export const SET_PROJECT_PIPELINE_LIMIT_DATA_PER_PAGE =
  "SET_PROJECT_PIPELINE_LIMIT_DATA_PER_PAGE";
export const SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE =
  "SET_KEY_ACCOUNT_LIMIT_DATA_PER_PAGE";
