import initialState from "./initialState";
import {
  SET_DASHBOARD_UPDATE,
  SET_DASHBOARD_UPDATE_ERROR,
  SET_DASHBOARD_UPDATE_LOAD,
} from "./types";

const dashboard = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DASHBOARD_UPDATE_LOAD: {
      return {
        ...state,
        dashboardUpdates: {
          ...initialState.dashboardUpdates,
          loadDashboardUpdate: true,
        },
      };
    }
    case SET_DASHBOARD_UPDATE_ERROR: {
      return {
        ...state,
        dashboardUpdates: {
          ...initialState.dashboardUpdates,
          errorDashboardUpdates: payload || "something went wrong",
        },
      };
    }
    case SET_DASHBOARD_UPDATE: {
      return {
        ...state,
        dashboardUpdates: {
          ...initialState.dashboardUpdates,
          data: {
            all: payload.all,
            bciUpdate: payload.bciUpdate,
            myActivity: payload.myActivity,
            teamActivity: payload.teamActivity,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default dashboard;
