import initialState from "./initialState";
import {
  RESET_PROJECT_PIPELINE_LOCATION,
  SET_PIPELINE_PROJECT_LOCATION_DATA,
  SET_PIPELINE_PROJECT_LOCATION_LOAD,
  SET_PIPELINE_PROJECT_LOCATION_LOAD_NEXT,
  SET_PIPELINE_PROJECT_LOCATION_PAGE,
  SET_PIPELINE_PROJECT_LOCATION_TOTAL,
  SET_AFTER_PAGE_PROJECT_PIPELINE,
} from "./type";

const ProjectPipelineLocation = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PIPELINE_PROJECT_LOCATION_DATA: {
      return {
        ...state,
        loadLocation: false,
        locations: payload,
      };
    }
    case SET_PIPELINE_PROJECT_LOCATION_LOAD: {
      return {
        ...state,
        loadLocation: payload,
      };
    }
    case SET_PIPELINE_PROJECT_LOCATION_LOAD_NEXT: {
      return {
        ...state,
        loadLocationNextPage: payload,
      };
    }
    case SET_PIPELINE_PROJECT_LOCATION_PAGE: {
      return {
        ...state,
        locationPage: payload,
      };
    }
    case SET_PIPELINE_PROJECT_LOCATION_TOTAL: {
      return {
        ...state,
        totalLocations: payload,
      };
    }
    case RESET_PROJECT_PIPELINE_LOCATION: {
      return {
        ...initialState,
      };
    }
    case SET_AFTER_PAGE_PROJECT_PIPELINE: {
      return {
        ...state,
        afterPage: payload,
      };
    }
    default:
      return state;
  }
};

export default ProjectPipelineLocation;
