import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import SSORedirectRoute from "../components/TNLMRedirect";

const user = localStorage.getItem("profile");

interface PrivateRouteProps {
  children?: React.ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const location = useLocation();

  let redirect = `${location.pathname}${location.search}`;
  if (redirect.indexOf("/logout") === 0) {
    redirect = "/main/dashboard";
  }
  localStorage.setItem("redirect", redirect);

  if (!user) {
    return <SSORedirectRoute />;
  } else {
    return children ? children : <Outlet />;
  }
};

export default PrivateRoute;
