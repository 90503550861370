import initialState from "./initialState";
import {
  RESET_GLOBAL_API_ERROR,
  RESET_GLOBAL_API_ERROR_TIMEOUT,
  SET_CURRENT_VIEW,
  SET_FORCE_LOGGED_OUT_INACTIVE,
  SET_FORCE_LOGGED_OUT_TOKEN_EXPIRE,
  SET_GLOBAL_API_ERROR,
  SET_GLOBAL_API_ERROR_TIMEOUT_LIST,
  SET_GLOBAL_API_ERROR_TIMEOUT_RETRY_COUNT,
  SET_GLOBAL_LOADER_STATE,
  SET_MORE_APP_MODAL,
  SET_PREPARE_EXCEL_DOWNLOAD_MODAL,
  SET_PRINT_PREVIEW,
  SET_SUBSCRIPTION_MODAL,
  SET_USER_FRAUD,
} from "./types";

const layoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GLOBAL_LOADER_STATE: {
      return {
        ...state,
        globalLoaderState: payload,
      };
    }
    case SET_CURRENT_VIEW: {
      return {
        ...state,
        PREV_VIEW: payload.PREV_VIEW,
        CURRENT_VIEW: payload.CURRENT_VIEW,
      };
    }
    case SET_MORE_APP_MODAL: {
      return {
        ...state,
        moreApp: !state.moreApp,
      };
    }
    case SET_PRINT_PREVIEW: {
      return {
        ...state,
        printPreview: payload,
      };
    }
    case SET_FORCE_LOGGED_OUT_INACTIVE: {
      return {
        ...state,
        forceLoggedOutInactive: payload,
      };
    }
    case SET_FORCE_LOGGED_OUT_TOKEN_EXPIRE: {
      return {
        ...state,
        forceLoggedOutTokenExpire: payload,
      };
    }
    case SET_GLOBAL_API_ERROR_TIMEOUT_RETRY_COUNT: {
      return {
        ...state,
        globalApiErrorTimeout: {
          ...state.globalApiErrorTimeout,
          retryCount: payload,
        },
      };
    }
    case SET_GLOBAL_API_ERROR: {
      return {
        ...state,
        globalApiError: {
          ...state.globalApiError,
          errorStatus: payload?.errorStatus ? payload?.errorStatus : null,
          errorMessage: payload?.errorStatus ? payload?.errorMessage : null,
          errorUrl: payload?.errorUrl || null,
          errorMethod: payload?.errorMethod || null,
        },
      };
    }
    case RESET_GLOBAL_API_ERROR: {
      return {
        ...state,
        globalApiError: initialState.globalApiError,
        globalApiErrorTimeout: initialState.globalApiErrorTimeout,
      };
    }
    case SET_GLOBAL_API_ERROR_TIMEOUT_LIST: {
      return {
        ...state,
        globalApiErrorTimeout: {
          ...state.globalApiErrorTimeout,
          error: true,
          apiReloadList: [
            ...state.globalApiErrorTimeout.apiReloadList,
            ...payload,
          ],
        },
      };
    }
    case RESET_GLOBAL_API_ERROR_TIMEOUT: {
      return {
        ...state,
        globalApiErrorTimeout: {
          ...initialState.globalApiErrorTimeout,
          retryCount: payload?.retryCount,
        },
      };
    }
    case SET_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        subscriptionModal: payload,
      };
    }
    case SET_USER_FRAUD: {
      return {
        ...state,
        userFraudIndicationStatus: payload,
      };
    }
    case SET_PREPARE_EXCEL_DOWNLOAD_MODAL: {
      return {
        ...state,
        prepareExcelDownloadModal: payload,
      };
    }
    default:
      return state;
  }
};

export default layoutReducer;
