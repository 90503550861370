export const SET_CF_TAB = "SET_CF_TAB";

export const SET_CF_PROJECT_LIST = "SET_CF_PROJECT_LIST";
export const SET_CF_PROJECT_LIST_LOAD = "SET_CF_PROJECT_LIST_LOAD";
export const SET_CF_PROJECT_LIST_ERROR = "SET_CF_PROJECT_LIST_ERROR";
export const SET_CF_PROJECT_DETAIL = "SET_CF_PROJECT_DETAIL";
export const RESET_CF_PROJECT = "RESET_CF_PROJECT";

export const SET_CF_COMPANY_LIST = "SET_CF_COMPANY_LIST";
export const SET_CF_COMPANY_LIST_LOAD = "SET_CF_COMPANY_LIST_LOAD";
export const SET_CF_COMPANY_LIST_ERROR = "SET_CF_COMPANY_LIST_ERROR";
export const SET_CF_COMPANY_DETAIL = "SET_CF_COMPANY_DETAIL";
export const RESET_CF_COMPANY = "RESET_CF_COMPANY";

export const SET_CF_ADD_FIELD_DIALOG = "SET_CF_ADD_FIELD_DIALOG";

export const SET_CF_TYPE_FIELD = "SET_CF_TYPE_FIELD";
export const SET_CF_SELECTED_LIST = "SET_CF_SELECTED_LIST";
