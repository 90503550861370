import initialState from "./initialState";
import {
  SET_LIST_COMBO_CATEGORY,
  SET_LIST_COMBO_LOCATION,
  SET_LIST_COMBO_STAGE,
  SET_LIST_COMBO_ROLE,
  SET_LIST_PROJECT_SAVED,
  SET_LIST_COMPANY_SAVED,
  SET_LIST_GREEN_BUILDING,
  SET_LIST_KEY_ACCOUNT,
  SET_LIST_SALES_PIPELINE,
  SET_LIST_DEVELOPMENT_TYPE,
  SET_LIST_OWNERSHIP_TYPE,
  SET_LIST_COMBO_COUNTRIES,
  SET_LIST_LAST_UPDATE,
  SET_LIST_TENDER_STATUS,
  SET_LIST_ASSIGNED_TO,
  SET_LIST_CONTACT_BY,
  SET_LIST_VISIBILITY_TYPE_CODE,
  SET_LIST_ASSIGNED_BY,
  SET_LIST_PRESET_PIPELINE_ALERT,
  SET_LIST_PRESET_ACCOUNT_ALERT,
  SET_LIST_INQUIRIES_TYPE,
  RESET_ALL_COMBOS,
  SET_LIST_CONSTRUCTION_START_DATE,
  SET_LIST_TENDER_CLOSING_DATE,
  SET_LIST_GREEN_BUILDING_STATUS,
  SET_LIST_GREEN_BUILDING_SCHEME,
  SET_LIST_COMBO_LOCATION_LOAD,
  SET_LIST_COMBO_CATEGORY_LOAD,
  SET_LIST_COMBO_STAGE_LOAD,
  SET_LIST_COMBO_ROLE_LOAD,
  SET_LIST_LAST_UPDATE_LOAD,
  SET_LIST_CONSTRUCTION_START_DATE_LOAD,
  SET_LIST_TENDER_CLOSING_DATE_LOAD,
  SET_LIST_OWNERSHIP_TYPE_LOAD,
  SET_LIST_DEVELOPMENT_TYPE_LOAD,
  SET_LIST_GREEN_BUILDING_SCHEME_LOAD,
  SET_LIST_GREEN_BUILDING_STATUS_LOAD,
  SET_LIST_KEY_ACCOUNT_LOAD,
  SET_LIST_SALES_PIPELINE_LOAD,
  SET_LIST_PROJECT_SAVED_LOAD,
  SET_LIST_COMPANY_SAVED_LOAD,
  SET_LIST_TENDER_STATUS_LOAD,
  SET_LIST_APPOINTED_METHOD,
  SET_LIST_APPOINTED_METHOD_LOAD,
  SET_LIST_QUOTED,
  SET_LIST_PRODUCT_SPECIFIED,
  SET_LIST_PROBABILITY,
  SET_LIST_WON_LOSE,
  SET_LIST_COMBO_ROLE_SETTING,
  SET_LIST_PREFERENCE_LANGUAGE_LOAD,
  SET_LIST_PREFERENCE_LANGUAGE,
  SET_LIST_COMPANY_GROUP_LOAD,
  SET_LIST_COMPANY_GROUP,
  SET_LIST_PROJECT_CF_LOAD,
  SET_LIST_PROJECT_CF,
  SET_LIST_COMPANY_CF_LOAD,
  SET_LIST_COMPANY_CF,
  SET_LIST_ROLE_STATUS,
  SET_LIST_ROLE_STATUS_LOAD, SET_LIST_COMBO_BUILDING_MATERIAL, SET_LIST_BUILDING_MATERIAL_LOAD,
} from "./types";

const listComboReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_COMBO_CATEGORY: {
      return {
        ...state,
        categoryList: payload,
      };
    }
    case SET_LIST_COMBO_LOCATION: {
      return {
        ...state,
        locationList: payload,
      };
    }
    case SET_LIST_COMBO_BUILDING_MATERIAL:{
      return {
        ...state,
        buildingMaterialList: payload,
      };
    }
    case SET_LIST_COMBO_STAGE: {
      return {
        ...state,
        stageList: payload,
      };
    }
    case SET_LIST_COMBO_ROLE: {
      return {
        ...state,
        roleList: payload,
      };
    }
    case SET_LIST_COMBO_ROLE_SETTING: {
      return {
        ...state,
        roleListSetting: payload,
      };
    }
    case SET_LIST_PROJECT_SAVED: {
      return {
        ...state,
        projectSavedQueryList: payload,
      };
    }
    case SET_LIST_COMPANY_SAVED: {
      return {
        ...state,
        companySavedQueryList: payload,
      };
    }
    case SET_LIST_GREEN_BUILDING: {
      return {
        ...state,
        greenBuildingList: payload,
      };
    }
    case SET_LIST_SALES_PIPELINE: {
      return {
        ...state,
        salesPipelineList: payload,
      };
    }
    case SET_LIST_KEY_ACCOUNT: {
      return {
        ...state,
        keyAccountList: payload,
      };
    }
    case SET_LIST_DEVELOPMENT_TYPE: {
      return {
        ...state,
        developmentTypeList: payload,
      };
    }
    case SET_LIST_OWNERSHIP_TYPE: {
      return {
        ...state,
        ownershipTypeList: payload,
      };
    }
    case SET_LIST_COMBO_COUNTRIES: {
      return {
        ...state,
        countriesList: payload,
      };
    }
    case SET_LIST_LAST_UPDATE: {
      return {
        ...state,
        lastUpdateList: payload,
      };
    }
    case SET_LIST_TENDER_STATUS: {
      return {
        ...state,
        tenderStatusList: payload,
      };
    }
    case SET_LIST_ASSIGNED_TO: {
      return {
        ...state,
        assignedTo: payload,
      };
    }
    case SET_LIST_ASSIGNED_BY: {
      return {
        ...state,
        assignedByList: payload,
      };
    }
    case SET_LIST_CONTACT_BY: {
      return {
        ...state,
        contactBy: payload,
      };
    }
    case SET_LIST_VISIBILITY_TYPE_CODE: {
      return {
        ...state,
        visibilityTypeCode: payload,
      };
    }
    case SET_LIST_PRESET_PIPELINE_ALERT: {
      return {
        ...state,
        presetPipelineList: payload,
      };
    }
    case SET_LIST_PRESET_ACCOUNT_ALERT: {
      return {
        ...state,
        presetAccountList: payload,
      };
    }
    case SET_LIST_INQUIRIES_TYPE: {
      return {
        ...state,
        inquiriesTypeList: payload,
      };
    }
    case SET_LIST_CONSTRUCTION_START_DATE: {
      return {
        ...state,
        constructionStartDateList: payload,
      };
    }
    case SET_LIST_TENDER_CLOSING_DATE: {
      return {
        ...state,
        tenderClosingDateList: payload,
      };
    }
    case SET_LIST_GREEN_BUILDING_STATUS: {
      return {
        ...state,
        greenBuildingStatusList: payload,
      };
    }
    case SET_LIST_GREEN_BUILDING_SCHEME: {
      return {
        ...state,
        greenBuildingSchemeList: payload,
      };
    }
    case RESET_ALL_COMBOS: {
      return {
        ...initialState,
      };
    }
    case SET_LIST_COMBO_LOCATION_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          locationLoad: payload,
        },
      };
    }

    case SET_LIST_COMBO_CATEGORY_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          categoryLoad: payload,
        },
      };
    }

    case SET_LIST_COMBO_STAGE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          stageLoad: payload,
        },
      };
    }
    case SET_LIST_COMBO_ROLE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          roleLoad: payload,
        },
      };
    }
    case SET_LIST_LAST_UPDATE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          lastUpdateLoad: payload,
        },
      };
    }
    case SET_LIST_BUILDING_MATERIAL_LOAD:{
      return {
        ...state,
        loadState: {
          ...state.loadState,
          buildingMaterialLoad: payload,
        },
      };
    }
    case SET_LIST_CONSTRUCTION_START_DATE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          constructionStartDateLoad: payload,
        },
      };
    }
    case SET_LIST_TENDER_CLOSING_DATE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          tenderClosingDateLoad: payload,
        },
      };
    }
    case SET_LIST_DEVELOPMENT_TYPE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          developmentTypeLoad: payload,
        },
      };
    }
    case SET_LIST_OWNERSHIP_TYPE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          ownershipTypeLoad: payload,
        },
      };
    }
    case SET_LIST_GREEN_BUILDING_STATUS_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          greenBuildingStatusLoad: payload,
        },
      };
    }
    case SET_LIST_GREEN_BUILDING_SCHEME_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          greenBuildingSchemeLoad: payload,
        },
      };
    }

    case SET_LIST_SALES_PIPELINE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          salesPipelineLoad: payload,
        },
      };
    }

    case SET_LIST_KEY_ACCOUNT_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          keyAccountLoad: payload,
        },
      };
    }

    case SET_LIST_PROJECT_SAVED_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          projectSavedQueryLoad: payload,
        },
      };
    }

    case SET_LIST_COMPANY_SAVED_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          companySavedQueryLoad: payload,
        },
      };
    }

    case SET_LIST_TENDER_STATUS_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          tenderStatusLoad: payload,
        },
      };
    }

    case SET_LIST_APPOINTED_METHOD: {
      return {
        ...state,
        appointedMethodList: payload,
      };
    }
    case SET_LIST_APPOINTED_METHOD_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          appointedMethodLoad: payload,
        },
      };
    }
    case SET_LIST_QUOTED: {
      return {
        ...state,
        quotedList: payload,
      };
    }
    case SET_LIST_PRODUCT_SPECIFIED: {
      return {
        ...state,
        productSpecifiedList: payload,
      };
    }
    case SET_LIST_PROBABILITY: {
      return {
        ...state,
        probabilityList: payload,
      };
    }
    case SET_LIST_WON_LOSE: {
      return {
        ...state,
        wonLoseList: payload,
      };
    }
    case SET_LIST_PREFERENCE_LANGUAGE: {
      return {
        ...state,
        preferenceLanguageList: payload,
      };
    }
    case SET_LIST_PREFERENCE_LANGUAGE_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          preferenceLanguageListLoad: payload,
        },
      };
    }
    case SET_LIST_COMPANY_GROUP: {
      return {
        ...state,
        companyGroupList: payload,
      };
    }
    case SET_LIST_COMPANY_GROUP_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          companyGroupListLoad: payload,
        },
      };
    }
    case SET_LIST_PROJECT_CF: {
      return {
        ...state,
        projectCFList: payload,
      };
    }
    case SET_LIST_PROJECT_CF_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          projectCFLoad: payload,
        },
      };
    }
    case SET_LIST_COMPANY_CF: {
      return {
        ...state,
        companyCFList: payload,
      };
    }
    case SET_LIST_COMPANY_CF_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          companyCFLoad: payload,
        },
      };
    }
    case SET_LIST_ROLE_STATUS: {
      return {
        ...state,
        roleStatusList: payload,
      };
    }
    case SET_LIST_ROLE_STATUS_LOAD: {
      return {
        ...state,
        loadState: {
          ...state.loadState,
          roleStatusLoad: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default listComboReducer;
