export const SET_SALES_INSIGHT_TAB = "SET_SALES_INSIGHT_TAB";

// ----- Sales Forecast ---- //
export const SET_SALES_FORECAST_FILTER = "SET_SALES_FORECAST_FILTER";
export const SET_SALES_FORECAST_DURATION_FILTER =
  "SET_SALES_FORECAST_DURATION_FILTER";
export const SET_SALES_FORECAST_CATEGORY_FILTER =
  "SET_SALES_FORECAST_CATEGORY_FILTER";

export const SET_SALES_FORECAST_DURATION_DATA_LOAD =
  "SET_SALES_FORECAST_DURATION_DATA_LOAD";
export const SET_SALES_FORECAST_DURATION_DATA_ERROR =
  "SET_SALES_FORECAST_DURATION_DATA_ERROR";
export const SET_SALES_FORECAST_DURATION_DATA =
  "SET_SALES_FORECAST_DURATION_DATA";

export const SET_SALES_FORECAST_CATEGORY_DATA_LOAD =
  "SET_SALES_FORECAST_CATEGORY_DATA_LOAD";
export const SET_SALES_FORECAST_CATEGORY_DATA_ERROR =
  "SET_SALES_FORECAST_CATEGORY_DATA_ERROR";
export const SET_SALES_FORECAST_CATEGORY_DATA =
  "SET_SALES_FORECAST_CATEGORY_DATA";
// ----- Sales Forecast ---- //

// ---- Sales Performance ---- //
export const SET_SALES_PERFORMANCE_INSIGHT_FILTER =
  "SET_SALES_PERFORMANCE_INSIGHT_FILTER";
export const SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_LOAD =
  "SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_LOAD";
export const SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_ERROR =
  "SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA_ERROR";
export const SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA =
  "SET_SALES_PERFORMANCE_INSIGHT_SUMMARIES_DATA";

export const SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_LOAD =
  "SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_LOAD";
export const SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_ERROR =
  "SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA_ERROR";
export const SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA =
  "SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_DATA";
export const SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_FILTER =
  "SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_FILTER";
export const SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_PAGINATION =
  "SET_SALES_PERFORMANCE_INSIGHT_STATISTIC_PAGINATION";
// ---- Sales Performance ---- //

// ---- Won/Lost ---- //
export const SET_WON_LOST_INSIGHT_FILTER = "SET_WON_LOST_INSIGHT_FILTER";
// ---- Won/Lost ---- //

// ---- Competitor Lost To ---- //
export const SET_COMPETITOR_INSIGHT_FILTER = "SET_COMPETITOR_INSIGHT_FILTER";
// ---- Competitor Lost To ---- //

export const RESET_SALES_INSIGHT_DATA = "RESET_SALES_INSIGHT_DATA";
export const RESET_SALES_INSIGHT = "RESET_SALES_INSIGHT";
