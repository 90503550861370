export const SET_LOCATION_SEARCH_CHECK = "SET_LOCATION_SEARCH_CHECK";
export const SET_CATEGORY_SEARCH_CHECK = "SET_CATEGORY_SEARCH_CHECK";
export const SET_STAGE_SEARCH_CHECK = "SET_STAGE_SEARCH_CHECK";
export const SET_ROLE_SEARCH_CHECK = "SET_ROLE_SEARCH_CHECK";
export const SET_TABS_CHECK = "SET_TABS_CHECK";
export const SET_TABS_LOCATION = "SET_TABS_LOCATION";
export const SET_ADVANCE_LOCATION = "SET_ADVANCE_LOCATION";
export const SET_ALL_TABS = "SET_ALL_TABS";
export const SET_GLOBAL_SEARCH_FILTER_COMPANY = "SET_GLOBAL_SEARCH_FILTER_COMPANY";
export const SET_GLOBAL_SEARCH_FILTER_PROJECT = "SET_GLOBAL_SEARCH_FILTER_PROJECT";
export const SET_MODAL_FILTER_ADVANCE_PROJECT = "SET_MODAL_FILTER_ADVANCE_PROJECT";
export const SET_MODAL_FILTER_ADVANCE_COMPANY = "SET_MODAL_FILTER_ADVANCE_COMPANY";
export const SET_MODAL_SAVE_SEARCH_PROJECT = "SET_MODAL_SAVE_SEARCH_PROJECT";
export const SET_MODAL_SAVE_SEARCH_COMPANY = "SET_MODAL_SAVE_SEARCH_COMPANY";
export const SET_OTHER_SUBSCRIPTION_PROJECTS = "SET_OTHER_SUBSCRIPTION_PROJECTS";
export const SET_FORCE_DISABLE_REFRESH_SEARCH_LIST = "SET_FORCE_DISABLE_REFRESH_SEARCH_LIST";
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER"
export const SET_SEARCH_FILTER_FOLDER = "SET_SEARCH_FILTER_FOLDER"
export const SET_SEARCH_FILTER_DOCUMENT = "SET_SEARCH_FILTER_DOCUMENT"
export const SET_SEARCH_TABS_ALL = "SET_SEARCH_TABS_ALL"
export const SET_SEARCH_TABS_PROJECT = "SET_SEARCH_TABS_PROJECT"
export const SET_SEARCH_TABS_COMPANY = "SET_SEARCH_TABS_COMPANY"
export const SET_TABS_CHECK_ACTIVE = "SET_TABS_CHECK_ACTIVE";
export const SET_MAP_MODE_ACTIVE = "SET_MAP_MODE_ACTIVE";
export const SET_MAP_POPUP_REFRESH = "SET_MAP_POPUP_REFRESH";
export const SET_MAP_POPUP_OPEN = "SET_MAP_POPUP_OPEN";
export const SET_MAP_DATA = "SET_MAP_DATA";

export const SET_SEARCH_OPTION = "SET_SEARCH_OPTION";

export const RESET_GLOBAL_SEARCH = "RESET_GLOBAL_SEARCH";