const initialState = {
  parameters: {
    // Parameters from filter search bar
    // Keyword
    keyword: "",
    advanceKeyword: {
      projectName: "",
      projectDescription: "",
      projectDocument: "",
    },
    projectListIds: [],
    excludeZeroMatchedProject: false,
    isShowCompaniesByProjects: false,
    projectVersionListIds: [],
    // role
    roleGroups: null,
    // Company Location
    location: null,
    advancedLocation: null,
    // Category
    categories: null,
    // Stage
    stage: null,
    // Date range
    lastUpdate: null,
    lastUpdateCustom: null,
    tenderClosingDateCustom: null,
    tenderClosingDateDay: null,
    constructionStartDateCustom: null,
    constructionStartDateDay: null,
    constructionEndDateCustom: null,
    constructionEndDateDay: null,
    // Project detail
    floorArea: null,
    siteArea: null,
    noOfStorey: null,
    noOfUnit: null,
    developmentType: null,
    ownershipType: null,
    allOwnership: null,
    foreignParticipantsProject: null,
    greenBuildingStatusId: null,
    greenBuildingSchemeId: null,
    anyGreenBuilding: null,
    projectDocument: null,
    projectImage: null,
    // Project Value
    projectValue: null,
    // Project Location
    projectLocation: null,
    advancedProjectLocation: null,
    // Tender Type
    contractType: null,
    // other
    excludeAllFolder: null,
    excludeAllWatchlist: 0,
    otherKeyAccountMyAllFolder: null, //0=false, 1=true include, 2=true exclude
    otherKeyAccountAllMyTeamFolder: null, //0=false, 1=true include, 2=true exclude
    disableCompanyCount :true,
    disableInnerHits : true
  },

  filter: {
    limit: 25,
    sortby: "number_of_total_project",
    offset: 0,
  },

  resultData: {
    companies: [],
    load: false,
    error: null,
    totalData: 0,
  },
  resultDataTopResult: {
    loadDataTopResult: false,
    error: null,
    totalData: 0,
    totalLinked: 0,
  },

  selected: [],
  selectedCompanyId: [],
  detail: null,
  detailData: {
    loadData: false,
    detailData: null,
  },

  companyWatchlistForm: null,
  companyEnquiryForm: false,

  saveSearch: null,
  showAllDataOnMap: false,
  redrawMap: null,
};

export default initialState;
