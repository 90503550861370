export const SET_MARKET_INSIGHT_TAB = "SET_MARKET_INSIGHT_TAB";

export const SET_MARKET_INSIGHT_FORECASTER_YEARS =
  "SET_MARKET_INSIGHT_FORECASTER_YEARS";
export const SET_MARKET_INSIGHT_FORECASTER_FILTER =
  "SET_MARKET_INSIGHT_FORECASTER_FILTER";
export const SET_MARKET_INSIGHT_FORECASTER_DATA =
  "SET_MARKET_INSIGHT_FORECASTER_DATA";
export const SET_MARKET_INSIGHT_FORECASTER_DATA_LOAD =
  "SET_MARKET_INSIGHT_FORECASTER_DATA_LOAD";
export const SET_MARKET_INSIGHT_FORECASTER_DATA_ERROR =
  "SET_MARKET_INSIGHT_FORECASTER_DATA_ERROR";

export const RESET_MARKET_INSIGHT = "RESET_MARKET_INSIGHT";
