export const SET_PRESET_ALERT_TAB = "SET_PRESET_ALERT_TAB";

export const SET_PRESET_PIPELINE_DATA = "SET_PRESET_PIPELINE_DATA";
export const SET_PRESET_PIPELINE_DATA_LOAD = "SET_PRESET_PIPELINE_DATA_LOAD";
export const SET_PRESET_PIPELINE_DATA_ERROR = "SET_PRESET_PIPELINE_DATA_ERROR";
export const SET_PRESET_PIPELINE_DETAIL = "SET_PRESET_PIPELINE_DETAIL";
export const SET_PRESET_PIPELINE_FILTER = "SET_PRESET_PIPELINE_FILTER";
export const RESET_PRESET_PIPELINE = "RESET_PRESET_PIPELINE";

export const SET_PRESET_KEY_ACCOUNT_DATA = "SET_PRESET_KEY_ACCOUNT_DATA";
export const SET_PRESET_KEY_ACCOUNT_DATA_LOAD = "SET_PRESET_KEY_ACCOUNT_DATA_LOAD";
export const SET_PRESET_KEY_ACCOUNT_DATA_ERROR = "SET_PRESET_KEY_ACCOUNT_DATA_ERROR";
export const SET_PRESET_KEY_ACCOUNT_DETAIL = "SET_PRESET_KEY_ACCOUNT_DETAIL";
export const SET_PRESET_KEY_ACCOUNT_FILTER = "SET_PRESET_KEY_ACCOUNT_FILTER";
export const RESET_PRESET_KEY_ACCOUNT = "RESET_PRESET_KEY_ACCOUNT";
