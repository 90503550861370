export const SET_PIPELINE_PROJECT_LOCATION_DATA =
  "SET_PIPELINE_PROJECT_LOCATION_DATA";
export const SET_PIPELINE_PROJECT_LOCATION_LOAD =
  "SET_PIPELINE_PROJECT_LOCATION_LOAD";
export const SET_PIPELINE_PROJECT_LOCATION_LOAD_NEXT =
  "SET_PIPELINE_PROJECT_LOCATION_LOAD_NEXT";
export const SET_PIPELINE_PROJECT_LOCATION_PAGE =
  "SET_PIPELINE_PROJECT_LOCATION_PAGE";
export const SET_PIPELINE_PROJECT_LOCATION_TOTAL =
  "SET_PIPELINE_PROJECT_LOCATION_TOTAL";
export const RESET_PROJECT_PIPELINE_LOCATION =
  "RESET_PROJECT_PIPELINE_LOCATION";
export const SET_AFTER_PAGE_PROJECT_PIPELINE =
  "SET_AFTER_PAGE_PROJECT_PIPELINE";
