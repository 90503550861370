import initialState from "./initialState";
import {
  SET_CF_TAB,
  RESET_CF_PROJECT,
  RESET_CF_COMPANY,
  SET_CF_PROJECT_LIST,
  SET_CF_PROJECT_LIST_LOAD,
  SET_CF_COMPANY_LIST,
  SET_CF_COMPANY_LIST_LOAD,
  SET_CF_TYPE_FIELD,
  SET_CF_SELECTED_LIST,
} from "./types";

const presetAlert = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CF_TAB: {
      return {
        ...state,
        tabActive: payload,
      };
    }
    case SET_CF_PROJECT_LIST: {
      return {
        ...state,
        cfProjectList: {
          ...state.cfProjectList,
          data: payload.data,
          total: payload.total,
          load: false,
        },
      };
    }
    case SET_CF_PROJECT_LIST_LOAD: {
      return {
        ...state,
        cfProjectList: {
          ...initialState.cfProjectList,
          load: true,
        },
      };
    }
    case RESET_CF_PROJECT: {
      return {
        ...state,
        cfProjectList: initialState.cfProjectList,
        cfProjectDetail: initialState.cfProjectDetail,
        cfProjectSelected: initialState.cfProjectSelected,
      };
    }

    case SET_CF_COMPANY_LIST: {
      return {
        ...state,
        cfCompanyList: {
          ...state.cfCompanyList,
          data: payload.data,
          total: payload.total,
          load: false,
        },
      };
    }
    case SET_CF_COMPANY_LIST_LOAD: {
      return {
        ...state,
        cfCompanyList: {
          ...initialState.cfCompanyList,
          load: true,
        },
      };
    }
    case RESET_CF_COMPANY: {
      return {
        ...state,
        cfCompanyList: initialState.cfCompanyList,
        cfCompanyDetail: initialState.cfCompanyDetail,
        cfCompanySelected: initialState.cfCompanySelected,
      };
    }
    case SET_CF_TYPE_FIELD: {
      return {
        ...state,
        cfTypeList: payload,
      };
    }

    case SET_CF_SELECTED_LIST: {
      return {
        ...state,
        cfSelectedList: payload || [],
      };
    }
    default:
      return state;
  }
};

export default presetAlert;
