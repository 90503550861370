export const SET_SEARCH_COMPANY_SELECTED = "SET_SEARCH_COMPANY_SELECTED";
export const SET_SEARCH_COMPANYID_SELECTED = "SET_SEARCH_COMPANYID_SELECTED";

export const SET_SEARCH_COMPANY_PARAMETERS = "SET_SEARCH_COMPANY_PARAMETERS";
export const SET_SEARCH_COMPANY_FILTER = "SET_SEARCH_COMPANY_FILTER";
export const RESET_SEARCH_COMPANY_PARAMETERS =
  "RESET_SEARCH_COMPANY_PARAMETERS";
export const RESET_SEARCH_COMPANY_FILTER = "RESET_SEARCH_COMPANY_FILTER";

export const SET_SEARCH_COMPANY_DETAIL = "SET_SEARCH_COMPANY_DETAIL";
export const SET_SEARCH_COMPANY_DETAIL_DATA = "SET_SEARCH_COMPANY_DETAIL_DATA";
export const SET_SEARCH_COMPANY_DETAIL_DATA_LOAD =
  "SET_SEARCH_COMPANY_DETAIL_DATA_LOAD";

export const SET_SEARCH_COMPANY_DATA_LOAD = "SET_SEARCH_COMPANY_DATA_LOAD";

export const SET_SEARCH_COMPANY_DATA = "SET_SEARCH_COMPANY_DATA";

export const SET_SEARCH_COMPANY_DATA_ERROR = "SET_SEARCH_COMPANY_DATA_ERROR";

export const RESET_SEARCH_COMPANY_DATA = "RESET_SEARCH_COMPANY_DATA";

export const SET_SEARCH_COMPANY_WATCHLIST_FORM =
  "SET_SEARCH_COMPANY_WATCHLIST_FORM";

export const SET_SEARCH_COMPANY_ENQUIRY_FORM =
  "SET_SEARCH_COMPANY_ENQUIRY_FORM";

export const SET_SEARCH_COMPANY_SAVE_SEARCH = "SET_SEARCH_COMPANY_SAVE_SEARCH";

export const RESET_FILTER_COMPANY = "RESET_FILTER_COMPANY";
export const SET_SHOW_ALL_DATA_ON_MAP_COMPANY =
  "SET_SHOW_ALL_DATA_ON_MAP_COMPANY";