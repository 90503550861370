const initialState = {
  tabActive: "", // 'projectpipeline' || 'keyaccount' || ''
  projectPipeline: {
    list: {
      data: [],
      total: 0,
      load: false,
      error: '',
    },
    filter: {
      page: 1,
      limit: 10,
      sortBy: "modifiedDate",
      orderBy: "desc",
    },
    detail: null,
  },
  keyAccount: {
    list: {
      data: [],
      total: 0,
      load: false,
      error: '',
    },
    filter: {
      page: 1,
      limit: 10,
      sortBy: "modifiedDate",
      orderBy: "desc",
    },
    detail: null,
  },
};

export default initialState;
