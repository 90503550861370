export const SET_SEARCH_PROJECT_PAGE = "SET_SEARCH_PROJECT_PAGE";
export const SET_SEARCH_PROJECT_LIMIT = "SET_SEARCH_PROJECT_LIMIT";
export const SET_SEARCH_PROJECT_SORT = "SET_SEARCH_PROJECT_SORT";
export const SET_SEARCH_PROJECT_DATA = "SET_SEARCH_PROJECT_DATA";
export const SET_SEARCH_PROJECT_DATA_MAP_MODE = "SET_SEARCH_PROJECT_DATA_MAP_MODE";
export const SET_SEARCH_PROJECT_LOCATION_DATA = "SET_SEARCH_PROJECT_LOCATION_DATA";
export const SET_SEARCH_PROJECT_DATA_LOAD = "SET_SEARCH_PROJECT_DATA_LOAD";
export const SET_SEARCH_PROJECT_DATA_LOAD_MAP_MODE = "SET_SEARCH_PROJECT_DATA_LOAD_MAP_MODE";
export const SET_SEARCH_PROJECT_LOCATION_DATA_LOAD = "SET_SEARCH_PROJECT_LOCATION_DATA_LOAD";
export const SET_SEARCH_PROJECT_OTHER_DATA = "SET_SEARCH_PROJECT_OTHER_DATA";
export const SET_SEARCH_PROJECT_OTHER_DATA_LOAD = "SET_SEARCH_PROJECT_OTHER_DATA_LOAD";
export const SET_SEARCH_PROJECT_DETAIL_LOAD = "SET_SEARCH_PROJECT_DETAIL_LOAD";
export const SET_SEARCH_PROJECT_DETAIL = "SET_SEARCH_PROJECT_DETAIL";
export const SET_SEARCH_PROJECT_DETAIL_DATA = "SET_SEARCH_PROJECT_DETAIL_DATA";
export const SET_SEARCH_PROJECT_SELECTED = "SET_SEARCH_PROJECT_SELECTED";
export const SET_SEARCH_PROJECT_TOTAL = "SET_SEARCH_PROJECT_TOTAL";
export const SET_SEARCH_PROJECT_TOTAL_OUTSIDE_SUBSCRIPTION = "SET_SEARCH_PROJECT_TOTAL_OUTSIDE_SUBSCRIPTION";
export const RESET_SEARCH_PROJECT = "RESET_SEARCH_PROJECT";
export const SET_SEARCH_PROJECT_ERROR = "SET_SEARCH_PROJECT_ERROR";
export const SET_SEARCH_PROJECT_DETAIL_ERROR = "SET_SEARCH_PROJECT_DETAIL_ERROR";
export const SET_SEARCH_PROJECT_WATCHLIST_FORM = "SET_SEARCH_PROJECT_WATCHLIST_FORM";
export const SET_SEARCH_PROJECT_LOCATION_OTHER_DATA = "SET_SEARCH_PROJECT_LOCATION_OTHER_DATA";
export const SET_SEARCH_PROJECT_LOCATION_OTHER_DATA_LOAD = "SET_SEARCH_PROJECT_LOCATION_OTHER_DATA_LOAD";