const initialState = {
  searchProject: {
    data: [],
    outsideSubs: [],
    totalData: 0,
    load: false,
    error: null,
  },
  searchProjectLocation: {
    locations: [],
    additionalLocations: [],
    loadLocation: false,
    error: null,
    loadLocationNextPage: false,
    scrollId: null,
    totalLocations: 0,
    redrawMap: null
  },
};

export default initialState;
