export const SET_SUBSCRIPTION_INSIGHT_FILTER =
  "SET_SUBSCRIPTION_INSIGHT_FILTER";
export const SET_SUBSCRIPTION_INSIGHT_DATA_LOAD =
  "SET_SUBSCRIPTION_INSIGHT_DATA_LOAD";
export const SET_SUBSCRIPTION_INSIGHT_DATA_ERROR =
  "SET_SUBSCRIPTION_INSIGHT_DATA_ERROR";
export const SET_SUBSCRIPTION_INSIGHT_DATA = "SET_SUBSCRIPTION_INSIGHT_DATA";

export const SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_LOAD =
  "SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_LOAD";
export const SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_ERROR =
  "SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA_ERROR";
export const SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA =
  "SET_SUBSCRIPTION_INSIGHT_CATEGORY_DATA";

export const SET_SUBSCRIPTION_INSIGHT_LOCATION_FILTER =
  "SET_SUBSCRIPTION_INSIGHT_LOCATION_FILTER";
export const SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_LOAD =
  "SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_LOAD";
export const SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_ERROR =
  "SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA_ERROR";
export const SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA =
  "SET_SUBSCRIPTION_INSIGHT_LOCATION_DATA";

export const SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_OPTION =
  "SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_OPTION";
export const SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_FILTER =
  "SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_FILTER";
export const SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_LOAD =
  "SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_LOAD";
export const SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_ERROR =
  "SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA_ERROR";
export const SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA =
  "SET_SUBSCRIPTION_INSIGHT_OPPORTUNITIES_DATA";

export const SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_LOAD =
  "SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_LOAD";
export const SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_ERROR =
  "SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA_ERROR";
export const SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA =
  "SET_SUBSCRIPTION_INSIGHT_TOTAL_COMPANIES_DATA";

export const SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_LOAD =
  "SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_LOAD";
export const SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_ERROR =
  "SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA_ERROR";
export const SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA =
  "SET_SUBSCRIPTION_INSIGHT_TOTAL_CONTACT_DATA";
