import initialState from "./initialState";
import {
  SET_NOTIFICATION_TOP_BAR,
  SET_SUBSCRIPTION_INFO,
  SET_USER_DATA,
  SET_USER_OPTIONS,
  SET_LAST_GET_NOTIFICATION,
} from "./types";

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA: {
      return {
        ...state,
        userData: payload,
      };
    }
    case SET_USER_OPTIONS: {
      localStorage.setItem(
        "profile",
        JSON.stringify({
          ...state.userData,
          ...payload,
        })
      );
      return {
        ...state,
        userData: {
          ...state.userData,
          ...payload,
        },
      };
    }
    case SET_SUBSCRIPTION_INFO: {
      return {
        ...state,
        subscriptionInfo: payload
          ? {
              ...state.subscriptionInfo,
              ...payload,
            }
          : null,
      };
    }
    case SET_NOTIFICATION_TOP_BAR: {
      return {
        ...state,
        userNotification: payload,
      };
    }
    case SET_LAST_GET_NOTIFICATION: {
      return {
        ...state,
        lastGetNotification: payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
