const initialState = {
  projectpipelineSelectedFolder: null,
  projectpipelineFolderUserId: null,
  projectpipelineListDefaultFolder: {
    data: [],
    load: false,
  },
  projectpipelineListMyFolder: {
    data: [],
    load: false,
  },
  projectpipelineFilter: {
    categories: [],
    mainCategory: 0,
    location: null,
    advancedLocation: null,
    stage: [],
    keyword: "",
    dateFilter: null,
    dateModifiedFilter: null,
  },
  projectpipelineUtil: {
    page: 1,
    limit: 25,
    sortby: "createdAt",
    order: "desc",
    isDefault: "1",
  },
  projectpipelineData: {
    data: [],
    error: false,
    load: false,
    total: 0,
  },
  projectPipelineListView: 1,
  projectPipelineFolderType: 1,
  projectPipelineSelectedCheck: [],
  projectPipelineHistoryPage: [],

  projectSuggestionsData: null,
};

export default initialState;
