export const SET_SEARCH_COMPANY_PAGE = "SET_SEARCH_COMPANY_PAGE";
export const SET_SEARCH_COMPANY_LIMIT = "SET_SEARCH_COMPANY_LIMIT";
export const SET_SEARCH_COMPANY_SORT = "SET_SEARCH_COMPANY_SORT";
export const SET_SEARCH_COMPANY_DATA = "SET_SEARCH_COMPANY_DATA";
export const SET_SEARCH_COMPANY_DATA_LOAD = "SET_SEARCH_COMPANY_DATA_LOAD";
export const SET_SEARCH_COMPANY_DATA_MAP_MODE =
  "SET_SEARCH_COMPANY_DATA_MAP_MODE";
export const SET_SEARCH_COMPANY_DATA_LOAD_MAP_MODE =
  "SET_SEARCH_COMPANY_DATA_LOAD_MAP_MODE";
export const SET_SEARCH_COMPANY_LOCATION_DATA =
  "SET_SEARCH_COMPANY_LOCATION_DATA";
export const SET_SEARCH_COMPANY_LOCATION_DATA_LOAD =
  "SET_SEARCH_COMPANY_LOCATION_DATA_LOAD";
export const SET_SEARCH_COMPANY_DETAIL_LOAD = "SET_SEARCH_COMPANY_DETAIL_LOAD";
export const SET_SEARCH_COMPANY_DETAIL = "SET_SEARCH_COMPANY_DETAIL";
export const SET_SEARCH_COMPANY_DETAIL_DATA = "SET_SEARCH_COMPANY_DETAIL_DATA";
export const SET_SEARCH_COMPANY_SELECTED = "SET_SEARCH_COMPANY_SELECTED";
export const SET_SEARCH_COMPANY_TOTAL = "SET_SEARCH_COMPANY_TOTAL";
export const RESET_SEARCH_COMPANY = "RESET_SEARCH_COMPANY";
export const SET_SEARCH_COMPANY_ERROR = "SET_SEARCH_COMPANY_ERROR";
export const SET_SEARCH_COMPANY_DETAIL_ERROR =
  "SET_SEARCH_COMPANY_DETAIL_ERROR";
export const SET_SEARCH_COMPANY_WATCHLIST_FORM =
  "SET_SEARCH_COMPANY_WATCHLIST_FORM";
export const SET_SEARCH_COMPANY_PROJECT_VALUE_DATA =
  "SET_SEARCH_COMPANY_PROJECT_VALUE_DATA";
export const SET_SEARCH_COMPANY_PROJECT_VALUE_LOAD =
  "SET_SEARCH_COMPANY_PROJECT_VALUE_LOAD";
export const SET_SEARCH_COMPANY_PROJECT_VALUE_ERROR =
  "SET_SEARCH_COMPANY_PROJECT_VALUE_ERROR";
export const RESET_SEARCH_COMPANY_PROJECT_VALUE =
  "RESET_SEARCH_COMPANY_PROJECT_VALUE";
