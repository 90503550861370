import { Box, Button, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import appConfig from "../../config/app.config";
import colorVar from "../../styles/variable";
import iconTNLM from "../../assets/logo-red.svg";
import TNLMIcon from "../../utils/TNLMIcon";
import { isAndroid, isIOS } from "../../utils/constants";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    zIndex: 999999,
    width: "100%",
    padding: "15px",
    background: "#FF0000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
  },
  buttonDownload: {
    "&.MuiButton-contained": {
      backgroundColor: "#FFF",
    },
    "& .MuiButton-label": {
      color: colorVar["red-bci"],
    },
  },
  appTitle: {
    color: "#FFF",
    fontWeight: "bold",
  },
  appDesc: {
    color: colorVar["grey-light"],
    lineHeight: 1.3,
  },
  closeBtn: {
    position: "absolute",
    left: 0,
    top: 0,
    padding: "5px",
  },
}));

const mobileAppIntegrationLinks = {
  STAGING: {
    appUrl: "leadmanager-staging://",
    downloadIOS:
      "https://install.appcenter.ms/orgs/bci-media-group/apps/lead-manager-1/distribution_groups/staging",
    downloadAndroid:
      "https://install.appcenter.ms/orgs/bci-media-group/apps/lead-manager/distribution_groups/staging",
  },
  DEMO: {
    appUrl: "leadmanager-demo://",
    downloadIOS:
      "https://install.appcenter.ms/orgs/bci-media-group/apps/lead-manager-1/distribution_groups/demo",
    downloadAndroid:
      "https://install.appcenter.ms/orgs/bci-media-group/apps/lead-manager/distribution_groups/demo",
  },
  PRODUCTION: {
    appUrl: "leadmanager://",
    downloadIOS:
      "https://itunes.apple.com/id/app/bci-central-leadmanager/id1600188684",
    downloadAndroid:
      "https://play.google.com/store/apps/details?id=com.bcicentral.leadmanager",
  },
};

function launchAndroidApp(url) {
  var now = new Date().valueOf();
  function openApp() {
    window.location = url;
  }
  function fallback() {
    if (new Date().valueOf() - now > 1050) return;
    window.location =
      mobileAppIntegrationLinks[
        appConfig?.tnlmEnv || "PRODUCTION"
      ]?.downloadAndroid;
  }
  openApp();
  setTimeout(fallback, 1000);
}

function launchiOSApp(url) {
  var now = new Date().valueOf();
  function openApp() {
    window.location = url;
  }
  function fallback() {
    if (new Date().valueOf() - now > 1050) return;
    window.location =
      mobileAppIntegrationLinks[
        appConfig?.tnlmEnv || "PRODUCTION"
      ]?.downloadIOS;
  }
  openApp();
  setTimeout(fallback, 1000);
}

export default function TNLMAppBanner() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const onDownload = () => {
    if (isIOS) {
      launchiOSApp(
        `${
          mobileAppIntegrationLinks[appConfig.tnlmEnv || "PRODUCTION"]?.appUrl
        }${window.location.pathname}${window.location.search}`
      );
    } else if (isAndroid) {
      launchAndroidApp(
        `${
          mobileAppIntegrationLinks[appConfig.tnlmEnv || "PRODUCTION"]?.appUrl
        }${window.location.pathname}${window.location.search}`
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  }, []);

  return isOpen ? (
    <Box className={classes.root}>
      <IconButton
        onClick={() => setIsOpen(false)}
        size="small"
        className={classes.closeBtn}
      >
        <TNLMIcon icon="close" size={0.8} color="#fff" />
      </IconButton>
      <Box display={"flex"} alignItems={"center"}>
        <Box
          bgcolor={"#FFF"}
          width="50px"
          minWidth="50px"
          height="50px"
          display={"flex"}
          borderRadius="8px"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={iconTNLM} alt="TLMN" title="TNLM" width={35} />
        </Box>
        <Box display={"flex"} flexDirection="column" px={1.5}>
          <Typography variant="subtitle2" className={classes.appTitle}>
            LeadManager
          </Typography>
          <Typography variant="caption" className={classes.appDesc}>
            Access construction data whenever and wherever you need it.
          </Typography>
        </Box>
      </Box>
      <Box>
        <Button
          size="small"
          className={classes.buttonDownload}
          variant="contained"
          onClick={onDownload}
        >
          Download
        </Button>
      </Box>
    </Box>
  ) : null;
}
