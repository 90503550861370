import initialState from "./initialState";
import {
  RESET_PROJECT_LIST_SEARCH,
  SET_LOAD_PROJECT_LIST_SEARCH,
  SET_PROJECT_LIST_SEARCH,
  SET_PROJECT_LIST_SEARCH_ERROR,
  SET_PROJECT_LIST_SEARCH_OUTSIDE_SUBS,
  SET_SEARCH_PROJECT_LOCATION,
  SET_SEARCH_PROJECT_LOCATION_ERROR,
  SET_SEARCH_PROJECT_LOCATION_LOAD,
  SET_SEARCH_PROJECT_LOCATION_LOAD_NEXT_PAGE,
  SET_SEARCH_PROJECT_LOCATION_ADD,
  SET_SEARCH_PROJECT_LOCATION_TOTAL,
  SET_SEARCH_PROJECT_LOCATION_SCROLL_ID,
  SET_SEARCH_PROJECT_LOCATION_REMOVE,
  SET_SEARCH_PROJECT_LOCATION_FORCE_REDRAW_MAP,
  SET_SEARCH_PROJECT_LOCATION_ADD_FORCE_REDRAW,
} from "./types";

const projectList = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOAD_PROJECT_LIST_SEARCH: {
      return {
        ...state,
        searchProject: {
          ...initialState.searchProject,
          load: true,
        },
      };
    }
    case SET_PROJECT_LIST_SEARCH: {
      return {
        ...state,
        searchProject: {
          ...initialState.searchProject,
          data: payload?.data || [],
          totalData: payload?.totalDataProjectAll || 0,
        },
      };
    }
    case SET_PROJECT_LIST_SEARCH_OUTSIDE_SUBS: {
      return {
        ...state,
        searchProject: {
          ...initialState.searchProject,
          outsideSubs: payload?.data || [],
          totalData: payload?.totalDataProjectAll || 0,
        },
      };
    }
    case SET_PROJECT_LIST_SEARCH_ERROR: {
      return {
        ...state,
        searchProject: {
          ...initialState.searchProject,
          error: payload,
        },
      };
    }

    case RESET_PROJECT_LIST_SEARCH: {
      return {
        ...state,
        searchProject: initialState.searchProject,
      };
    }

    case SET_SEARCH_PROJECT_LOCATION_LOAD: {
      return {
        ...state,
        searchProjectLocation: {
          ...initialState.searchProjectLocation,
          loadLocation: true,
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_ERROR: {
      return {
        ...state,
        searchProjectLocation: {
          ...initialState.searchProjectLocation,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION: {
      return {
        ...state,
        searchProjectLocation: {
          ...initialState.searchProjectLocation,
          locations: payload,
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_FORCE_REDRAW_MAP: {
      return {
        ...state,
        searchProjectLocation: {
          ...initialState.searchProjectLocation,
          locations: payload,
          redrawMap: new Date()
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_ADD: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          locations: state.searchProjectLocation.locations.concat(payload),
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_REMOVE: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          locations: state.searchProjectLocation.locations?.filter((x) => !payload.includes(+x.PROJECT_ID)),
          totalLocations: state.searchProjectLocation.totalLocations ? state.searchProjectLocation.totalLocations-payload.length : 0,
          redrawMap: new Date()
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_ADD_FORCE_REDRAW: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          locations: state.searchProjectLocation.locations.concat(payload),
          totalLocations: state.searchProjectLocation.totalLocations ? state.searchProjectLocation.totalLocations+payload.length : 0,
          redrawMap: new Date()
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_LOAD_NEXT_PAGE: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          loadLocationNextPage: payload,
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_TOTAL: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          totalLocations: payload,
        },
      };
    }
    case SET_SEARCH_PROJECT_LOCATION_SCROLL_ID: {
      return {
        ...state,
        searchProjectLocation: {
          ...state.searchProjectLocation,
          scrollId: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default projectList;
